import gql from 'graphql-tag'

const DELETE_CATEGORIAS = gql`
    mutation DeleteCategorias(
        $id: ID!
    ){
        deleteCategoria(input: {
            where: {
                id: $id,
            }
        }){
            categoria{
                _id
                nombre
            }
        }
    }
`

export default DELETE_CATEGORIAS