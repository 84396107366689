import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_CONFIG } from '../queries/queryList'
import styled from 'styled-components'
import { LoadingContext } from '../components/LoadingContext'

const Loader = () => {
    const { loading, setLoading } = useContext(LoadingContext)
    
    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const [colores, setColores] = useState({})
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if(dataConfig){
            setColores(dataConfig.configs[0].colores)
        }
    }, [dataConfig])

    useEffect(() => {
        if(loading){ setVisible(true) }
        else if(loading === false){
            setTimeout(() => {
                setVisible(false)
            }, 330)
        }
    }, [loading])

    return (
        visible ? 
            <LoaderWrapper isLoading={loading} >
                <img onClick={() => setLoading(false)} src={process.env.REACT_APP_API_URL + "/lyntia_anim.webp"} />
                <LoaderBackground background={colores.base} />
            </LoaderWrapper> : <div />
    )
}

const LoaderWrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 300ms;
    opacity: ${({ isLoading }) => isLoading ? 0.9 : 0};
    &>img{
        position: relative;
        z-index: 1000000001;
    }
`
const LoaderBackground = styled.div`
    /*background-color: ${({background}) => background};*/
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

export default Loader