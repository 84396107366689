import gql from 'graphql-tag'

const DELETE_TEMPORADA = gql`
    mutation DeleteTemporada(
        $id: ID!
    ){
        deleteTemporada(input: {
            where: {
                id: $id,
            }
        }){
            temporada{
                _id
                nombre
            }
        }
    }
`

export default DELETE_TEMPORADA