import gql from 'graphql-tag'

const EDIT_EQUIPO = gql`
    mutation UpdateEquipo(
        $id: ID!
        $nombre: String
        $idequipo: String
        $lat: Float
        $lng: Float
        $nivel: Int
        $padres: [ID]
    ){
        updateEquipo(input: {
            where: {
                id: $id,
            },
            data: {
                nombre: $nombre,
                idequipo: $idequipo,
                lat: $lat,
                lng: $lng,
                nivel: $nivel,
                padres: $padres
            }
        }){
            equipo {
                nombre
                idequipo
                slug
                lat
                lng
                nivel
                padres {
                    _id
                }
            }
        }
    }
`

export default EDIT_EQUIPO