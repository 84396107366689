import gql from 'graphql-tag'

const ADD_RETOINSTANCIA = gql`
    mutation CreateRetoinstancia(
        $objetivo: Float,
        $resultado: Float,
        $valor: Int,
        $reto: ID!,
        $misioninstancia: ID!
    ){
        createRetoinstancia(input: {
            data: {
                objetivo: $objetivo
                resultado: $resultado
                valor: $valor
                reto: $reto
                misioninstancia: $misioninstancia
            }
        }){
            retoinstancia {
                _id
                objetivo
                resultado
                valor
                reto{
                    _id
                    nombre
                }
                misioninstancia{
                    _id
                }
            }
        }
    }
`

export default ADD_RETOINSTANCIA