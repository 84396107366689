import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Icon } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import GET_CONFIG from '../queries/GET_CONFIG'

const Header = () => {
    const { loading, error, data } = useQuery(GET_CONFIG)
    const [logo, setLogo] = useState()
    const [baseURL, setBaseURL] = useState("/")
    const [userInfo, setUserInfo] = useState()
    const [role, setRole] = useState()
    let location = useLocation()

    useEffect(() => {
        const ui = JSON.parse(window.localStorage.getItem("userInfo"))
        const role = ui && ui.role && ui.role.name
        setUserInfo(ui)
        setRole(ui && ui.role && ui.role.name)
        if(role === "Administrador"){ setBaseURL("/global") }
        else if(role === "Manager"){
            if(ui.managerDe.length === 1){ setBaseURL("/equipo/" + ui.managerDe[0].slug) }
            else{ setBaseURL("/perfil") }
        }
        else if(role === "Agente"){
            setBaseURL("/agente/" + ui.slug)
        }
        else{ setBaseURL("/") }
    }, [location])

    if(data && !logo){ setLogo(data.configs[0].logo.url.includes("cix-lyntia.s3") ? data.configs[0].logo.url : process.env.REACT_APP_API_URL + data.configs[0].logo.url) }

    return (
        <HeaderWrapper>
            { !loading && !error && data && (
                <div>
                    <Logo>
                        <Link to={baseURL}>
                            <img src={logo} />
                        </Link>
                    </Logo>

                    { userInfo &&
                        <LinkWrapper>
                            {role === "Administrador" && [
                                <LinkItem key="admin">
                                    <Link to="/admin">
                                        <Icon type="reconciliation" />
                                        <Text>Administración</Text>
                                    </Link>
                                </LinkItem>,
                                <LinkItem key="global">
                                    <Link to="/global">
                                        <Icon type="compass" />
                                        <Text>Global</Text>
                                    </Link>
                                </LinkItem>,
                                <LinkItem key="comparar">
                                    <Link to="/comparar">
                                        <Icon type="switcher" />
                                        <Text>Comparar</Text>
                                    </Link>
                                </LinkItem>,
                                // <LinkItem key="holi">
                                //     <Link to="/holi">
                                //         <Icon type="user" />
                                //         <Text>Holi</Text>
                                //     </Link>
                                // </LinkItem>
                            ]}
                            <LinkItem>
                                <Link to="/auth/logout">
                                    <Icon type="logout" />
                                    <Text>Cerrar sesión</Text>
                                </Link>
                            </LinkItem>
                        </LinkWrapper>
                    }
                </div>
            )}
        </HeaderWrapper>
    )
}

const HeaderWrapper = styled.div`
    height: 100px;
    background-color: #3d215c;
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    margin-bottom: 1em;
    &>div{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
`

const Logo = styled.div`
    display: flex;
    max-width: 200px;
    padding: 1em 0;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-right: 1em;
`

const LinkItem = styled.div`
    padding: 0 1em;
    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, .7);
        transition: all 300ms;
        &:hover {
            color: rgba(255, 255, 255, .85)
        }
    }
    i{
        margin: 0 5px 4px 0;
    }
`

const Text = styled.span`
    margin-bottom: 2px;
`

export default Header
