import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import Quill from 'react-quill'
import { message } from 'antd'

const ModalMensaje = ({ data = {}, setOpenModal, config }) => {

    const [dest, setDest] = useState([])
    const [asunto, setAsunto] = useState("")
    const [asuntoErr, setAsuntoErr] = useState(false)
    const [cuerpo, setCuerpo] = useState("")
    const [cuerpoErr, setCuerpoErr] = useState(false)

    useEffect(() => {
        if(data && data.to){
            setDest(data.to.map(u => u.email))
        }
    }, [data])

    const enviarCorreos = async () => {
        if(!cuerpo){ setCuerpoErr(true) }
        if(!asunto){ setAsuntoErr(true) }

        console.log({ cuerpo, asunto })
        if(cuerpo && asunto){
            const userInfo = JSON.parse(localStorage.getItem("userInfo"))
            const cuerpoMod = `<div>
                <p>Has recibido un mensaje de ${userInfo.nombre} ${userInfo.apellidos}</p>
                <hr />
                <br />
                ${ cuerpo }
                <br />
                <hr />
                <p>Mensaje enviado a traves de ${config.nombrePlat}</p>
            </div>`

            const prom = dest.map(d => {
                fetch(process.env.REACT_APP_API_URL + "/email", {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify({
                        from: config.correoSaliente,
                        to: d,
                        subject: "CIX | " + asunto,
                        html: cuerpoMod,
                        body: cuerpoMod
                    })
                })
            })
            const resp = await Promise.all(prom)
            if(dest.length > 1){
                message.success("Se han enviado los mensajes satisfactoriamente")
            }
            else{
                message.success("Se ha enviado el mensaje satisfactoriamente")
            }
            setOpenModal({ open: false, to: []})
        }
    }

    return (
        <Modal
            isOpen={data.open}                    
            onRequestClose={() => setOpenModal({ open: false, to: []})}
            contentLabel="Enviar mensaje"
            overlayClassName="Overlay"
            className="Modal container-fluid d-flex flex-column justify-content-between"
            footer={null}
            ariaHideApp={false}
        >
            <div className="row px-2 pt-4">
                <div className="col-12">
                    <h3>Enviar mensaje</h3>
                </div>
                <div className="col-12 mt-2">
                    <p><strong>Destinatario{dest.length > 1 && "s"}: </strong>{ dest.reduce((a, b) => a + ", " + b, "").slice(2) }</p>
                </div>
                <div className="col-12">
                    <label htmlFor="asuntoMsg">Asunto</label>
                    <Input 
                        name="asuntoMsg"
                        type="text" 
                        value={asunto}
                        className="p-2"
                        onChange={evt => setAsunto(evt.target.value)}
                    />
                    <small style={{ color: "red" }} className={`${ asuntoErr ? "" : "d-none"}`}>El asunto no puede estar vacío</small>
                </div>
                <div className="col-12 mt-4">
                    <label>Cuerpo</label>
                    <Quill 
                        value={cuerpo}
                        onChange={evt => setCuerpo(evt)}
                    />
                    <small style={{ color: "red" }} className={`${ cuerpoErr ? "" : "d-none"}`}>El cuerpo no puede estar vacío</small>
                </div>
            </div>
            <div className="d-flex w-100 justify-content-end pb-4 mt-4">
                <div className="btn btn-secondary mr-3" onClick={() => setOpenModal({ open: false, to: []})}>Cancelar</div>
                <div className="btn btn-primary mr-2" onClick={() => enviarCorreos()}>Enviar mensaje</div>
            </div>
        </Modal>
    )
}

const Input = styled.input`
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 3px;
`

export default ModalMensaje