import gql from 'graphql-tag'

const GET_CONFIG = gql`
    {
        configs{
            _id
            nombrePlat
            estructuraJerarquica
            logo{
                url
            }
            nombrePlat
            indicadores
            pesoRetosIndicadores
            copyright
            colores
            ayudaManager { 
                url
                name
            }
            ayudaUsuario { 
                url
                name
            }
            ayudaAdmin { 
                url
                name
            }
            temporadaActual{
                _id
                nombre
            }
            enviarEquipoCompletoPreasignado
            plantillaEquipoCompletoPreasignado
            asuntoEquipoCompletoPreasignado
            firmaEquipoCompletoPreasignado
            enviarMisionValidada
            plantillaMisionValidada
            asuntoMisionValidada
            firmaMisionValidada
            enviarNuevoUsuario
            plantillaNuevoUsuario
            asuntoNuevoUsuario
            firmaNuevoUsuario
            enviarMisionEditada
            plantillaMisionEditada
            asuntoMisionEditada
            enviarCambioPw
            plantillaCambioPw
            asuntoCambioPw
            firmaCambioPw
            plantillaFirma
            correoSaliente
            literales
            mediasTrueSumasFalse
            version
        }
    }
`

export default GET_CONFIG