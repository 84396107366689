import gql from 'graphql-tag'

const EDIT_RETOINSTANCIA = gql`
    mutation UpdateRetoinstancia(
        $id: ID!
        $objetivo: Float,
        $resultado: Float,
        $valor: Int,
        $reto: ID,
        $misioninstancia: ID
    ){
        updateRetoinstancia(input: {
            where: {
                id: $id
            },
            data: {
                objetivo: $objetivo
                resultado: $resultado
                valor: $valor
                reto: $reto
                misioninstancia: $misioninstancia
            }
        }){
            retoinstancia {
                _id
                objetivo
                resultado
                valor
                reto{
                    _id
                    nombre
                }
                misioninstancia{
                    _id
                }
            }
        }
    }
`

export default EDIT_RETOINSTANCIA