import gql from 'graphql-tag'

const GET_ROLES = gql`
    {
        roles{
            _id
            name
        }
    }
`

export default GET_ROLES