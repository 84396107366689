import gql from 'graphql-tag'

const GET_MISION = gql`
    {
        misions{
            _id
            nombre
            descripcion
            slug
            categorias{
                _id
                nombre
            }
            retos{
                _id
                nombre
                peso
                tipomejora
                inverso
                tooltip
            }
            archivos{
                _id
                url
                name
            }
        }
    }
`

export default GET_MISION