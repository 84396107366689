import gql from 'graphql-tag'

const ADD_USER = gql`
    mutation CreateUser(
        $username: String!
        $email: String!
        $password: String
        $confirmed: Boolean
        $blocked: Boolean
        $role: ID
        $lugar: String
        $trabajadorDe: ID
        $managerDe: [ID]
        $nombre: String
        $apellidos: String
        $idusuario: String
        $slug: String
    ){
        createUser(input: {
            data: {
                username: $username,
                email: $email,
                password: $password,
                confirmed: $confirmed,
                blocked: $blocked,
                role: $role,
                lugar: $lugar
                trabajadorDe: $trabajadorDe,
                managerDe: $managerDe,
                nombre: $nombre,
                apellidos: $apellidos,
                idusuario: $idusuario,
                slug: $slug
            }
        }){
            user {
                username
                email
                confirmed
                blocked
                role{
                    _id
                    name
                }
                trabajadorDe{
                    _id
                    nombre
                }
                managerDe{
                    _id
                    nombre
                }
                nombre
                apellidos
                idusuario
                slug
            }
        }
    }
`

export default ADD_USER