import gql from 'graphql-tag'

const GET_RETOS = gql`
    query {
       retos{
           _id
           nombre
       }
    }
`

export default GET_RETOS