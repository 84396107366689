import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_CONFIG } from "../queries/queryList";
import { aclararHex } from "../utils/func";
// import Skeleton from 'react-skeleton-loader';

const Speedometer = ({
  val,
  obj,
  min = 0,
  max = 100,
  color1,
  color2,
  perc = true,
  mostrar = false
}) => {
  // console.log(val)
  // const valor = val !== undefined ? ((val-min)/(max-min))*100 : undefined;
  //const objetivo = ((obj-min)/(max-min))*100;
  var colind = "0";
  if (color1 === undefined || color2 === undefined) {
    if (val > 66.6) {
      colind = "green";
    } else if (val > 33.3) {
      colind = "orange";
    } else if (val === " - ") {
      colind = "grey";
    } else if (val !== undefined) {
      colind = "red";
    } else {
      colind = "grey";
    }
  } else {
    if (val >= color2) {
      colind = "green";
    } else if (val >= color1) {
      colind = "orange";
    } else if (val === " - ") {
      colind = "grey";
    } else if (val !== undefined) {
      colind = "red";
    } else {
      colind = "grey";
    }
  }

  const {
    loading: loadingConfig,
    error: errorConfig,
    data: dataConfig,
    refetch: refetchConfig
  } = useQuery(GET_CONFIG);
  const [colores, setColores] = useState({});

  useEffect(() => {
    if (dataConfig) {
      setColores(dataConfig.configs[0].colores);
    }
  }, [dataConfig]);

  const anguloNoFilt = ![undefined, " - "].includes(val) ? val * 1.8 : false;
  const angulo = anguloNoFilt < 0 ? 0 : anguloNoFilt > 180 ? 180 : anguloNoFilt;
  // console.log({
  //   val, angulo
  // })

  return (
    <div className="p-2 d-flex flex-column justify-content-center align-items-center">
      {mostrar && (
        <div className="ratio">
          <h2 style={{ fontSize: 45, fontWeight: 600, marginTop: -18, marginBottom: "1em" }}>
            {val !== undefined ? (
              val + (perc && ![undefined, " - "].includes(val) ? "%" : "")
            ) : (
              <div />
            )}
          </h2>
        </div>
      )}
      <div
        className="outherspeed pb-2"
        style={{
          height: "50%",
          width: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          className="containerspeed"
          style={{
            position: "relative",
            width: 149,
            height: 79,
            transform: "scale(1.3)"
          }}
        >
          <div className="line">
            <svg width="149px" height="76px" viewBox="0 0 149 76">
              <defs>
                <linearGradient id="green">
                  <stop offset="0" stopColor="#429321" stopOpacity="1" />
                  <stop offset="1" stopColor="#B4EC51" stopOpacity="1" />
                </linearGradient>
                <linearGradient id="orange">
                  <stop
                    offset="0"
                    stopColor={colores.amarillo || "#ffee00"}
                    stopOpacity="1"
                  />
                  <stop
                    offset="1"
                    stopColor={aclararHex(colores.amarillo, 30, "#ffee00")}
                    stopOpacity="1"
                  />
                </linearGradient>
                <linearGradient id="red">
                  <stop
                    offset="0"
                    stopColor={colores.naranja || "#ff0000"}
                    stopOpacity="1"
                  />
                  <stop
                    offset="1"
                    stopColor={aclararHex(colores.naranja, 70, "#ff0000")}
                    stopOpacity="1"
                  />
                </linearGradient>
                <linearGradient id="grey">
                  <stop offset="0" stopColor="#dcdcdc" stopOpacity="1" />
                  <stop offset="1" stopColor="#f2f2f2" stopOpacity="1" />
                </linearGradient>
              </defs>
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
              >
                <path
                  d="M3,73 C4.03356625,34.163479 35.6495492,3 74.5000001,3 C113.350451,3 144.966434,34.163479 146,73"
                  stroke="gainsboro"
                  strokeWidth="6"
                />
              </g>
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
              >
                <path
                  className="dibujo"
                  d="M3,73 C4.03356625,34.163479 35.6495492,3 74.5000001,3 C113.350451,3 144.966434,34.163479 146,73"
                  stroke={`url(#${colind})`}
                  strokeWidth="6"
                />
              </g>
            </svg>
          </div>
          <div
            className="speedindicator"
            style={{
              transform: `rotate(${angulo || 0}deg)`,
              position: "absolute",
              bottom: "-10px",
              left: "41px",
              transformOrigin: "34px 9px",
              transition: "all 2s"
            }}
          >
            <svg width="44px" height="19px" viewBox="0 0 44 19">
              <defs />
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  transform="translate(-477.000000, -782.000000)"
                  fill="#3E3E3E"
                >
                  <path
                    d="M499,813 C494.029437,813 490,808.856387 490,803.744981 C490,798.633575 497.225469,770 499.362473,770 C501.499478,770 508,798.633575 508,803.744981 C508,808.856387 503.970563,813 499,813 Z M499,808.447177 C500.911755,808.447177 502.461538,806.85348 502.461538,804.887555 C502.461538,802.921629 500.911755,801.327932 499,801.327932 C497.088245,801.327932 495.538462,802.921629 495.538462,804.887555 C495.538462,806.85348 497.088245,808.447177 499,808.447177 Z"
                    transform="translate(499.000000, 791.500000) rotate(-90.000000) translate(-499.000000, -791.500000) "
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      {!mostrar && (
        <div className="ratio">
          <div className="ratio pt-4">
            <h2 style={{ fontSize: 40, fontWeight: 600 }}>
              {val !== undefined ? (
                val + (perc && ![undefined, " - "].includes(val) ? "%" : "")
              ) : (
                <div />
              )}
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default Speedometer;
