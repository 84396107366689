import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { GET_EQUIPOS, GET_CONFIG, GET_TEMPORADAS } from '../queries/queryList'
import { mayusculizer } from '../utils/func'
import CabeceraTeams from '../components/CabeceraTeams'
import CuadroResultados from '../components/CuadroResultados'
import Speedometer from '../components/Speedometer'
import Jumbo from '../components/Jumbo'
import RankingEquipos from '../components/RankingEquipos'
import TarjetaEquipo from '../components/TarjetaEquipo'
import ModalMensaje from '../components/ModalMensaje'
import { LoadingContext } from '../components/LoadingContext'
import { media, redondear } from '../utils/func'

const GlobalPage = () => {

    const { loading: loadingEquipos, error: errorEquipos, data: dataEquipos, refetch: refetchEquipos } = useQuery(GET_EQUIPOS)
    const { loading: loadingTemporadas, error: errorTemporadas, data: dataTemporadas, refetch: refetchTemporadas } = useQuery(GET_TEMPORADAS)
    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const { setLoading } = useContext(LoadingContext)

    const [equipos, setEquipos] = useState([])
    const [jerar, setJerar] = useState([])
    const [temporadas, setTemporadas] = useState([])
    const [modalMensaje, setModalMensaje] = useState({ open: false, to: []})
    const [selTemporada, setSelTemporada] = useState({ label: "Todas", value: false })
    const [globales, setGlobales] = useState({ indicadores: {}})

    useEffect(() => {
        if(dataEquipos && dataConfig && dataTemporadas){
            const j = Object.keys(dataConfig.configs[0].estructuraJerarquica).map(n => ({ ...dataConfig.configs[0].estructuraJerarquica[n], nivel: parseInt(n) }))
            setJerar(j)
            setTemporadas([...dataTemporadas.temporadas.map(t => ({ label: t.nombre, value: t._id })), { label: "Todas", value: false }])
            setLoading(false)
        }
    }, [dataEquipos, dataConfig, dataTemporadas])

    useEffect(() => {
        if(dataEquipos){
            // console.log(selTemporada.label)
            const indicadores = dataConfig.configs[0].indicadores
            const indic = dataConfig.configs[0].mediasTrueSumasFalse ? "mediaIndicadores" : "sumaIndicadores"
            const total = dataConfig.configs[0].mediasTrueSumasFalse ? "mediaTotal" : "sumaTotal"
            const retos = dataConfig.configs[0].mediasTrueSumasFalse ? "mediaRetos" : "sumaRetos"
            const equipos = dataEquipos.equipos.filter(e => e.nivel === 0).map(eq => {
                const mFilt = eq.medias.filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
                const mUsFilt = [].concat.apply([], eq.trabajadores.map(t => t.medias)).filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
                const mhUsFilt = [].concat.apply([], [].concat.apply([], eq.hijos.map(h => h.trabajadores)).map(t => t.medias)).filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
                const maxRanking = Math.max.apply(null, [...mUsFilt, ...mhUsFilt].map(m => redondear(dataConfig.configs[0].mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
                const minRanking = Math.min.apply(null, [...mUsFilt, ...mhUsFilt].map(m => redondear(dataConfig.configs[0].mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
                const mediasInter = {
                    consecucion: mFilt.length > 0 ? media(mFilt, "mediaConsecucion", 1) : " - ",
                    retos: mFilt.length > 0 ? media(mFilt, retos, 1) : " - ",
                    mediaIndicadores: mFilt.length > 0 ? media(mFilt, indic, 1) : " - ",
                    ranking: mFilt.length > 0 ? media(mFilt, total, 1) + 50 : " - ",
                    maxRanking: maxRanking !== -Infinity ? maxRanking : " - ",
                    minRanking: minRanking !==  Infinity ? minRanking : " - ",
                    indicadores: Object.keys(indicadores).map(ind => ({
                        [ind]: mFilt.length > 0 ? media(mFilt.map(m => m.indicadores, 1), ind) : " - "
                    })).reduce((a, b) => ({ ...a, ...b}), {})
                }
                return ({
                    ...eq,
                    mFilt,
                    mediasInter
                })
            })

            // console.log({ dataEquipos })

            const m = equipos.map(eq => eq.mediasInter)
            // console.log(m)
            const globales = {
                consecucion: !(m.map(mi => mi.consecucion).every(it => it === " - ")) ? media(m, "consecucion", 0) : " - ",
                mediaIndicadores: !(m.map(mi => mi.mediaIndicadores).every(it => it === " - ")) ? media(m, indic, 1) : " - ",
                retos: !(m.map(mi => mi.retos).every(it => it === " - ")) ? media(m, "retos", 1) : " - ",
                ranking: !(m.map(mi => mi.ranking).every(it => it === " - ")) ? media(m, "ranking", 1) : " - ",
                maxRanking: !(m.map(mi => mi.maxRanking).every(it => it === " - ")) ? media(m, "maxRanking", 1) : " - ",
                minRanking: !(m.map(mi => mi.minRanking).every(it => it === " - ")) ? media(m, "minRanking", 1) : " - ",
                indicadores: Object.keys(indicadores).map(ind => ({
                    [ind]: !(m.map(mi => mi.indicadores[ind]).every(it => it === " - ")) ? media(m.map(mi => mi.indicadores), ind, 1) : " - "
                })).reduce((a, b) => ({ ...a, ...b}), {})
            }
            setEquipos(equipos)
            // console.log({ equipos, globales })
            setGlobales(globales)
        }
    }, [selTemporada, dataEquipos])

    return (
        <div className="container">  
            <CabeceraTeams 
                nivel0={true} 
                config={dataConfig && dataConfig.configs[0]} 
                temporadas={temporadas} 
                selTemporada={selTemporada} 
                setSelTemporada={setSelTemporada} 
            />
            <div className="row mt-4 mb-5">
                <div className="col-md">
                    <h3 className="mb-3">Valoración</h3>
                    <CuadroResultados datos={globales} config={dataConfig && dataConfig.configs[0]} />
                </div>
                <div className="col-md-3">
                    <h3 className="mb-3">Consecución</h3>
                    {
                        <Jumbo className="alto320 d-flex align-items-center justify-content-center p-0">
                            <SingleSpeedo>
                                <Speedometer val={globales.consecucion} />
                                {/* <Title>Retos</Title> */}
                            </SingleSpeedo>
                        </Jumbo>
                    }
                </div>
                <div className="col-md">
                    <h3 className="mb-3">Ranking</h3>
                    <RankingEquipos equipos={equipos}/>
                </div>
            </div>

            { equipos.map(h => (
                    <div key={"nivel" + Object.keys(h)[0]} style={{ marginBottom: 20 }}>
                        <h3 style={{ marginBottom: 20 }}>{jerar.find(jit => jit.nivel === 0) && mayusculizer(jerar.find(jit => jit.nivel === 0).plur)}</h3>
                        <div className="row">
                            { equipos.map(e => (
                                <div key={e._id} className="col-md-4">
                                    <TarjetaEquipo equipo={e} openModal={setModalMensaje}/>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            )}

            <ModalMensaje 
                data={modalMensaje}
                setOpenModal={setModalMensaje}
                config={dataConfig && Array.isArray(dataConfig.configs) && dataConfig.configs[0]}
            />
        </div>
    )
}

const SingleSpeedo = styled.div`
    padding-top: 35px;
    &:not(:last-child){
        border-bottom: 1px solid gainsboro;
    }
`
const Title = styled.div`
        width: 100%;
        padding-bottom: 10px;
        margin-top: -20px;
        text-align: center;
        font-weight: bold;
`

export default GlobalPage