import gql from 'graphql-tag'

const ADD_RETO = gql`
    mutation CreateReto(
        $mision: ID!
        $nombre: String
        $peso: Float
        $inverso: Boolean
        $tipomejora: ENUM_RETO_TIPOMEJORA
        $tooltip: String
    ){
        createReto(input: {
            data: {
                mision: $mision,
                nombre: $nombre,
                peso: $peso,
                inverso: $inverso,
                tipomejora: $tipomejora,
                tooltip: $tooltip
            }
        }){
            reto {
                _id
                mision{
                    _id
                    nombre
                }
                nombre
                peso
                tipomejora
                inverso
                tooltip
            }
        }
    }
`

export default ADD_RETO