import React, { useContext, useEffect } from 'react'
import { LoadingContext } from '../components/LoadingContext'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { goToBaseURL } from '../utils/func'

const HomePage = ({ history }) => {

    const { setLoading } = useContext(LoadingContext)

    useEffect(() => {
        setLoading(false)
        goToBaseURL(history)
    }, [])
    
    return (
            <div className="container">
                <div className="row mt-4 mb-4">
                    <div className="col">
                        <h2 className="text-center" style={{ color: "grey" }}>Redirigiendo...</h2>
                    </div>
                </div>
            </div>
    )
}

export default HomePage
