import gql from 'graphql-tag'

const GET_MEDIAS_GLOBAL = gql`
    {
        users{
            _id
            nombre
            apellidos
            slug
            medias{
                id
                createdAt
                sumaTotal
                mediaTotal
                temporada{
                    id
                    nombre
                }
            }
        }
    }
`

export default GET_MEDIAS_GLOBAL