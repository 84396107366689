import React, { useState } from 'react';
import Modal from 'react-modal';

const ModalEdit = ({ user, open, setModalEdit, modalEditCallback }) => {

    const [actual, setActual] = useState()
    const [editPw, setEditPw] = useState(false)
    const [pw, setPw] = useState("")
    const [pw2, setPw2] = useState("")

    const handleFile = async evt => {
        const body = new FormData();
        body.append("refId", user._id);
        body.append("ref", "user");
        body.append("field", "imagen");
        body.append("source", "users-permissions");
        if (evt.target.files[0] instanceof File) {
            body.append('files', evt.target.files[0]);
        }
        const token = localStorage.getItem('token')
        const response = await fetch(process.env.REACT_APP_API_URL + "/upload", {
            method: 'POST', body, headers: {
                "Authorization": `Bearer ${token}`
            }
        }, false).then(data => data.json())

        const newuser = { ...user }
        newuser.imagen = response[0]
        modalEditCallback(newuser)
    }

    const cambiarPw = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + "/auth/local", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "identifier": user.username,
                "password": actual
            })
        })

        if (response.status === 200) {
            if (pw !== pw2) {
                alert("Las dos contraseñas no son iguales")
                return
            }
            else if (pw.length < 6) {
                alert("La contraseña tiene que tener más de 6 caracteres")
                return
            }
            else {
                //TODO GUAY
                const token = localStorage.getItem('token')
                fetch(`${process.env.REACT_APP_API_URL}/users/${user._id}`, {
                    method: "PUT",
                    body: JSON.stringify({ password: pw }),
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(data => { console.log(data); alert("La contraseña se cambió correctamente"); setActual(""); setPw(""); setPw2(); setEditPw(false) })
                    .catch(err => { console.log(err); alert("Ocurrió un error durante el proceso de cambio de contraseña"); setActual(""); setPw(""); setPw2(); })
            }
        }
        else {
            alert("Contraseña actual incorrecta")
        }
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setModalEdit(false)}
            contentLabel="Editar datos"
            overlayClassName="Overlay"
            className="Modal"
            ariaHideApp={false}
            style={{ content: { width: "90%", maxWidth: 600 } }}
        >
            <div className="container" style={{ position: "relative", overflowY: "scroll", height: 440 }}>
                <div style={{
                    position: "sticky",
                    textAlign: "right",
                    top: 0,
                    padding: "10px 20px",
                    fontSize: 30,
                    zIndex: 123
                }} onClick={() => setModalEdit(false)}>&times;</div>
                <div className="row p-3" style={{ marginTop: -50 }}>
                    <div className="col-12 d-flex flex-column align-items-center">
                        <h3 className="pb-4">Edición de perfil</h3>
                        {
                            // console.log({
                            //     matu: (user.imagen) ? (user.imagen.url.includes("cix-lyntia.s3") ? user.imagen.url : process.env.REACT_APP_API_URL + user.imagen.url) : (process.env.REACT_APP_API_URL + "/uploads/person.jpg")
                            // })
                        }
                        <img alt="Foto de perfil" style={{ width: 300, borderRadius: 10, marginBottom: 20, height: 200, objectFit: "cover", backgroundColor: "gainsboro" }} src={user.imagen ? (user.imagen.url.includes("cix-lyntia.s3") ? user.imagen.url : process.env.REACT_APP_API_URL + "/uploads" + user.imagen.url.split("/uploads").slice(-1)[0]) : (process.env.REACT_APP_API_URL + "/uploads/person.jpg")} />
                        <label style={{ position: "relative" }} htmlFor="inputFile">
                            <div className="btn btn-primary">Editar imagen de perfil</div>
                            <input style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                top: 0,
                                left: 0,
                                opacity: 0
                            }} type="file" name="inputFile" onChange={evt => handleFile(evt)} />
                        </label>
                    </div>
                </div>
                <div className="row pb-3 pt-0">
                    <div className="col-12 d-flex justify-content-center">
                        {!editPw ? (
                            <div className="d-flex">
                                <div className="btn btn-primary" onClick={() => setEditPw(true)}>Editar contraseña</div>
                            </div>
                        ) : (
                                <div className="w-100 px-3">
                                    <label htmlFor="actual">Contraseña actual</label>
                                    <input value={actual} className="mb-4 form-control" type="password" name="actual" onChange={evt => setActual(evt.target.value)} />
                                    <label htmlFor="pw">Nueva contraseña</label>
                                    <input value={pw} className="mb-4 form-control" type="password" name="pw" onChange={evt => setPw(evt.target.value)} />
                                    <label htmlFor="pw2">Confirmar contraseña</label>
                                    <input value={pw2} className="form-control" type="password" name="pw2" onChange={evt => setPw2(evt.target.value)} />
                                    <div className="row mt-4">
                                        <div className="col-12 d-flex justify-content-center">
                                            <div className="btn btn-primary m-4" onClick={() => setEditPw(false)}>Cancelar</div>
                                            <div className="btn btn-primary m-4" onClick={() => cambiarPw()}>Confirmar</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalEdit