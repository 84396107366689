import React, { useState, useContext, useEffect } from 'react';
import logo from './logo.svg';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Header, Footer, Loader } from './components/CIXLibrary'
import { LoadingContext } from './components/LoadingContext'
import { ApolloProvider } from '@apollo/react-hooks'
import PrivateRoute from "./containers/PrivateRoute"
import ApolloClient from 'apollo-boost'
import styled from 'styled-components'
import _ from 'lodash'
import './bootstrap.min.css';
import 'antd/dist/antd.css';
import "react-table/react-table.css";
import './App.scss';

import {
    HomePage,
    FourOhFourPage,
    AuthPage,
    AdminPage,
    GlobalPage,
    ComparaTeamPage,
    TeamPage,
    AgentePage,
    PerfilPage,
    DetalleMisionPage,
    GestionMisionesPage,
    HoliPage,
    RedirectToHTML,
    ActualizarPage
 } from './containers/PageList';

const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL + '/graphql',
    request: (operation) => {
        const token = localStorage.getItem('token')
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        })
    },
    onError: err => {
        console.log(err)
        const statusCodes = err.graphQLErrors.map(gqlErr => {
            return  _.get(gqlErr, "extensions.exception.output.statusCode")
        })
        console.log(statusCodes)
        if(statusCodes.includes(401)){
            window.location = "/auth/logout"
        }
    }
});

const App = () => {

    const [loadingCtx, setLoadingCtx] = useState({
        value: true,
        setLoading: bool => {
            setLoadingCtx({
                ...loadingCtx,
                value: bool
            })
        }
    })

    // useEffect(() => {
    //     console.log("Build 2020-02-11 11:25")
    // }, [])

    return (
        <ApolloProvider client={client}>
            {/* <BrowserRouter basename={"pre"}> */}
            <BrowserRouter>
            {/* <BrowserRouter> */}
                <LoadingContext.Provider value={{
                    loading: loadingCtx.value,
                    setLoading: loadingCtx.setLoading
                }}>
                    <MainContainer>
                        <Loader />
                        <Header />
                        <div style={{ flex: 1 }}>
                            <Switch>
                                <Route path="/auth/:show" exact component={AuthPage}></Route>
                                <PrivateRoute allowed={["Administrador", "Manager", "Agente"]} path="/" exact component={HomePage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador"]} path="/admin" exact component={AdminPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador"]} path="/holi" exact component={HoliPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador"]} path="/global" exact component={GlobalPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador"]} path="/comparar/" exact component={ComparaTeamPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador"]} path="/comparar/:idEquipo1" exact component={ComparaTeamPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador"]} path="/comparar/:idEquipo1/:idEquipo2" exact component={ComparaTeamPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador", "Manager"]} path="/equipo/:idEquipo" exact component={TeamPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador", "Manager"]} path="/gestionmisiones/:idEquipo" exact component={GestionMisionesPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador", "Manager", "Agente"]} path="/agente/:idAgente" exact component={AgentePage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador", "Manager", "Agente"]} path="/perfil" exact component={PerfilPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador", "Manager", "Agente"]} path="/detallemision/:idMision" exact component={DetalleMisionPage}></PrivateRoute>
                                <PrivateRoute allowed={["Administrador", "Manager", "Agente"]} path="/actualizar" exact component={ActualizarPage}></PrivateRoute>
                                <Route path="/encuesta/:nombreEncuesta" component={RedirectToHTML}></Route>
                                <Route path="" component={FourOhFourPage}></Route>
                            </Switch>
                        </div>
                        <Footer />
                    </MainContainer>
                </LoadingContext.Provider>
            </BrowserRouter>
        </ApolloProvider>
    );
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`
    
export default App;
    