import gql from 'graphql-tag'

const EDIT_TEMPORADA = gql`
    mutation UpdateTemporada(
        $id: ID!,
        $nombre: String
    ){
        updateTemporada(input: {
            where: {
                id: $id
            },
            data: {
                nombre: $nombre,
            }
        }){
            temporada{
                _id
                nombre
            }
        }
    }
`

export default EDIT_TEMPORADA