import moment from 'moment';
import { message } from 'antd'

export const sumar = (datos, clave) => (
    clave ? (
        datos.map(d => d[clave])
             .filter(e => (e !== undefined && typeof(e) === "number" && !Number.isNaN(e)))
             .reduce((a, b) => a + b, 0)
    ) : (
        datos.filter(e => (e !== undefined && typeof(e) === "number" && !Number.isNaN(e)))
             .reduce((a, b) => a + b, 0)
    )
)

export const media = (datos, clave, decimales = 2) => (
    clave ? (
        redondear(
            datos.map(d => d[clave])
                .filter(e => (e !== undefined && typeof(e) === "number" && !Number.isNaN(e)))
                .reduce((a, b) => a + b, 0) / datos.length
        , decimales)
    ) : (
        redondear(
            datos.filter(e => (e !== undefined && typeof(e) === "number" && !Number.isNaN(e)))
                .reduce((a, b) => a + b, 0) / datos.length
        , decimales)
    )
)

export const redondear = (dato, decimales = 0) => {
    const returnito = Math.round( dato * Math.pow( 10, decimales )) / Math.pow( 10, decimales )
    return Number.isNaN(returnito) ? 0 : returnito
}

/* eslint-disable no-useless-escape */
export const slugify = (string, arr = []) => {
    const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')
    let str = string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with ‘and’
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple — with single -
      .replace(/^-+/, '') // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
    str = str + "-1";
    return !arr.includes(str) ? str : (str.slice(0, -1) + (parseInt(arr.map(e => e.slice(0, -2)).filter(e => e === str.slice(0, -2)).length, 10) + 1))
    // return str
}

export const mayusculizer = (str, lower = true) => str.replace(/\s|-/g, " ,")
    .split(",")
    .map(c => {
        const firstChunk = c.slice(0, 1).toUpperCase()
        const lastChunk = lower ? c.slice(1).toLowerCase() : c.slice(1)
        return firstChunk + lastChunk
    })
    .reduce((a, b) => a + b, "")

export const aclararHex = (hex, delta, def) => {
    const hexOk = hex && typeof hex === "string" && hex[0] === "#" && hex.length === 7
    const defOk = def && typeof def === "string" && def[0] === "#" && def.length === 7

    let r = hexOk ? parseInt(hex.slice(1,3), 16) + delta : NaN
    let g = hexOk ? parseInt(hex.slice(3, 5), 16) + delta : NaN
    let b = hexOk ? parseInt(hex.slice(5), 16) + delta : NaN
    
    const rdef = defOk ? parseInt(def.slice(1,3), 16) + delta : NaN
    const gdef = defOk ? parseInt(def.slice(3, 5), 16) + delta : NaN
    const bdef = defOk ? parseInt(def.slice(5), 16) + delta : NaN

    if(r > 255){ r = 255 }
    else if(r < 0){ r = 0 }
    else if(Number.isNaN(r)){ 
        if(rdef > 255){ r = 255 }
        else if(rdef < 0 || Number.isNaN(rdef)){ r = 0 }
        else{ r = rdef }
    }

    if(g > 255){ g = 255 }
    else if(g < 0){ g = 0 }
    else if(Number.isNaN(g)){ 
        if(gdef > 255){ g = 255 }
        else if(gdef < 0 || Number.isNaN(gdef)){ g = 0 }
        else{ g = gdef }
    }

    if(b > 255){ b = 255 }
    else if(b < 0){ b = 0 } 
    else if(Number.isNaN(b)){ 
        if(bdef > 255){ b = 255 }
        else if(bdef < 0 || Number.isNaN(bdef)){ b = 0 } 
        else{ b = bdef }
    }

    return "#" + r.toString(16) + g.toString(16) + b.toString(16)
}

export const plantillaMail = (asuntoRaw, cuerpoRaw, variables) => {
    const subject = asuntoRaw.split(/\[|]/gi).map((ch, i) => {
        if(i%2){ return variables[ch] }
        else{ return ch }
    }).reduce((a, b) => a + b, "")
    const html = cuerpoRaw.split(/\[|]/gi).map((ch, i) => {
        if(i%2){ return variables[ch] }
        else{ return ch }
    }).reduce((a, b) => a + b, "")
    return { subject, html, text: html }
}

export const goToBaseURL = (history, inAuth) => {
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
    const role = userInfo && userInfo.role && userInfo.role.name
    // console.log({ userInfo })

    if(role === "Administrador"){
        history.push("/global")
    }
    else if(role === "Manager" && userInfo.trabajadorDe){
        history.push("/equipo/" + userInfo.trabajadorDe.slug)
    }
    else if(role === "Manager" && !userInfo.trabajadorDe){
        history.push("/perfil")
    }
    else if(role === "Agente"){
        history.push("/agente/" + userInfo.slug)
    }
    else if(!inAuth){
        history.push("/auth/login")
    }
}

export const presiosoJSON = obj => {
    const str = JSON.stringify(obj)
    const arr1 = str.split(/({)||(,)||(})/gm).filter(e => e)
    let level = 1
    const str1 = arr1.reduce((a, b) => {
        const add = b === "{" ? `{\n${Array(level).fill(null).map(() => "\t").reduce((a, b) => a + b, "")}` : (
                   (b === "," ? `,\n${Array(level - 1).fill(null).map(() => "\t").reduce((a, b) => a + b, "")}` : (
                   (b === "}" ? `\n${Array(level - 2).fill(null).map(() => "\t").reduce((a, b) => a + b, "")}}` : b ))))

        if((add !== b) && (b === "{")){ level = level + 1 }
        else if((add !== b) && (b === "}")){ level = level - 1 }
        return a + add
    }, "")
    return str1
}