import React from 'react'
import Modal from 'react-modal';
import styled from 'styled-components'
import { CIXButton } from './CIXLibrary'

const Alert = ({open, title, text, modalCb, style, confirm, confirmFn, confirmArgs}) => {
    Modal.setAppElement('body');
    return (
        <Modal 
          isOpen={open}                    
          onRequestClose={() => modalCb(false)}
          contentLabel={title}
          overlayClassName="Overlay"
          className="ModalAlert"
        >
            <Wrapper>
                <strong>{title}</strong>
                <p>{text}</p>
                { !confirm ? <CIXButton style={styles} onClick={() => modalCb(false)}>Aceptar</CIXButton> : (
                    <ButtonWrapper>
                        <CIXButton className="mr-4" style={styles} onClick={() => { modalCb(false); confirmFn(true, confirmArgs)}}>Aceptar</CIXButton>
                        <CIXButton style={styles} onClick={() => { modalCb(false); confirmFn(false, confirmArgs)}}>Cancelar</CIXButton>
                    </ButtonWrapper>
                )}
            </Wrapper>
        </Modal>
    )
}

const styles = {
    minWidth: 150, 
    maxWidth: 250, 
    margin: "0 auto"
}

const Wrapper = styled.div`
    border-radius: 5px;
    background-color: white;
    z-index: 5;
    padding: 2rem;
    max-width: 500px;
    max-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: overlay;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default Alert

