import gql from 'graphql-tag'

const GET_USERS = gql`
    {
        users{
            _id
            nombre
            apellidos
            slug
            email
            username
            confirmed
            imagen{
                url
            }
            managerDe{
                _id
                nombre
            }
            trabajadorDe{
                _id
                nombre
            }
            role{
                _id
                name
            }
        }
    }
`

export default GET_USERS