import gql from 'graphql-tag'

const GET_ADMIN_DATA = gql`
    {
        configs{
            estructuraJerarquica
            indicadores
            ayudaManager { url }
            ayudaUsuario { url }
            ayudaAdmin { url }
        }
        roles{
            _id
            name
        }
        equipos{
            _id
            nombre
            idequipo
            lat
            lng
            imagen{
                url
            }
            nivel
            hijos{
                _id
                nombre
                nivel
            }
            padres{
                _id
                nombre
                nivel
            }
            trabajadores{
                _id
                nombre
                apellidos
                email
            }
            managers{
                _id
                nombre
                apellidos
                email
            }
        }
        users{
            _id
            nombre
            apellidos
            email
            username
            confirmed
            managerDe{
                _id
                nombre
            }
            trabajadorDe{
                _id
                nombre
            }
            role{
                _id
                name
            }
        }
    }
`

export default GET_ADMIN_DATA