import React, { useState, useEffect, useContext } from 'react'
import Dropzone from 'react-dropzone'
import Moment from 'moment'
import { Alert } from "../CIXLibrary";
import _ from 'lodash'
import XLSX from 'xlsx'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_MISIONINSTANCIAS, EDIT_MISIONINSTANCIA, EDIT_RETOINSTANCIA } from '../../queries/queryList';
import { Icon, message } from 'antd';
import { groupBy, fromPairs } from 'lodash'
import { LoadingContext } from '../LoadingContext';

const BloqueSubida = ({ data, history }) => {

    const [table, setTable] = useState()
    const [indic, setIndic] = useState({})
    const [misionesActivas, setMisionesActivas] = useState()
    const [msgEmailInvalido, setMsgEmailInvalido] = useState("")
    const [alertEmailInvalido, setAlertEmailInvalido] = useState(false)
    const [alertCargaDatosOk, setAlertCargaDatosOk] = useState(false)
    const [alertCargaDatosMal, setAlertCargaDatosMal] = useState(false)
    const [alertSinMisiones, setAlertSinMisiones] = useState(false)
    const [alertCargaSinMisiones, setAlertCargaSinMisiones] = useState(false)
    const [mediasNeedRecalc, setMediasNeedRecalc] = useState([])

    const [waitLength, setWaitLength] = useState(0)
    const [iterator, setIterator] = useState(1)
    const [toRecalc, setToRecalc] = useState([])

    const { loading: loadingMI, error: errorMI, data: dataMI, refetch: refetchMI } = useQuery(GET_MISIONINSTANCIAS, {variables: { where: { estado: "activa" }}})

    const [editarMisionInstanciaMt, { data: dataEdit, error: errorEdit }] = useMutation(EDIT_MISIONINSTANCIA)
    const [editarRetoInstanciaMt, { data: dataRIEdit, error: errorRIEdit }] = useMutation(EDIT_RETOINSTANCIA)

    const { setLoading } = useContext(LoadingContext)

    useEffect(() => {
        refetchMI()
    }, [history])

    useEffect(() => { 
        if(dataEdit){ 
            console.log({ iterator, waitLength })
            message.success("Se ha completado correctamente el "+data.configs[0].literales.mision.sing+" de " + dataEdit.updateMisioninstancia.misioninstancia.user.nombre + " " + dataEdit.updateMisioninstancia.misioninstancia.user.apellidos) 
            if(iterator === waitLength){
                fetch(process.env.REACT_APP_API_URL + "/calcula-medias", {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify({ misionesNuevas: [...toRecalc, dataEdit.updateMisioninstancia.misioninstancia._id] })
                }).then(resp => resp.json()).then(data => {
                    console.log(data)
                })
            }
            else{
                setIterator(iterator + 1)
                setToRecalc([...toRecalc, dataEdit.updateMisioninstancia.misioninstancia._id])
            }
            setMediasNeedRecalc()
        }
    }, [dataEdit, errorEdit])
    useEffect(() => { 
        if(errorEdit){ 
            console.log(errorEdit)
            message.error("Ha fallado la subida de datos") 
            if(iterator === waitLength){
                console.log("err Acabado!")
            }
            else{
                setIterator(iterator + 1)
            }
        }
    }, [errorEdit])

    useEffect(() => {
        if(data && data.configs && Array.isArray(data.configs)){
            setIndic(data.configs[0].indicadores)
        }
    }, [data])
    useEffect(() => {
        if(dataMI && Array.isArray(dataMI.misioninstancias)){
            setMisionesActivas(dataMI.misioninstancias.filter(m => m.user))
        }
    }, [dataMI])
    
    const completarMisiones = uploaded => {
        setLoading(true)
        var errors = []
        var file = uploaded[0], read = new FileReader();
        read.readAsBinaryString(file);
        read.onloadend = async () => {
            var oFile = XLSX.read(read.result, {type: 'binary' })
            const table = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {cellNF:true, raw:false})
            setTable(table)

            //Perdóneme padre porque he pecado
            const indicKeys = Object.keys(table[0]).filter(k => Object.values(indic).map(ind => ind.nombre).includes(k))
            const procesada = table.map((row, i) => {
                // const ma = misionesActivas.find(ma => ma.user.email === row.email)
                // if(!ma){ errors.push(i + 2) }
                const convert = indicKeys.map(indk => Object.entries(indic).find(ind => ind[1].nombre === indk))
                const valores = indicKeys.map(indk => ({ [convert.find(c => c[1].nombre === indk)[0]]: row[indk] })).reduce((a, b) => ({ ...a, ...b }), {})
                const cajas = {
                    entrenamiento: ["si", "sí"].includes(row.entrenamiento.toLowerCase()),
                    reto: ["si", "sí"].includes(row.retoCompletado.toLowerCase()),
                    finalizacion: ["si", "sí"].includes(row.misionFinalizada.toLowerCase())
                }
                let retosInt = groupBy(Object.entries(row), k => {
                    const kSplit = k[0].split(/(?=Reto\s)/gm)
                    if(kSplit[1]){ return kSplit[1] }
                })
                delete retosInt["undefined"]
                const retos = Object.values(retosInt).map(e => e.map(f => ({ [f[0].split(" Reto")[0]]: f[1] })).reduce((a, b) => ({...a,...b}), {}))
                return {...row, cajas, valores, retos}
            })

            console.log(procesada)
            setWaitLength(procesada.length)

            if( errors.length > 0 ){
                setLoading(false)
                setAlertEmailInvalido(true)
                setMsgEmailInvalido(`Las siguientes filas del documento no se corresponden con una ${data.configs[0].literales.mision.sing} activa: ${errors.reduce((a, b) => a + ", " + b, "").slice(2)}. Subida de datos cancelada.`)
            }
            else{
                for (const row of procesada){
                    console.log(row)
                    if(row.idMision){
                        row.retos.forEach(async ri => {
                            await editarRetoInstanciaMt({
                                variables: {
                                    id: ri.ID,
                                    valor: parseFloat(ri.Valor),
                                    resultado: parseFloat(ri.Resultado)
                                }
                            })
                        })
                        await editarMisionInstanciaMt({
                            variables: {
                                id: row.idMision,
                                valores: row.valores,
                                cajas: row.cajas,
                                estado: "completada"
                            }
                        })
                    }
                }
                refetchMI()
                setLoading(false)
            }
        }
    }
    
    const descargarPlantilla = () => {

        if( misionesActivas.length === 0 ){
            setAlertSinMisiones(true)
        }
        else{
            const valores = Object.values(indic).map(ind => ({ [ind.nombre]: "" })).reduce((a, b) => ({...a, ...b}), {})

            const nMaxRetos = Math.max.apply(null, misionesActivas.map(ma => ma.retoinstancias.length))

            const dataProc = misionesActivas.map(m => {
                const manager = m.user && m.user.trabajadorDe && m.user.trabajadorDe.managers && 
                                m.user.trabajadorDe.managers.find(mng => mng.trabajadorDe && ( mng.trabajadorDe._id === m.user.trabajadorDe._id ));

                m.retoinstancias.sort((a, b) => {
                    if(a.reto.nombre > b.reto.nombre){ return 1 }
                    else if(a.reto.nombre < b.reto.nombre){ return -1 }
                    else{ return 0 }
                })

                const retos = Array(nMaxRetos).fill(null).map((e, i) => ({
                    [`Nombre Reto ${i}`]: m.retoinstancias[i] && m.retoinstancias[i].reto.nombre,
                    [`Objetivo Reto ${i}`]: m.retoinstancias[i] && m.retoinstancias[i].objetivo,
                    [`Resultado Reto ${i}`]: "",
                    [`Valor Reto ${i}`]: "",
                    [`ID Reto ${i}`]: m.retoinstancias[i] && m.retoinstancias[i]._id,
                })).reduce((a, b) => ({ ...a, ...b }), {})
                const obj = {
                    idMision: m._id,
                    equipo: m.user.trabajadorDe.nombre,
                    manager: manager && ( manager.nombre + " " + manager.apellidos ),
                    emailManager: manager && manager.email,
                    nombre: m.user.nombre + " " + m.user.apellidos,
                    email: m.user.email,
                    comentario: m.comentario,
                    ...valores,
                    ...retos,
                    entrenamiento: "",
                    retoCompletado: "",
                    misionFinalizada: ""
                }
                return obj
            })
            
            let sheet = XLSX.utils.json_to_sheet(dataProc)  
            let workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet, "Plantilla");
            XLSX.writeFile(workbook, `Plantilla ${Moment().format("YYYY-MM-DD")}.xlsx`)
        }
    }
    
    return (
        <div>
            <div className="row">
                <div className="col jumbo sombra p-4 text-center d-flex flex-column align-items-center">
                    <h3 className="pb-2">Subida de datos de {data && data.configs && data.configs[0] && data.configs[0].literales.mision.plur} completados</h3>
                    <p className="pb-4">{data && data.configs && data.configs[0] && _.capitalize(data.configs[0].literales.mision.plur)} pendientes de completar: { misionesActivas && misionesActivas.length }</p>
                    <div className="btn btn-secondary mb-4" onClick={() => descargarPlantilla()}>
                        Descargar plantilla para la subida de datos
                    </div>                               
                    <Dropzone onDrop={file => completarMisiones(file)}>
                    {({getRootProps, getInputProps}) => (
                        <div className="btn btn-primary p-4" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Icon type="form" />
                            <p className="mt-2 mb-0 mx-4">Selecciona una hoja de cálculo o arrástrala aquí</p>
                        </div>
                    )}
                    </Dropzone>
                </div>
            </div>
            <Alert 
                open={alertEmailInvalido}
                text={msgEmailInvalido}
                // confirm={true}
                // confirmFn={handleCompletadas}
                modalCb={bool => setAlertEmailInvalido(bool)}
            />  
            <Alert 
                open={alertCargaDatosOk}
                text={`La carga de datos se ha completado correctamente`}
                modalCb={bool => setAlertCargaDatosOk(bool)}
            />            
            <Alert 
                open={alertCargaDatosMal}
                text={`Se ha producido un error durante la carga de datos`}
                modalCb={bool => setAlertCargaDatosMal(bool)}
            />  
            <Alert 
                open={alertSinMisiones}
                text={`No hay ${data && data.configs && data.configs[0] && data.configs[0].literales.mision.plur} por completar, no se puede generar una plantilla`}
                modalCb={bool => setAlertCargaSinMisiones(bool)}
            />  
        </div>
    )
}

export default BloqueSubida
                
                
                
                
                