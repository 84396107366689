import React from 'react'
import styled from 'styled-components'

const FourOhFourPage = () => {
    return (
        <div>
            FourOhFourPage
        </div>
    )
}

export default FourOhFourPage
