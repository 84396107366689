import gql from 'graphql-tag'

const ADD_TEMPORADA = gql`
    mutation CreateUser(
        $nombre: String!
    ){
        createTemporada(input: {
            data: {
                nombre: $nombre,
            }
        }){
            temporada {
                _id
                nombre
            }
        }
    }
`

export default ADD_TEMPORADA