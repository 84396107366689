import React from 'react'
import styled from 'styled-components'

const TableIconWrapper = ({ children }) => <Wrapper>{ children }</Wrapper>

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%
  .ant-tag {
    height: 20px;
    display: flex;
    align-items: center;
    transition: all 300ms;
  }
  .ant-tag:hover{
    filter: brightness(0.9)
  }
`

export default TableIconWrapper