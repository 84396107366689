import gql from 'graphql-tag'

const EDIT_CONFIG = gql`
    mutation UpdateConfig(
        $id: ID!,
        $enviarEquipoCompletoPreasignado: Boolean,
        $plantillaEquipoCompletoPreasignado: String,
        $asuntoEquipoCompletoPreasignado: String,
        $enviarMisionValidada: Boolean,
        $plantillaMisionValidada: String,
        $asuntoMisionValidada: String,
        $enviarNuevoUsuario: Boolean,
        $plantillaNuevoUsuario: String,
        $asuntoNuevoUsuario: String,
        $enviarMisionEditada: Boolean,
        $plantillaMisionEditada: String,
        $asuntoMisionEditada: String,
        $correoSaliente: String,
        $enviarCambioPw: Boolean,
        $plantillaCambioPw: String,
        $asuntoCambioPw: String,
        $plantillaFirma: String,
        $firmaEquipoCompletoPreasignado: Boolean,
        $firmaMisionValidada: Boolean,
        $firmaNuevoUsuario: Boolean,
        $firmaCambioPw: Boolean,
        $enviarManagerEncuesta: Boolean,
        $plantillaManagerEncuesta: String,
        $asuntoManagerEncuesta: String,
        $enviarAgenteValorado: Boolean,
        $plantillaAgenteValorado: String,
        $asuntoAgenteValorado: String,
        $enviarManagerComentario: Boolean,
        $plantillaManagerComentario: String,
        $asuntoManagerComentario: String,
        $fechaUltimaSubidaDatos: DateTime,
        $fechaUltimosDatosSubidos: DateTime,
        $estructuraJerarquica: JSON,
        $nombrePlat: String,
        $indicadores: JSON,
        $copyright: String,
        $colores: JSON,
        $temporadaActual: ID,
        $literales: JSON,
        $mediasTrueSumasFalse: Boolean
    ){
        updateConfig(input: {
            where: {
                id: $id
            },
            data: {
                enviarEquipoCompletoPreasignado: $enviarEquipoCompletoPreasignado,
                plantillaEquipoCompletoPreasignado: $plantillaEquipoCompletoPreasignado,
                asuntoEquipoCompletoPreasignado: $asuntoEquipoCompletoPreasignado,
                enviarMisionValidada: $enviarMisionValidada,
                plantillaMisionValidada: $plantillaMisionValidada,
                asuntoMisionValidada: $asuntoMisionValidada,
                enviarNuevoUsuario: $enviarNuevoUsuario,
                plantillaNuevoUsuario: $plantillaNuevoUsuario,
                asuntoNuevoUsuario: $asuntoNuevoUsuario,
                enviarMisionEditada: $enviarMisionEditada,
                plantillaMisionEditada: $plantillaMisionEditada,
                asuntoMisionEditada: $asuntoMisionEditada,
                correoSaliente: $correoSaliente,
                enviarCambioPw: $enviarCambioPw,
                plantillaCambioPw: $plantillaCambioPw,
                asuntoCambioPw: $asuntoCambioPw,
                plantillaFirma: $plantillaFirma,
                firmaEquipoCompletoPreasignado: $firmaEquipoCompletoPreasignado,
                firmaMisionValidada: $firmaMisionValidada,
                firmaNuevoUsuario: $firmaNuevoUsuario,
                firmaCambioPw: $firmaCambioPw,
                enviarManagerEncuesta: $enviarManagerEncuesta,
                plantillaManagerEncuesta: $plantillaManagerEncuesta,
                asuntoManagerEncuesta: $asuntoManagerEncuesta,
                enviarAgenteValorado: $enviarAgenteValorado,
                plantillaAgenteValorado: $plantillaAgenteValorado,
                asuntoAgenteValorado: $asuntoAgenteValorado,
                enviarManagerComentario: $enviarManagerComentario,
                plantillaManagerComentario: $plantillaManagerComentario,
                asuntoManagerComentario: $asuntoManagerComentario,
                fechaUltimaSubidaDatos: $fechaUltimaSubidaDatos,
                fechaUltimosDatosSubidos: $fechaUltimosDatosSubidos,
                estructuraJerarquica: $estructuraJerarquica,
                nombrePlat: $nombrePlat,
                indicadores: $indicadores,
                copyright: $copyright,
                colores: $colores,
                temporadaActual: $temporadaActual,
                literales: $literales,
                mediasTrueSumasFalse: $mediasTrueSumasFalse
            }
        }){
            config{
                _id
                enviarEquipoCompletoPreasignado
                plantillaEquipoCompletoPreasignado
                asuntoEquipoCompletoPreasignado
                enviarMisionValidada
                plantillaMisionValidada
                asuntoMisionValidada
                enviarNuevoUsuario
                plantillaNuevoUsuario
                asuntoNuevoUsuario
                enviarMisionEditada
                plantillaMisionEditada
                asuntoMisionEditada
                correoSaliente
                enviarCambioPw
                plantillaCambioPw
                asuntoCambioPw
                plantillaFirma
                firmaEquipoCompletoPreasignado
                firmaMisionValidada
                firmaNuevoUsuario
                firmaCambioPw
                ayudaManager{ url }
                ayudaUsuario{ url }
                ayudaAdmin{ url }
                enviarManagerEncuesta
                plantillaManagerEncuesta
                asuntoManagerEncuesta
                enviarAgenteValorado
                plantillaAgenteValorado
                asuntoAgenteValorado
                enviarManagerComentario
                plantillaManagerComentario
                asuntoManagerComentario
                fechaUltimaSubidaDatos
                fechaUltimosDatosSubidos
                estructuraJerarquica
                logo{ url }
                nombrePlat
                indicadores
                copyright
                colores
                temporadaActual{
                    _id
                    nombre
                }
                literales
                mediasTrueSumasFalse
            }
        }
    }
`

export default EDIT_CONFIG