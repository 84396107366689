import React, { useEffect } from 'react'

const RedirectToHTML = ({ history }) => {

    useEffect(() => {
        if(!history.location.pathname.includes(".html")){
            window.location = history.location.pathname + ".html"
        }
    }, [])

    return (
        <div></div>
    )
}

export default RedirectToHTML
