import React, { useState, useEffect } from 'react';
import { Icon, message, Tag } from 'antd';
import { Link } from 'react-router-dom'
import { Alert, TableIconWrapper, CIXButton } from "../CIXLibrary";
import Modal from 'react-modal';
import Select from "react-select";
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import "react-table/react-table.css";
import { mayusculizer, slugify } from '../../utils/func';
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash';
import XLSX from 'xlsx'
import {
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
  GET_USERS
} from '../../queries/queryList'
import Dropzone from 'react-dropzone';
import { plantillaMail } from '../../utils/func';

const BloqueUsuarios = ({ data }) => {

  const reset = { label: "-", value: null }
  const pwAleatoria = (longitud = 8) => {
    const str = "qwertyuiopasdfghjklzxcvbnmmQWERTYUIOPASDFGHJKLZXCVBNM1234567890"
    return Array(longitud).fill(null).map(e => str[Math.trunc(Math.random()*str.length)]).reduce((a,b) => a+b, "")
  }

  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [email, setEmail] = useState("")
  const [config, setConfig] = useState({
    plantillaFirma: "",
    enviarNuevoUsuario: true, 
    firmaNuevoUsuario: true, 
    asuntoNuevoUsuario: "",
    plantillaNuevoUsuario: "",        
    enviarCambioPw: true,
    firmaCambioPw: true,
    asuntoCambioPw: "",
    plantillaCambioPw: ""
  })
  const [users, setUsers] = useState([])
  const [equipo, setEquipo] = useState(reset)
  const [rol, setRol] = useState(reset)
  const [rolEdit, setRolEdit] = useState(reset)
  const [selEquipos, setSelEquipos] = useState([reset])
  const [selRoles, setSelRoles] = useState([reset])
  const [autopw, setAutopw] = useState(true)
  const [autopwEdit, setAutopwEdit] = useState(true)
  const [password, setPassword] = useState(pwAleatoria())
  const [passwordEdit, setPasswordEdit] = useState(pwAleatoria())
  const [managerDe, setManagerDe] = useState([])
  const [managerDeEdit, setManagerDeEdit] = useState([])
  const [nuevoUsuario, setNuevoUsuario] = useState(false)
  const [errors, setErrors] = useState([])
  const [usuarioBorrar, setUsuarioBorrar] = useState()

  const [nombreEdit, setNombreEdit] = useState()
  const [apellidosEdit, setApellidosEdit] = useState()
  const [pwEdit, setPwEdit] = useState()
  const [pwEditErr, setPwEditErr] = useState()
  const [pwErr, setPwErr] = useState()
  const [emailEdit, setEmailEdit] = useState()
  const [emailEditErr, setEmailEditErr] = useState()
  const [equipoEdit, setEquipoEdit] = useState()
  const [statusEdit, setStatusEdit] = useState()
  const [emailOriginal, setEmailOriginal] = useState()
  const [loading, setLoading] = useState()
  const [modalEdit, setModalEdit] = useState({ open: false, row: {}})
  const [idEdit, setIdEdit] = useState("")
  const [asuntoEdit, setAsuntoEdit] = useState("")
  const [cuerpoEdit, setCuerpoEdit] = useState("")

  const [alertSinCorreo, setAlertSinCorreo] = useState(false)
  const [alertEditSinCorreo, setAlertEditSinCorreo] = useState(false)
  const [alertNuevoUsuarioOk, setAlertNuevoUsuarioOk] = useState(false)
  const [alertNuevoUsuarioMal, setAlertNuevoUsuarioMal] = useState(false)
  const [alertEditUsuarioOk, setAlertEditUsuarioOk] = useState(false)
  const [alertEditUsuarioMal, setAlertEditUsuarioMal] = useState(false)
  const [alertCorreoFallo, setAlertCorreoFallo] = useState(false)
  const [alertCorreoRepe, setAlertCorreoRepe] = useState(false)
  const [confirmBorraUsuario, setConfirmBorraUsuario] = useState({ open: false, row: {}})
  const [alertUsuarioBorrado, setAlertUsuarioBorrado] = useState(false)
  const [alertFalloUsuarioBorrado, setAlertFalloUsuarioBorrado] = useState(false)

  const [nuevoUsuarioMt, { data: dataNuevo, error: errorNuevo }] = useMutation(ADD_USER, { refetchQueries: [{ query: GET_USERS }] })
  const [editarUsuarioMt, { data: dataEdit, error: errorEdit }] = useMutation(EDIT_USER, { refetchQueries: [{ query: GET_USERS }] })
  const [borrarUsuarioMt, { data: dataBorrado, error: errorBorrado }] = useMutation(DELETE_USER, { refetchQueries: [{ query: GET_USERS }] })

  useEffect(() => {
    if(data){
      const niveles = Object.values(data.configs[0].estructuraJerarquica)
      let selRoles = data.roles.filter(r => r.name !== "Public").map(r => ({ value: r._id, label: r.name }))
      let selEquipos = data.equipos.map(r => ({ value: r._id, label: r.nombre + " - " + mayusculizer(niveles.find((n, i) => i === r.nivel).sing) }))
      selEquipos.unshift({ label: "-", value: null })
      setSelEquipos(selEquipos)
      setSelRoles(selRoles)
      setRol(selRoles.find(r => r.label === "Agente"))
      setConfig(data.configs[0])
      setUsers(data.users)
    }
  }, [data])

    
  useEffect(() => {
    if(data && modalEdit.row && modalEdit.row.original){
      if(modalEdit.open){
        setNombreEdit(modalEdit.row.original.nombre)
        setApellidosEdit(modalEdit.row.original.apellidos)
        setEmailEdit(modalEdit.row.original.email)
        setRolEdit(selRoles.find(r => r.value === modalEdit.row.original.role?._id))
        if(modalEdit.row.original.trabajadorDe){
          setEquipoEdit(selEquipos.find(e => e.value === modalEdit.row.original.trabajadorDe._id))
        }
        setManagerDeEdit(modalEdit.row.original.managerDe)
        setStatusEdit(modalEdit.row.original.confirmed)
        setEmailOriginal(modalEdit.row.original.email)
      }
    }
  }, [modalEdit])

  const thisTab = window.localStorage.getItem("lastTab") === "usuarios"
  useEffect(() => { if(thisTab && dataNuevo){ 
    message.success("Se ha creado correctamente el usuario " + dataNuevo.createUser.user.email)
    setNombre("")
    setApellido("")
    setEmail("")
    setEquipo({ label: " - ", value: "" })
    setRol({ label: " - ", value: "" })
    setManagerDe([])
    setAutopw(true)
    setPassword(pwAleatoria())
    if(config.enviarNuevoUsuario){
      enviarCorreo("nuevoUsuario", {...dataNuevo.createUser.user, password }) 
    }
  }}, [dataNuevo])
  useEffect(() => { if(thisTab && errorNuevo){ message.error("Ha fallado el proceso de creación del usuario") } }, [errorNuevo])
  useEffect(() => { if(thisTab && dataBorrado){ message.success("Se ha borrado correctamente el usuario") } }, [dataBorrado])
  useEffect(() => { if(thisTab && errorBorrado){ message.error("Ha fallado el proceso de borrado del usuario") } }, [errorBorrado])
  useEffect(() => { if(thisTab && dataEdit){ 
    message.success("Se ha editado correctamente el usuario") 
    if(pwEdit && config.enviarCambioPw){
      enviarCorreo("cambioPw", {...dataEdit.updateUser.user, password: passwordEdit })
    }
    setModalEdit({ open: false, row: {}})
  }}, [dataEdit])
  useEffect(() => { if(thisTab && errorEdit){ message.error("Ha fallado el proceso de edición del usuario") }}, [errorEdit])
  
  const crearUsuario = conf => {
    const managerDeMod = rol.label === "Manager" ? managerDe.map(item => item.value) : []
    if(validarFormulario("crear")){
      if(conf === undefined && !config.enviarNuevoUsuario){ setAlertSinCorreo(true) }
      else if(conf === true || config.enviarNuevoUsuario){
        nuevoUsuarioMt({ variables: {
          username: email,
          email,
          password,
          confirmed: true,
          blocked: false,
          role: rol.value,
          trabajadorDe: equipo.value,
          managerDe: managerDeMod,
          nombre,
          apellidos: apellido,
          slug: slugify(nombre + " " + apellido, users.map(u => u.slug))
        }})
      }
    }
  }

  const enviarCorreo = async (tipo, user) => {
    console.log({ user })
    const asunto = tipo === "nuevoUsuario" ? config.asuntoNuevoUsuario : (
                   tipo === "cambioPw" ? config.asuntoCambioPw : ""
    )
    const plantilla = tipo === "nuevoUsuario" ? config.plantillaNuevoUsuario : (
                      tipo === "cambioPw" ? config.plantillaCambioPw : ""
    )
    // const pwToMail = tipo === "nuevoUsuario" ? password : (
    //                  tipo === "cambioPw" ? pwEdit : ""
    // )
    const firma = config.firmaNuevoUsuario ? ("<br /><hr /><br />" + config.plantillaFirma) : ""
    const emailito = await ( fetch(process.env.REACT_APP_API_URL + "/email", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + window.localStorage.getItem("token")
        },
        body: JSON.stringify({
            to: user.email,
            from: config.correoSaliente,
            ...plantillaMail(asunto, plantilla + firma, user)
        })
    }))
    console.log({ emailito })
  }

 
  const editarUsuario = (bool, row) => {
    if( validarFormulario("editar") ){
      if(bool === undefined && pwEdit && !config.enviarCambioPw){ 
        setAlertEditSinCorreo(true) 
      }
      else if((pwEdit && !config.enviarCambioPw && bool) || bool === undefined ){
        const managerDeEditMod = rolEdit.label === "Manager" ? managerDeEdit.map(item => item._id) : []
        let variables = {
          id: row.original._id,
          username: emailEdit,
          email: emailEdit,
          password: passwordEdit,
          confirmed: statusEdit,
          blocked: !statusEdit,
          role: rolEdit.value,
          trabajadorDe: equipoEdit && equipoEdit.value,
          managerDe: managerDeEditMod,
          nombre: nombreEdit, 
          apellidos: apellidosEdit,
          slug: slugify(nombreEdit + " " + apellidosEdit, users.map(u => u.slug))
        }
        if( emailOriginal === emailEdit ){ 
          delete variables["email"] 
          delete variables["username"]
        }
        if(!pwEdit){
          delete variables["password"]
        }
        editarUsuarioMt({ variables })
      }
    }
  }
  
  const validarEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  const validarFormulario = tipo => {
    let errors = [];
    if(tipo === "crear"){
      if( nombre === "" ){ errors.push("nombre") }
      if( apellido === "" ){ errors.push("apellido") }
      if( email === "" ){ errors.push("email") }
      if( config.enviarNuevoUsuario && !config.asuntoNuevoUsuario ){ errors.push("asunto") }
      if( config.enviarNuevoUsuario && !config.plantillaNuevoUsuario ){ errors.push("cuerpo") }
      if( rol.value === null ){ errors.push("rol") }
      if( rol.label === "Manager" && managerDe.length === 0 ){ errors.push("managerDe") }
      if( rol.label === "Agente" && equipo.value === null ){ errors.push("equipo") }
      if( !autopw && password.length < 6 ){ 
        if(password === ""){ setPwErr("Se requiere este campo") }
        else if(password.length <= 6){ setPwErr("La contraseña ha de tener un mínimo de 6 caracteres") }
        else{ setPwErr("La contraseña no es válida") }
        errors.push("password") 
      }
    }
    if(tipo === "editar"){
      if( nombreEdit === "" ){ errors.push("nombreEdit") }
      if( apellidosEdit === "" ){ errors.push("apellidosEdit") }
      if( !validarEmail( emailEdit )){ 
        if(emailEdit === ""){ setEmailEditErr("El campo 'correo' no puede estar vacío") }
        else{ setEmailEditErr("El correo introducido no es válido") }
        errors.push("emailEdit") 
      }
      if( rolEdit.label === "Manager" && managerDeEdit.length === 0 ){ errors.push("managerDeEdit") }
      if( rolEdit.value === null ){ errors.push("rolEdit") }
      if( !autopwEdit && passwordEdit.length < 6 ){ 
        if(passwordEdit === ""){ setPwEditErr("Se requiere este campo") }
        else if(passwordEdit.length <= 6){ setPwEditErr("La contraseña ha de tener un mínimo de 6 caracteres") }
        else{ setPwEditErr("La contraseña no es válida") }
        errors.push("passwordEdit") 
      }
      
    }
    setErrors(errors)
    
    return errors.length === 0
  }
  
  const borrarUsuario = (bool, args) => {
    console.log(args)
    if(bool){ borrarUsuarioMt({ variables: { id: args.original._id }}) }
  }


  const subidaMasivaUser =uploaded => {
    var file = uploaded[0], read = new FileReader();
    console.log(file)
    read.readAsBinaryString(file);
    read.onloadend = async () => {
      var oFile = XLSX.read(read.result, {type: 'binary' })
      const table = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {cellNF:true, raw:false})
      const slugArr = users.map(u => u.slug)
      const procesada = table.map((r, i) => {
        const equipo = data.equipos.find(e => e.idequipo === r.trabajadorDe)
        const managerDe = r.managerDe && r.managerDe.split(",").map(idequipo => {
          const eq = data.equipos.find(e => e.idequipo === idequipo)
          return eq && eq._id
        }).filter(e => e)
        const rol = data.roles.find(rol => rol.name === (r.role || "Agente"))
        return ({
          username: r.email,
          email: r.email,
          password: "lyntia",
          nombre: r.nombre,
          apellidos: r.apellidos,
          confirmed: true,
          blocked: false,
          lugar: r.lugar,
          fechaContrato: r.fechaContrato,
          slug: slugify(r.nombre + " " + r.apellidos, slugArr),
          trabajadorDe: equipo && equipo._id,
          managerDe,
          role: rol && rol._id
        })
      })

      for (const newUser of procesada){
        await nuevoUsuarioMt({ variables: {
          username: newUser.username,
          email: newUser.email,
          password: newUser.password,
          nombre: newUser.nombre,
          apellidos: newUser.apellidos,
          confirmed: newUser.confirmed,
          blocked: newUser.blocked,
          lugar: newUser.lugar,
          fechaContrato: newUser.fechaContrato,
          slug: newUser.slug,
          trabajadorDe: newUser.trabajadorDe,
          managerDe: newUser.managerDe,
          role: newUser.role
        }})
      }
    }
  }
  
  return (
    <div className="w-100">
      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4 finalizaActivas">
          <div className="d-flex w-100 transi" onClick={() => setNuevoUsuario(!nuevoUsuario)}>
            <h3 className="mr-2">Nuevo usuario</h3>
            <Icon type={`caret-${nuevoUsuario ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
          </div>
          
          { nuevoUsuario && (
            <div className="mt-4">
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input 
                  name="nombre" 
                  className={`form-control ${errors.includes("nombre") && "is-invalid"}`} 
                  type="text" 
                  value={nombre} 
                  onChange={evt => setNombre(evt.target.value)}
                />
                <div className="invalid-feedback">Se requiere este campo</div>
              </div>            
              <div className="form-group">
                <label htmlFor="apellido">Apellido</label>
                <input 
                  name="apellido" 
                  className={`form-control ${errors.includes("apellido") && "is-invalid"}`} 
                  type="text" 
                  value={apellido} 
                  onChange={evt => setApellido(evt.target.value)}
                />
                <div className="invalid-feedback">Se requiere este campo</div>
              </div>            
              <div className="form-group">
                <label htmlFor="nombre">Email</label>
                <input 
                  name="email" 
                  className={`form-control ${errors.includes("email") && "is-invalid"}`} 
                  type="text" 
                  value={email} 
                  onChange={evt => setEmail(evt.target.value)}
                />
                <div className="invalid-feedback">Se requiere este campo</div>
              </div>              
              <div className="form-group">
                <label htmlFor="equipo">Equipo</label>
                <Select className={`p-0 ${errors.includes("equipo") && "is-invalid"}`}
                  classNamePrefix="moradito"
                  value={equipo}
                  onChange={equipo => setEquipo(equipo)}
                  options={selEquipos}
                  placeholder={"Equipo"}
                />
                <div className="invalid-feedback">Los {config.literales && config.literales.agente.plur} han de estar asignados a un equipo</div>
              </div>             
              <div className="form-group">
                <label htmlFor="rol">Rol de usuario</label>
                <Select className={`p-0 ${errors.includes("rol") && "is-invalid"}`}
                  classNamePrefix="moradito"
                  value={rol}
                  onChange={rol => setRol(rol)}
                  options={selRoles}
                  placeholder={"Rol"}
                />
                <div className="invalid-feedback">Es necesario asignar un rol al usuario</div>
              </div>  
              {(rol && rol.label === "Manager") && (
                <div className="mb-4">
                  <div className="form-group">
                    <label htmlFor="rol">Manager de</label>
                    <Select className={`p-0 ${errors.includes("managerDe") && "is-invalid"}`}
                      classNamePrefix="moradito"
                      value={managerDe.slice[-1]}
                      onChange={equipo => {
                        if(!managerDe.includes(equipo) && equipo.value){
                          setManagerDe([...managerDe, equipo])
                        }
                      }}
                      options={selEquipos}
                      placeholder={"Equipo"}
                    />
                    <div className="invalid-feedback">Un manager ha de tener asignado al menos un equipo</div>
                  </div>  
                  { managerDe.map((e, i) => (
                    <a 
                      href="#" 
                      key={`m${i}`} 
                      className="badge badge-secondary p-2 mr-2 mb-2 badge-pill" 
                      onClick={() => setManagerDe(managerDe.filter(eq => e.value !== eq.value))}
                    >{e.label}</a>
                  ))}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="autopw">Contraseña aleatoria</label>
                <div className="pagination" name="autopw" id="autopw">
                  <div className={`page-item act ${autopw && "active"}`}>
                    <div className="page-link" onClick={() => {
                      setAutopw(true)
                      setPassword(pwAleatoria())
                    }}>Sí</div>
                  </div>
                  <div className={`page-item desact ${!autopw && "active"}`}>
                    <div className="page-link" onClick={() => {
                      setAutopw(false)
                      setPassword("")
                    }}>No</div>
                  </div>
                </div>
              </div>
              { !autopw && (
                <div className="form-group">
                  <label htmlFor="pw">Contraseña</label>
                  <input 
                    name="password" 
                    className={`form-control ${errors.includes("password") && "is-invalid"}`} 
                    type="password" 
                    value={password} 
                    onChange={evt => setPassword(evt.target.value)}
                  />
                  <div className="invalid-feedback">{ pwErr }</div>
                </div> 
              )}
              <div className="form-group">
                <label htmlFor="bienvenida">Correo de bienvenida</label>
                <div className="pagination" name="bienvenida" id="bienvenida">
                  <div className={`page-item act ${config.enviarNuevoUsuario && "active"}`}>
                    <div className="page-link" onClick={() => setConfig({...config, enviarNuevoUsuario: true})}>Sí</div>
                  </div>
                  <div className={`page-item desact ${!config.enviarNuevoUsuario && "active"}`}>
                    <div className="page-link" onClick={() => setConfig({...config, enviarNuevoUsuario: false})}>No</div>
                  </div>
                </div>
                { config.enviarNuevoUsuario && (
                  <div className="pt-3">
                    <div className="form-group">
                      <label htmlFor="asunto">Asunto</label>
                      <input 
                        name="asunto" 
                        className={`form-control ${errors.includes("asunto") && "is-invalid"}`} 
                        type="text" 
                        value={config.asuntoNuevoUsuario || ""} 
                        onChange={evt => setConfig({...config, asuntoNuevoUsuario: evt.target.value})} 
                      />
                      <div className="invalid-feedback">El asunto no puede estar vacío</div>
                    </div>                 
                    <div className="form-group">
                      <label htmlFor="cuerpo">Cuerpo</label>
                      <textarea 
                        style={{ height: 100 }} 
                        name="cuerpo" 
                        className={`form-control ${errors.includes("cuerpo") && "is-invalid"}`} 
                        value={config.plantillaNuevoUsuario || ""} 
                        onChange={evt => setConfig({...config, plantillaNuevoUsuario: evt.target.value})} 
                      />
                      <div className="invalid-feedback">El cuerpo no puede estar vacío</div>
                    </div> 
                  </div>
                )}
              </div>
              <div className="btn btn-primary" onClick={() => crearUsuario()}>Crear nuevo usuario</div>
            </div>
          )}
        </div>
      </div>        

      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4 finalizaActivas">
          <div className="d-flex w-100 flex-column">
            <h3 className="mb-4">Lista de usuarios</h3>
            <ReactTable
              data={users || []}
              columns={[
                {
                  Header: "Nombre",
                  id: "nombre",
                  accessor: d => d.nombre + " " + d.apellidos,
                  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nombre"] }),
                  filterAll: true
                },
                {
                  Header: "Email",
                  id: "email",
                  accessor: "email",
                  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                  filterAll: true
                },
                {
                  Header: "Equipo",
                  id: "equipo",
                  accessor: d => d.trabajadorDe ? d.trabajadorDe.nombre : "No asignado",
                  filterMethod: (filter, row) => {
                    if (filter.value !== "-") {
                      return typeof filter.value === "string" && filter.value.split("-")[0].trim() === row[filter.id]
                    }
                    else{
                      return true
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      { selEquipos && selEquipos.map((e, i) => <option key={i} value={e.label}>{e.label}</option> )}
                    </select>
                  )
                },
                {
                  Header: "Rol",
                  accessor: "role",
                  Cell: ({ value }) => value && value.name,
                  filterMethod: (filter, row) => {
                    if (filter.value === "agentes") {
                      return row[filter.id].name === "Agente";
                    }
                    if (filter.value === "manager") {
                      return row[filter.id].name === "Manager";
                    }                    
                    if (filter.value === "admin") {
                      return row[filter.id].name === "Administrador";
                    }
                    else{
                      return true;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="todos">Todos</option>
                      <option value="agentes">{config.literales && config.literales.agente.plur}</option>
                      <option value="manager">{_.capitalize(config.literales && config.literales.manager.plur)}</option>
                      <option value="admin">Administradores</option>
                    </select>
                  )
                },
                {
                  Header: "",
                  id: 'buttons',
                  filterable: false,
                  Cell: row => {
                    return (
                      <TableIconWrapper>
                        <Tag color="volcano" onClick={() => setConfirmBorraUsuario({ open: true, row })}><Icon type="delete" /></Tag>
                        <Tag onClick={() => setModalEdit({ open: true, row })}><Icon type="edit" /></Tag>
                        <Link to={{
                          pathname: `/agente/${row.original.slug}`
                        }}>
                          <Tag color="purple"><Icon type="eye-o" /></Tag>
                        </Link>
                      </TableIconWrapper>
                    );
                  }
                }
              ]}
              filterable
              defaultPageSize={10}
              previousText='Anterior'
              nextText='Siguiente'
              loadingText='Cargando...'
              noDataText={loading ? "Cargando..." : "Sin datos"}
              pageText='Página'
              ofText='de'
              rowsText='filas'
              className="-striped -highlight w-100"
            />
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-center mt-4">
        <Dropzone onDrop={file => subidaMasivaUser(file)}>
          {({getRootProps, getInputProps}) => (
            <div className="btn btn-primary" {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="m-2">Creación masiva de usuarios</p>
            </div>
          )}
        </Dropzone>
        <a href={process.env.REACT_APP_API_URL + "/muestraSubidaUsuarios.ods"} className="ml-4 btn btn-secondary d-flex align-items-center px-3">Descargar archivo de muestra</a>
      </div>
      
      <Modal 
        isOpen={modalEdit.open}                    
        onRequestClose={() => setModalEdit({ open: false, row: {}}) }
        contentLabel="Modal Edición"
        overlayClassName="Overlay"
        className="ModalAlert"
      >
      <div style={{  
          position: "relative",
          borderRadius: 4,
          backgroundColor: "white",
          zIndex: 5,
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowY: "scroll",
          width: "90vw",
          height: "90vh"
        }}>
          <span style={{ fontSize: 30, position: "absolute", top: 8, right: 30, cursor: "pointer" }} onClick={() => { setModalEdit(false) }}>&times;</span>
          <h2 className="mb-2">Editar usuario</h2>

          <div className="form-group">
            <label htmlFor="nombreEdit">Nombre</label>
            <input 
              name="nombreEdit" 
              className={`form-control ${errors.includes("nombreEdit") && "is-invalid"}`} 
              type="text" 
              value={nombreEdit} 
              onChange={evt => setNombreEdit(evt.target.value)} 
            />
            <div className="invalid-feedback">El campo 'Nombre' no puede estar vacío</div>
          </div>                    
          <div className="form-group">
            <label htmlFor="apellidosEdit">Apellidos</label>
            <input 
              name="apellidosEdit" 
              className={`form-control ${errors.includes("apellidosEdit") && "is-invalid"}`} 
              type="text" 
              value={apellidosEdit} 
              onChange={evt => setApellidosEdit(evt.target.value)}
            />
            <div className="invalid-feedback">El campo 'Apellidos' no puede estar vacío</div>
          </div>                    
          <div className="form-group">
            <label htmlFor="emailEdit">Email</label>
            <input 
              name="emailEdit" 
              className={`form-control ${errors.includes("emailEdit") && "is-invalid"}`} 
              type="text" 
              value={emailEdit} 
              onChange={evt => setEmailEdit(evt.target.value)}
            />
            <div className="invalid-feedback">{ emailEditErr }</div>
          </div> 
          <div className="form-group">
            <label htmlFor="equipoEdit">Equipo</label>
            <Select className={`form-control p-0 ${errors.includes("equipoEdit") && "is-invalid"}`}
              value={equipoEdit}
              onChange={equipoEdit => setEquipoEdit(equipoEdit) }
              options={selEquipos}
              placeholder={"Equipo"}
            />
            <div className="invalid-feedback">Los {config.literales && config.literales.agente.plur} han de estar asignados a un equipo</div>
          </div>  
          <div className="form-group">
            <label htmlFor="rolEdit">Rol de usuario</label>
            <Select className={`form-control p-0 ${errors.includes("rolEdit") && "is-invalid"}`}
              value={rolEdit}
              onChange={rolEdit => setRolEdit(rolEdit) }
              options={selRoles}
              placeholder={"Rol"}
            />
            <div className="invalid-feedback">Es necesario asignar un rol al usuario</div>
          </div>
          { rolEdit?.label === "Manager" && (
            <div className="mb-4">
              <div className="form-group">
                <label htmlFor="rol">Manager de</label>
                <Select className={`form-control p-0 ${errors.includes("managerDeEdit") && "is-invalid"}`}
                  value={managerDeEdit.slice[-1]}
                  onChange={equipo => {
                    const e = { _id: equipo.value, nombre: equipo.label }
                    if( !(managerDeEdit.map(a => a._id)).includes(e._id) && e._id ){
                      setManagerDeEdit([...managerDeEdit, e])
                    }
                  }}
                  options={selEquipos}
                  placeholder={"Equipo"}
                />
                <div className="invalid-feedback">Un usuario con rol CETManager tiene que ser manager de al menos un equipo</div>
              </div>  
              { managerDeEdit.map((ed, i) => {
                const e = { value: ed._id, label: ed.nombre }
                return (
                  <a 
                    href="#" 
                    key={`m${i}`} 
                    className="badge badge-secondary p-2 mr-2 mb-2 badge-pill" 
                    onClick={() => setManagerDeEdit(managerDeEdit.filter(eq => e.value !== eq._id ))}
                  >
                    {e.label}&nbsp;&nbsp;&times;
                  </a>
                )
              })}
            </div>
          )}
          <div className="form-group">
            <label htmlFor="bienvenida">Estatus</label>
            <div className="pagination" name="statusEdit" id="statusEdit">
              <div className={`page-item act ${statusEdit && "active"}`}>
                <div className="page-link" onClick={() => setStatusEdit(true)}>Activo</div>
              </div>
              <div className={`page-item desact ${!statusEdit && "active"}`}>
                <div className="page-link" onClick={() => setStatusEdit(false)}>Inactivo</div>
              </div>
            </div>
          </div>            
          <div className="form-group">
            <label htmlFor="pwEdit">Mantener contraseña</label>
            <div className="pagination" name="pwEdit" id="pwEdit">
              <div className={`page-item act ${!pwEdit && "active"}`}>
                <div className="page-link" onClick={() => setPwEdit(false)}>Sí</div>
              </div>
              <div className={`page-item desact ${pwEdit && "active"}`}>
                <div className="page-link" onClick={() => setPwEdit(true)}>No</div>
              </div>
            </div>
          </div>
          { pwEdit && (
            <div className="form-group">
              <label htmlFor="autopwEdit">Contraseña aleatoria</label>
              <div className="pagination" name="autopwEdit" id="autopwEdit">
                <div className={`page-item act ${autopwEdit && "active"}`}>
                  <div className="page-link" onClick={() => {
                    setAutopwEdit(true) 
                    setPasswordEdit(pwAleatoria())
                  }}>Sí</div>
                </div>
                <div className={`page-item desact ${!autopwEdit && "active"}`}>
                  <div className="page-link" onClick={() => {
                    setAutopwEdit(false) 
                    setPasswordEdit("")
                  }}>No</div>
                </div>
              </div>
              { !autopwEdit && (
                <div className="form-group mt-3">
                  <label htmlFor="pw">Contraseña</label>
                  <input 
                    name="password" 
                    className={`form-control ${errors.includes("passwordEdit") && "is-invalid"}`} 
                    type="password" 
                    value={passwordEdit} 
                    onChange={evt => setPasswordEdit(evt.target.value)} 
                  />
                  <div className="invalid-feedback">{ pwEditErr }</div>
                </div> 
              )}
              <div className="form-group mt-3">
                <label htmlFor="correoPw">Correo de aviso de cambio de contraseña</label>
                <div className="pagination" name="correoPw" id="correoPw">
                  <div className={`page-item act ${config.enviarCambioPw && "active"}`}>
                    <div className="page-link" onClick={() => setConfig({ ...config, enviarCambioPw: true })}>Enviar</div>
                  </div>
                  <div className={`page-item desact ${!config.enviarCambioPw && "active"}`}>
                    <div className="page-link" onClick={() => setConfig({ ...config, enviarCambioPw: false })}>No enviar</div>
                  </div>
                </div>
                { config.enviarCambioPw && (
                  <div>
                    <div className="form-group mt-3">
                      <label htmlFor="asuntoEdit">Asunto</label>
                      <input 
                        name="asuntoEdit" 
                        className={`form-control ${errors.includes("asuntoEdit") && "is-invalid"}`} 
                        type="text" 
                        value={config.asuntoCambioPw || ""} 
                        onChange={evt => setConfig({...config, asuntoCambioPw: evt.target.value})} 
                      />
                      <div className="invalid-feedback">El asunto no puede estar vacío</div>
                    </div>                 
                    <div className="form-group">
                      <label htmlFor="cuerpoEdit">Cuerpo</label>
                      <textarea 
                        style={{ height: 100 }} 
                        name="cuerpoEdit" 
                        className={`form-control ${errors.includes("cuerpoEdit") && "is-invalid"}`} 
                        value={config.plantillaCambioPw || ""} 
                        onChange={evt => setConfig({...config, plantillaCambioPw: evt.target.value})} 
                      />
                      <div className="invalid-feedback">El cuerpo no puede estar vacío</div>
                    </div> 
                  </div>
                )}
              </div>
            </div>
          )}
                            
          <div className="d-flex justify-content-end pb-3">
            <CIXButton onClick={() => { editarUsuario(undefined, modalEdit.row) }}>Aceptar</CIXButton>
            <div className="ml-4 btn btn-secondary" onClick={() => { setModalEdit({ open: false, row: {}}) }}>Cancelar</div>
          </div>
          
        </div>
      </Modal>
        
      <Alert 
        open={alertSinCorreo}
        confirm={true}
        title="Correo de bienvenida desactivado"
        text="No se va a enviar la contraseña al nuevo usuario ¿Desea continuar?"
        modalCb={bool => setAlertSinCorreo(bool)}
        confirmFn={crearUsuario}
      />         
      <Alert 
        open={alertEditSinCorreo}
        confirm={true}
        title="Correo de notificación desactivado"
        text="No se va a enviar la contraseña modificada al usuario ¿Desea continuar?"
        modalCb={bool => setAlertEditSinCorreo(bool)}
        confirmFn={editarUsuario}
        confirmArgs={modalEdit.row}
      /> 
      <Alert 
        open={alertNuevoUsuarioOk}
        text={`La creación de nuevo usuario se ha completado correctamente`}
        modalCb={bool => setAlertNuevoUsuarioOk(bool)}
      />            
      <Alert 
        open={alertNuevoUsuarioMal}
        text={`Se ha producido un error durante la creación de nuevo usuario`}
        modalCb={bool => setAlertNuevoUsuarioMal(bool)}
      />           
      <Alert 
        open={alertEditUsuarioOk}
        text={`El usuario se ha editado correctamente`}
        modalCb={bool => setAlertEditUsuarioOk(bool)}
      />            
      <Alert 
        open={alertEditUsuarioMal}
        text={`Se ha producido un error durante la edición de nuevo usuario`}
        modalCb={bool => setAlertEditUsuarioMal(bool)}
      />            
      <Alert 
        open={alertCorreoFallo}
        text={`Se ha producido un error durante el envío del correo electrónico`}
        modalCb={bool => setAlertCorreoFallo(bool)}
      />              
      <Alert 
        open={alertCorreoRepe}
        text={`Esa dirección de correo ya figura en la base de datos`}
        modalCb={bool => setAlertCorreoRepe(bool)}
      />  
      <Alert 
        open={confirmBorraUsuario.open}
        confirm={true}
        title="Borrar usuario"
        text={`Se va a borrar el usuario ${confirmBorraUsuario.row.original && confirmBorraUsuario.row.original.username}. ¿Desea continuar?`}
        modalCb={bool => setConfirmBorraUsuario({ open: bool, row: {}})}
        confirmFn={borrarUsuario}
        confirmArgs={confirmBorraUsuario.row}
      /> 
      <Alert 
        open={alertUsuarioBorrado}
        text={`El usuario se ha borrado correctamente`}
        modalCb={bool => setAlertUsuarioBorrado(bool)}
      />              
      <Alert 
        open={alertFalloUsuarioBorrado}
        text={`Se produjo un fallo durante el proceso de borrado del usuario`}
        modalCb={bool => setAlertFalloUsuarioBorrado(bool)}
      />  
    </div>
  );
}

export default BloqueUsuarios;