import React, { useState, useEffect } from 'react';
import { Icon, message } from 'antd'
import Dropzone from 'react-dropzone'

const BloqueDocumentos = ({ data, refetchConfig }) => {

  const [config, setConfig] = useState({})

  useEffect(() => {
    if(data && Array.isArray(data.configs) && data.configs[0]){
      setConfig(data.configs[0])
    }
  }, [data])

  const completarMisiones = async (file, which) => {
    const body = new FormData();
    body.append("refId", config._id);
    body.append("ref", "config");
    body.append("field", which);
    if (file[0] instanceof File) {
      body.append('files', file[0]);
    }

    fetch(process.env.REACT_APP_API_URL + "/upload", { 
      method: 'POST', 
      headers: {
        "Authorization": "Bearer " + window.localStorage.getItem("token")
      },
      body 
    }).then(resp => {
        refetchConfig()
        if(resp.status === 200){
          message.success("El documento se subió correctamente", 5)
        }
        else if(resp.status >= 400){
          message.error("Se ha producido un error durante la subida del documento (Err. " + resp.status + ")", 5)
        }
      }).catch(err => {
        console.log(err)
        message.error("Se ha producido un error durante la subida del documento", 5)
      })
  }


  return (
    <div className="w-100">
      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4">
          <h3>Ayuda para administradores</h3>
          <div className="form-control mt-2 mb-4 p-4" style={{ height: "initial" }}>
            <div className="mb-4 d-flex align-items-center">
              <label className="m-0">Documento actual</label>
              { config.ayudaAdmin ? (
                <a href={ config.ayudaAdmin.url.includes("cix-lyntia.s3") ? config.ayudaAdmin.url : process.env.REACT_APP_API_URL + config.ayudaAdmin.url } rel="noopener noreferrer" target="_blank"><div className="btn btn-secondary ml-4">{ config.ayudaAdmin.name }</div></a>
              ) : <div className="btn btn-secondary disabled ml-4">No hay ningún documento disponible</div>}
            </div>
            <div className="d-flex align-items-center">
              <label className="m-0" htmlFor="admin">Subir nuevo documento</label>
              <Dropzone onDrop={file => completarMisiones(file, "ayudaAdmin")} style={{ border: "none", height: "40px" }}>
                {({getRootProps, getInputProps}) => (
                    <div className="ml-4" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="btn btn-primary p-4" style={{
                        width: "100%",
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}><Icon type="form" className="mr-4"/>Selecciona un archivo o arrástralo aquí</div>    
                    </div>
                )}
              </Dropzone> 
            </div>
          </div>            
          <h3>Ayuda para manager</h3>
          <div className="form-control mt-2 mb-4 p-4" style={{ height: "initial" }}>
            <div className="mb-4 d-flex align-items-center">
              <label className="m-0">Documento actual</label>
              { config.ayudaManager ? (
                <a href={ config.ayudaManager.url.includes("cix-lyntia.s3") ? config.ayudaManager.url : process.env.REACT_APP_API_URL + config.ayudaManager.url } rel="noopener noreferrer" target="_blank"><div className="btn btn-secondary ml-4">{ config.ayudaManager.name }</div></a>
              ) : <div className="btn btn-secondary disabled ml-4">No hay ningún documento disponible</div>}
            </div>
            <div className="d-flex align-items-center">
              <label className="m-0" htmlFor="admin">Subir nuevo documento</label>
              <Dropzone onDrop={file => completarMisiones(file, "ayudaManager")}>
                {({getRootProps, getInputProps}) => (
                  <div className="ml-4" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="btn btn-primary p-4" style={{
                      width: "100%",
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}><Icon type="form" className="mr-4"/>Selecciona un archivo o arrástralo aquí</div>    
                  </div>
                )}
              </Dropzone> 
            </div>
          </div>            
          <h3>Ayuda para {config.literales && config.literales.agente.plur}</h3>
          <div className="form-control mt-2 mb-4 p-4" style={{ height: "initial" }}>
            <div className="mb-4 d-flex align-items-center">
              <label className="m-0">Documento actual</label>
              { config.ayudaUsuario ? (
                <a href={ config.ayudaUsuario.url.includes("cix-lyntia.s3") ? config.ayudaUsuario.url : process.env.REACT_APP_API_URL + config.ayudaUsuario.url } rel="noopener noreferrer" target="_blank"><div className="btn btn-secondary ml-4">{ config.ayudaUsuario.name }</div></a>
              ) : <div className="btn btn-secondary disabled ml-4">No hay ningún documento disponible</div>}
            </div>
            <div className="d-flex align-items-center">
              <label className="m-0" htmlFor="admin">Subir nuevo documento</label>
              <Dropzone onDrop={file => completarMisiones(file, "ayudaUsuario")}>
                {({getRootProps, getInputProps}) => (
                  <div className="ml-4" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="btn btn-primary p-4" style={{
                      width: "100%",
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}><Icon type="form" className="mr-4"/>Selecciona un archivo o arrástralo aquí</div>    
                  </div>
                )}
              </Dropzone> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BloqueDocumentos;