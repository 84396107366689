import gql from 'graphql-tag'

const ADD_CATEGORIAS = gql`
    mutation CreateCategoria(
        $nombre: String!
    ){
        createCategoria(input: {
            data: {
                nombre: $nombre,
            }
        }){
            categoria {
                _id
                nombre
            }
        }
    }
`

export default ADD_CATEGORIAS