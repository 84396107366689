import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Skeleton } from 'antd';
import { get } from 'lodash'

const RankingMision = ({ instanciasPorMision, instanciasPorEquipo, instanciasPorTemp, rankingLocoDeUsuarios, id, userId, loading, config, misionInstancia }) => {

    // const rowRef = useRef(null)
    const [filtro, setFiltro] = useState("temporada")
    const [instanciasGlob, setInstanciasGlob] = useState([]) 
    const [instanciasFiltro, setInstanciasFiltro] = useState([]) 

    useEffect(() => {
        if(instanciasPorMision && filtro === "mision"){ 
            let instMod = instanciasPorMision.filter(m => m.user && (m.estado === "completada"))
            setInstanciasFiltro(procesaInst(instMod))
        }
        else if(instanciasPorEquipo && filtro === "equipo"){
            let instMod = instanciasPorEquipo.filter(m => m.user && (m.estado === "completada") && (get(m, "mision.nombre") === get(misionInstancia, "mision.nombre")))
            setInstanciasFiltro(procesaInst(instMod))
        }
        else if(instanciasPorTemp && filtro === "temporada"){
            let instMod = instanciasPorTemp.filter(m => m.user && (m.estado === "completada"))
            setInstanciasFiltro(procesaInst(instMod))
        }
        else if(rankingLocoDeUsuarios && filtro === "usuarios"){
            let userMod = procesaLoco(rankingLocoDeUsuarios)
            setInstanciasFiltro(userMod)
        }
    }, [filtro, instanciasPorMision, instanciasPorEquipo, instanciasPorTemp])

    const procesaInst = instancias => {
        instancias.forEach((m, i) => {
            const mediaIndicadores = m.valores && Object.entries(m.valores).reduce((a, b) => {
                return a+(parseFloat(b[1])*config.indicadores[b[0]].peso*0.01)
            }, 1)
            const mediaRetos = Array.isArray(m.retoinstancias) && m.retoinstancias.reduce((a, b) => a + (parseInt(b.valor)*b.reto.peso*0.01), 0)
            const mediaRank = mediaIndicadores*(100-config.pesoRetosIndicadores)*0.01 + mediaRetos*config.pesoRetosIndicadores*0.01 + 50;
            const sumaIndicadores = m.valores && Object.entries(m.valores).reduce((a, b) => a+parseInt(b[1]), 0)
            const sumaRetos = Array.isArray(m.retoinstancias) && m.retoinstancias.reduce((a, b) => a + parseInt(b.valor), 0)
            const sumaRank = sumaIndicadores + sumaRetos + 50;
            const r = config.mediaTrueSumaFalse ? mediaRank : sumaRank

            instancias[i].ranking = Math.round(r)
            if(m._id === id){ instancias[i].marcar = true }
            else{ instancias[i].marcar = false}
        })
        instancias.sort((a, b) => {
            if(a.ranking > b.ranking){ return -1 }
            else if(a.ranking < b.ranking){ return 1 }
            else{ return 0 }
        })
        instancias.forEach((m, i) => {
            if(i === 0){ instancias[i].posicion = 1 }
            else if(i > 0 && instancias[i].ranking !== instancias[i-1].ranking){ instancias[i].posicion = instancias[i-1].posicion + 1 }
            else{ instancias[i].posicion = instancias[i-1].posicion }
        })
        return instancias
    }

    const procesaLoco = usuarios => {
        let userMod = [...usuarios].sort((a, b) => {
            if(a.ranking < b.ranking){ return 1 }
            else{ return -1 }
        })
        userMod.forEach((u, i) => {
            if(u.id === misionInstancia.user._id){ userMod[i].marcar = true }

            if(i === 0){ userMod[i].posicion = 1 }
            else if(i > 0 && userMod[i].ranking !== userMod[i-1].ranking){ userMod[i].posicion = userMod[i-1].posicion + 1 }
            else{ userMod[i].posicion = userMod[i-1].posicion }
        })
        return userMod
    }

    return (
        <div className="d-flex flex-column w-100 align-items-center">
            <div className="row w-100 mb-4 justify-content-center">
                <div className="bottonera w-75">
                    <div onClick={() => setFiltro("temporada")} className={filtro === "temporada" ? "boton activo" : "boton"}>Por {config.literales && config.literales.temporada.sing}</div>
                    {/* <div onClick={() => setFiltro("mision")} className={filtro === "mision" ? "boton activo" : "boton"}>Por {config.literales && config.literales.mision.sing}</div>                         */}
                    <div onClick={() => setFiltro("usuarios")} className={filtro === "usuarios" ? "boton activo" : "boton"}>Por puntuación global de usuario</div>                        
                    <div onClick={() => setFiltro("equipo")} className={filtro === "equipo" ? "boton activo" : "boton"}>Por {config.literales && config.literales.equipo.sing}</div>
                </div>
            </div>
            <div className="row m-1 jumbo sombra alto320 bodytable w-75" style={{ overflow: "hidden" }}>
                <div className="innercontainer">
                    {
                        (!loading && Array.isArray(instanciasFiltro)) ? instanciasFiltro.map((e, i) => {
                            return(
                                <Link 
                                    onClick={() => window.scroll(0, 0)}
                                    to={filtro !== "usuarios" ? `/detallemision/${e._id}` : `/agente/${get(e, "user.slug")}`} /*ref={id === e._id && rowRef}*/ 
                                    key={i} 
                                    className="megarow" 
                                    data-id={id} 
                                    data-eid={e._id} 
                                    style={{ backgroundColor: e.marcar && "#4cab56"}}
                                >
                                    <h2 className="px-4 m-0">{(e.estado === "completada" || filtro === "usuarios") ? e.posicion : "-"}</h2>
                                    <div className="comp text-center" style={{flex:1}}>
                                        <h3>{ loading ? <Skeleton width={"135px"}/> : (e.user && (e.user.nombre + " " + e.user.apellidos)) }</h3>
                                    </div>
                                    {
                                        filtro !== "mision" && (
                                            <div className="comp text-center" style={{flex:1}}>
                                                <h3>{ loading ? <Skeleton width={"135px"}/> : (e.mision && e.mision.nombre) }</h3>
                                            </div>
                                        )
                                    }
                                    {
                                        filtro !== "temporada" && (
                                            <div className="comp text-center" style={{flex:1}}>
                                                <h3>{ loading ? <Skeleton width={"135px"}/> : (e.temporada && e.temporada.nombre) }</h3>
                                            </div>
                                        )
                                    }
                                    {
                                        filtro !== "equipo" && (
                                            <div className="comp text-center" style={{flex:1}}>
                                                <h3>{ loading ? <Skeleton width={"135px"}/> : (e.equipo && e.equipo.nombre) }</h3>
                                            </div>
                                        )
                                    }
                                    { loading ? <Skeleton width={"135px"}/> : (
                                        <div className="comp px-4 mr-4" style={{flex:0}}>
                                            <h3><strong>{ !loading ? ( e.ranking === 0 ? "-" : e.ranking ) : "-" }</strong></h3>
                                        </div>
                                    )}
                                </Link>
                            )
                        }) : [0,1,2,3,4].map(foo => (                                    
                            <div key={foo} className="megarow">
                                <h2 className="px-4"><Skeleton width={"75px"}/></h2>
                                <div className="comp text-center" style={{ flex:1 }}>
                                    <h3><Skeleton width={"400px"}/></h3>
                                </div>
                                <div className="comp px-4 mr-4" style={{ flex:0 }}>
                                    <h3><Skeleton width={"75px"}/></h3>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default RankingMision