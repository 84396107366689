import gql from 'graphql-tag'

const GET_CATEGORIAS = gql`
    {
        categorias{
            _id
            nombre
            misions{
                _id
            }
        }
    }
`

export default GET_CATEGORIAS