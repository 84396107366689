import gql from 'graphql-tag'

const EDIT_TEMPORADA = gql`
    mutation UpdateTemporada(
        $id: ID!
        $nombre: String
        $finalizada: Boolean
        $misioninstancias: [ID]
        $lecciones: String
        $proximos: String
    ){
        updateTemporada(input: {
            where: {
                id: $id
            },
            data: {
                nombre: $nombre
                finalizada: $finalizada
                misioninstancias: $misioninstancias
                lecciones: $lecciones
                proximos: $proximos
            }
        }){
            temporada{
                _id
                nombre
                finalizada
                misioninstancias{
                    _id
                }
                lecciones
                proximos
            }
        }
    }
`

export default EDIT_TEMPORADA