import gql from 'graphql-tag'

const DELETE_USER = gql`
    mutation DeleteEquipo(
        $id: ID!
    ){
        deleteEquipo(input: {
            where: {
                id: $id,
            }
        }){
            equipo {
                _id
                nombre
            }
        }
    }
`

export default DELETE_USER