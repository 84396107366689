import React, { useState, useEffect, useContext } from 'react'
import {
    BloqueSubida,
    BloqueNotificaciones,
    BloqueUsuarios,
    BloqueEquipos,
    BloqueMisiones,
    BloqueAnalitica,
    BloqueDocumentos
} from '../components/BloquesAdmin'
import {
    GET_CONFIG,
    GET_EQUIPOS,
    GET_USERS,
    GET_ROLES,
    GET_MISIONINSTANCIAS
} from '../queries/queryList'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { LoadingContext } from '../components/LoadingContext'

const AdminPage = ({ history }) => {
    
    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const { loading: loadingEquipos, error: errorEquipos, data: dataEquipos, refetch: refetchEquipos } = useQuery(GET_EQUIPOS)
    const { loading: loadingUsers, error: errorUsers, data: dataUsers, refetch: refetchUsers } = useQuery(GET_USERS)
    const { loading: loadingRoles, error: errorRoles, data: dataRoles, refetch: refetchRoles } = useQuery(GET_ROLES)
    const { loading: loadingMI, error: errorMI, data: dataMI, refetch: refetchMI } = useQuery(GET_MISIONINSTANCIAS, { variables: { where: { estado: "completada" }}})
    const [tab, setTab] = useState("usuarios")
    const [data, setData] = useState()
    const [config, setConfig] = useState({})

    const { loading, setLoading } = useContext(LoadingContext)
    useEffect(() => {
        if(window.localStorage.getItem("lastTab") && window.localStorage.getItem("lastTab") !== "null"){
            setTab(window.localStorage.getItem("lastTab"))
        }
    }, [])

    useEffect(() => {
        if(dataConfig && dataEquipos && dataUsers && dataRoles && dataMI){
            setData({
                configs: dataConfig.configs,
                equipos: dataEquipos.equipos,
                users: dataUsers.users,
                roles: dataRoles.roles,
                MI: dataMI.misioninstancias
            })
            setConfig(dataConfig.configs[0])
            setLoading(false)
        }
    }, [dataConfig, dataEquipos, dataUsers, dataRoles, dataMI])
    
    useEffect(() => {
        window.localStorage.setItem("lastTab", tab)
    }, [tab])

    // useEffect(() => {
    //     console.log(data)
    // }, [data])

    return (
        <div className="container">
            <div className="row">
                <div className="col d-flex flex-column align-items-center mt-4">
                    <h2 className="pb-4">Panel de administración de {config.nombrePlat}</h2>
                    <div className="pagination">
                        <div className={`page-item ${tab === "usuarios" && "active"}`}>
                            <div className="page-link" onClick={() => setTab("usuarios")}>Usuarios</div>
                        </div>                            
                        <div className={`page-item ${tab === "equipos" && "active"}`}>
                            <div className="page-link" onClick={() => setTab("equipos")}>Equipos</div>
                        </div>
                        <div className={`page-item ${tab === "misiones" && "active"}`}>
                        <div className="page-link" onClick={() => setTab("misiones")}>{_.capitalize(config.literales && config.literales.mision.plur)}</div>
                        </div>                    
                        <div className={`page-item ${tab === "notificaciones" && "active"}`}>
                            <div className="page-link" onClick={() => setTab("notificaciones")}>Notificaciones</div>
                        </div>                    
                        <div className={`page-item ${tab === "subida" && "active"}`}>
                            <div className="page-link" onClick={() => setTab("subida")}>Subida de datos</div>
                        </div>                    
                        <div className={`page-item ${tab === "analitica" && "active"}`}>
                            <div className="page-link" onClick={() => setTab("analitica")}>Analítica</div>
                        </div>                            
                        <div className={`page-item ${tab === "documentos" && "active"}`}>
                            <div className="page-link" onClick={() => setTab("documentos")}>Documentos</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="my-4 d-flex justify-content-center">
                {(() => {
                    switch(tab) {
                        case "usuarios":
                            return <BloqueUsuarios data={data} />                             
                        case "equipos":
                            return <BloqueEquipos data={data} /> 
                        case "misiones":
                            return <BloqueMisiones data={data} refetchMI={refetchMI} /> 
                        case "notificaciones":
                            return <BloqueNotificaciones data={data} />;                        
                        case "subida":
                            return <BloqueSubida data={data} history={history} />;                        
                        case "analitica":
                            return <BloqueAnalitica data={data} />                             
                        case "documentos":
                            return <BloqueDocumentos data={data} refetchConfig={refetchConfig} /> 
                        default:
                            return null;
                    }
                })()}
            </div>
        </div>
    )
    
}

export default AdminPage