import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, allowed, ...rest }) => {
    const notAuthorised = { role: { name: "Not authorised" } }
    var userInfo
    try{ userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : notAuthorised }
    catch(err){ userInfo = notAuthorised }
    const basicPath = rest.baseURL || "/auth/login"
    return (
        <Route
            {...rest}
            render={ props => (allowed.includes(userInfo.role.name) && !userInfo.blocked && userInfo.confirmed) ? <Component {...props} /> : (
                <Redirect
                    to={{
                        pathname: basicPath,
                        state: { from: props.location },
                    }}
                />
            )}
        />
    )
};
            
            
export default PrivateRoute;