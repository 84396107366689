import React from 'react'
import styled from 'styled-components'

const Jumbo = ({ children, className, style }) => {
    return <Wrapper style={style} className={className}>{ children }</Wrapper>
}

const Wrapper = styled.div`
    padding: 1em;
    border-radius: 15px;
    box-shadow: 0 3px 17px 0 rgba(53, 27, 81, 0.2);
`

export default Jumbo