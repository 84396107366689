import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { GET_EQUIPOS, GET_CONFIG, GET_TEMPORADAS } from '../queries/queryList'
import { CIXButton } from '../components/CIXLibrary'
import { mayusculizer } from '../utils/func'
import CabeceraTeams from '../components/CabeceraTeams'
import CuadroResultados from '../components/CuadroResultados'
import Speedometer from '../components/Speedometer'
import Jumbo from '../components/Jumbo'
import RankingEquipos from '../components/RankingEquipos'
import TarjetaEquipo from '../components/TarjetaEquipo'
import TarjetaAgente from '../components/TarjetaAgente'
import ModalMensaje from '../components/ModalMensaje'
import { media, redondear } from '../utils/func'
import { flattenDeep } from 'lodash' 
import { LoadingContext } from '../components/LoadingContext'

const TeamPage = ({ match, history }) => {

    const { loading: loadingEquipos, error: errorEquipos, data: dataEquipos, refetch: refetchEquipos } = useQuery(GET_EQUIPOS)
    const { loading: loadingTemporadas, error: errorTemporadas, data: dataTemporadas, refetch: refetchTemporadas } = useQuery(GET_TEMPORADAS)
    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)

    const [equipo, setEquipo] = useState([])
    const [equipos, setEquipos] = useState([])
    const [hijos, setHijos] = useState([])
    const [hijosSinNiv, setHijosSinNiv] = useState([])
    const [jerar, setJerar] = useState([])
    const [config, setConfig] = useState({ indicadores: {}, estructuraJerarquica: {}, literales: {}})
    const [trabajadores, setTrabajadores] = useState([])
    const [temporadas, setTemporadas] = useState([{ label: "Todas", value: false }])
    const [modalMensaje, setModalMensaje] = useState({ open: false, to: []})
    const [selTemporada, setSelTemporada] = useState([])
    const [indicadores, setIndicadores] = useState({})
    const [currentMatch, setCurrentMatch] = useState(match.params.idEquipo)
    const { setLoading } = useContext(LoadingContext)
    
    useEffect(() => {
        if(dataEquipos && dataConfig && dataTemporadas){
            setIndicadores(dataConfig.configs[0].indicadores)
            setTemporadas([...dataTemporadas.temporadas.map(t => ({ label: t.nombre, value: t._id })), { label: "Todas", value: false }])
            setEquipos(dataEquipos.equipos)
            setConfig(dataConfig.configs[0])
            try{
                const ultimaTemporada = JSON.parse(localStorage.getItem("ultimaTemporada"))
                if(!ultimaTemporada){ throw "No hay último " + dataConfig.configs[0].literales.temporada.sing + " en localStorage" }
                setSelTemporada(ultimaTemporada )
            } catch(err) {
                const todas = { label: "Todas", value: false }
                setSelTemporada(todas)
                localStorage.setItem("ultimaTemporada", JSON.stringify(todas))
            }
        }
    }, [dataEquipos, dataConfig, dataTemporadas])

    useEffect(() => {
        if(equipos.length > 0 && currentMatch !== match.params.idEquipo){
            procesarCosas(match.params.idEquipo)
        }
        setCurrentMatch(match.params.idEquipo)
    }, [match])

    useEffect(() => {
        if(equipos.length > 0 && currentMatch){
            procesarCosas(currentMatch)
        }
    }, [selTemporada])

    const procesarCosas = id => {
        setLoading(true)
        const eSel = equipos.find(e => e.slug === id) || 
                     equipos.find(e => e.idequipo === id)

        const userInfo = JSON.parse(localStorage.getItem("userInfo"))

        const cnd1 = !flattenDeep(eSel.padres.map(p => p.managers.map(m => m._id))).includes(userInfo._id)
        const cnd2 = !flattenDeep(eSel.managers.map(m => m._id)).includes(userInfo._id)
        const cnd3 = userInfo.role.name === "Manager"

        if(cnd1 && cnd2 && cnd3){
            const goTo = userInfo.trabajadorDe ? userInfo.trabajadorDe.slug : userInfo.managerDe[0].slug
            history.push("/equipo/" + goTo)
        }

        const j = Object.keys(config.estructuraJerarquica).map(n => ({ 
            ...config.estructuraJerarquica[n], 
            nivel: parseInt(n) 
        }))
        setJerar(j)
        const hijosDirectos = eSel && Array.isArray(eSel.hijos) && eSel.hijos.filter(e => {
            const padresOrd = e.padres.sort((a, b) => {
                if(a.nivel > b.nivel){ return -1 }
                else if(a.nivel < b.nivel){ return 1 }
                else { return 0 }
            })
            return padresOrd[0] && (padresOrd[0].nivel === eSel.nivel)
        })
        const hijosPorNivel = hijosDirectos ? [...new Set(hijosDirectos.map(e => e.nivel))].map(n => ({ 
            [j.find(jit => jit.nivel === n).nivel]: hijosDirectos.filter(h => h.nivel === n)
        })) : []

        const retos = config.mediasTrueSumasFalse ? "mediaRetos" : "sumaRetos"
        const indicadores = config.mediasTrueSumasFalse ? "mediaIndicadores" : "sumaIndicadores"
        const total = config.mediasTrueSumasFalse ? "mediaTotal" : "sumaTotal"

        if(Array.isArray(eSel.medias)){
            const mFilt = eSel.medias.filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
            const mUsFilt = [].concat.apply([], eSel.trabajadores.map(t => t.medias)).filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
            const mhUsFilt = [].concat.apply([], [].concat.apply([], eSel.hijos.map(h => h.trabajadores)).map(t => t.medias)).filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
            // console.log([...mUsFilt, ...mhUsFilt])
            const maxRanking = Math.max.apply(null, [...mUsFilt, ...mhUsFilt].map(m => redondear(config.mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
            const minRanking = Math.min.apply(null, [...mUsFilt, ...mhUsFilt].map(m => redondear(config.mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
            // console.log({ mFilt, maxRanking, minRanking })
            const mediasInter = {
                consecucion: mFilt.length > 0 ? media(mFilt, "mediaConsecucion", 0) : " - ",
                retos: mFilt.length > 0 ? media(mFilt, retos, 1) : " - ",
                mediaIndicadores: media(mFilt, indicadores, 1),
                ranking: mFilt.length > 0 ? media(mFilt, total, 1) + 50 : " - ",
                maxRanking: maxRanking !== -Infinity ? maxRanking : " - ",
                minRanking: minRanking !==  Infinity ? minRanking : " - ",
                indicadores: Object.keys(indicadores).map(ind => ({
                    [ind]: mFilt.length > 0 ? media(mFilt.map(m => m.indicadores), ind, 1) : " - "
                })).reduce((a, b) => ({ ...a, ...b}), {})
            }
            const equipoMod = {...eSel, mediasInter}
            // console.log(equipoMod)
            setEquipo(equipoMod)

            const trabajadoresMod = equipoMod.trabajadores
                // .filter(t => t.role.name !== "Manager")
                .map(t => {
                    const mFilt = t.medias.filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
                    const mediasInter = {
                        ranking: mFilt.length > 0 ? media(mFilt, total, 1) + 50 : " - ",
                    }
                    return {
                        ...t,
                        mediasInter
                    }
                })
            setTrabajadores(trabajadoresMod)
        }
        if(Array.isArray(hijosPorNivel)){
            const hijosMod = hijosPorNivel.map(j => {
                return {
                    [Object.keys(j)[0]]: (
                        Object.values(j)[0].map(eq => {
                            // console.log({ eq })
                            const mhFilt = eq.medias.filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
                            const mhUsFilt = [].concat.apply([], eq.trabajadores.map(t => t.medias)).filter(m => (m.temporada._id === selTemporada.value) || !selTemporada.value)
                            // console.log({ mhUsFilt })
                            const maxRanking = Math.max.apply(null, mhUsFilt.map(m => redondear(config.mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
                            const minRanking = Math.min.apply(null, mhUsFilt.map(m => redondear(config.mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
                            const mediasInter = {
                                ranking: mhFilt.length > 0 ? media(mhFilt, total, 1) + 50 : " - ",
                                maxRanking: maxRanking !== -Infinity ? maxRanking : " - ",
                                minRanking: minRanking !==  Infinity ? minRanking : " - "
                            }
                            return {...eq, mediasInter}
                        })
                    )
                }
            })

            setHijos(hijosMod)
            setHijosSinNiv([].concat.apply([], hijosMod.map(h => Object.values(h)[0])))
        }
        setLoading(false)
    }

    return (
        <div className="container">  
            <CabeceraTeams 
                match={match} 
                equipo={equipo} 
                jerar={jerar} 
                config={config} 
                temporadas={temporadas} 
                selTemporada={selTemporada} 
                setSelTemporada={setSelTemporada} 
                user={JSON.parse(localStorage.getItem("userInfo"))}
            />
            <div className="row">
                {
                    trabajadores.length > 0 &&
                        <div className="col d-flex justify-content-end">
                            <Link to={"/gestionmisiones/" + match.params.idEquipo}>
                                <div className="btn btn-primary">Gestionar {config.literales && config.literales.mision.plur}</div>
                            </Link>
                        </div>
                }
            </div>
            <div className="row mt-4 mb-5">
                <div className={`${(hijosSinNiv.length > 0 && trabajadores.length > 0) ? "col-md-8" : "col-md"}`}>
                    <h3 className="mb-3">Valoración</h3>
                    <CuadroResultados datos={equipo.mediasInter} config={config} />
                </div>
                <div className={`${(hijosSinNiv.length > 0 && trabajadores.length > 0) ? "col-md-4" : "col-md-3"}`}>
                    <h3 className="mb-3">Consecución</h3>
                    {
                        <Jumbo className="alto320 d-flex align-items-center justify-content-center p-0">
                            <SingleSpeedo>
                                <Speedometer val={equipo.mediasInter && equipo.mediasInter.consecucion} />
                            </SingleSpeedo>
                        </Jumbo>
                    }
                </div>
                <div className={`${hijosSinNiv.length > 0 ? (trabajadores.length > 0 ? "col-md-6 mt-4" : "col-md") : "d-none"}`}>
                    <h3 className="mb-3">Ranking Equipos</h3>
                    <RankingEquipos equipos={hijosSinNiv}/>
                </div>
                <div className={`${trabajadores.length > 0 ? (hijosSinNiv.length > 0 ? "col-md-6 mt-4" : "col-md") : "d-none"}`}>
                    <h3 className="mb-3">Ranking {config.literales && config.literales.agente && config.literales.agente.plur}</h3>
                    <RankingEquipos equipos={trabajadores} minMax={false} />
                </div>
            </div>

            { hijos.map(h => (
                    <div key={"nivel" + Object.keys(h)[0]} style={{ marginBottom: 20 }}>
                        <h3 style={{ marginBottom: 20 }}>{mayusculizer(jerar.find(jit => jit.nivel === parseInt(Object.keys(h)[0])).plur)}</h3>
                        <div className="row">
                            { Object.values(h)[0].map(e => (
                                <div key={e._id} className="col-md-4">
                                    <TarjetaEquipo equipo={e} openModal={setModalMensaje}/>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            )}

            { trabajadores.length > 0 && (
                <div>
                <h3 className="mb-3">{config.literales && config.literales.agente && config.literales.agente.plur}</h3> 
                    <div className="row">
                        { trabajadores.map((e, i) => (
                            <div key={"tr-" + e.slug} className="col-md-4">
                                <TarjetaAgente agente={e} openModal={setModalMensaje}  />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <ModalMensaje 
                data={modalMensaje}
                setOpenModal={setModalMensaje}
                config={dataConfig && Array.isArray(dataConfig.configs) && dataConfig.configs[0]}
            />
        </div>
    )
}

const SingleSpeedo = styled.div`
    padding-top: 30px;
    &:not(:last-child){
        border-bottom: 1px solid gainsboro;
    }
`
const Title = styled.div`
        width: 100%;
        padding-bottom: 10px;
        margin-top: -20px;
        text-align: center;
        font-weight: bold;
`

export default TeamPage