import gql from 'graphql-tag'

const GET_SINGLE_USER = gql`
    query User($where: JSON) {
        users(where: $where){
            _id
            nombre
            apellidos
            slug
            email
            username
            confirmed
            imagen{
                url
            }
            managerDe{
                _id
                nombre
                slug
            }
            trabajadorDe{
                _id
                nombre
                slug
                padres{
                    _id
                    managers{
                        _id
                    }
                }
            }
            role{
                _id
                name
            }
            medias{
                _id
                mediaRetos
                sumaRetos
                mediaIndicadores
                sumaIndicadores
                mediaTotal
                sumaTotal
                indicadores
                mediaConsecucion
                temporada{
                    _id
                    nombre
                }
            }
            misioninstancias(sort: "fecha:ASC"){
                _id
                valores
                estado
                comentario
                lugar
                fecha
                fechaFinal
                cajas
                mision{
                    _id
                    nombre
                    archivos{
                        _id
                        url
                    }
                }
                temporada{
                    _id
                    nombre
                    createdAt
                }
                retoinstancias{
                    objetivo
                    valor
                    resultado
                    reto{
                        _id
                        nombre
                        peso
                        orden
                        tipomejora
                        inverso
                    }
                }
            }
        }
    }
`

export default GET_SINGLE_USER