import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import { Skeleton, Button }  from 'antd';
import CuadroRanking from '../components/CuadroRanking';
import Speedometer from '../components/Speedometer';
import RankingMision from '../components/RankingMision';
import PrivateComponent from '../containers/PrivateComponent';
import renderHTML from 'react-render-html';
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks';
import { Icon } from 'antd';
import _ from 'lodash'
import { GET_CONFIG, GET_SINGLE_MISION, GET_MEDIAS_GLOBAL } from '../queries/queryList';
import { LoadingContext } from '../components/LoadingContext';
import { redondear } from '../utils/func';
import { CIXButton } from '../components/CIXLibrary';
import { flattenDeep, get } from 'lodash'
import { Tooltip } from 'react-tippy';

const DetalleMisionPage = ({ match, history }) => {

    const [datos, setDatos] = useState({})
    const [mision, setMision] = useState({})
    const [config, setConfig] = useState({})
    const [instancias, setInstancias] = useState([])
    const [userRank, setUserRank] = useState([])
    const { setLoading } = useContext(LoadingContext)

    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const { loading: loadingUserMedias, error: errorUserMedias, data: dataUserMedias, refetch: refetchUserMedias } = useQuery(GET_MEDIAS_GLOBAL)
    const { loading: loadingMision, error: errorMision, data: dataMision, refetch: refetchMision } = useQuery(GET_SINGLE_MISION, { variables: { id: match.params.idMision }})

    useEffect(() => {
        if(dataConfig && dataConfig.configs[0]){
            setConfig(dataConfig.configs[0])
        }
    }, [dataConfig])

    useEffect(() => {
        if(dataMision && config.indicadores){
            let m = dataMision.misioninstancia;
            const userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
            const cnd1 = m.user._id === userInfo._id //Si el usuario de la misión a la que intenta acceder es el suyo
            const cnd2 = userInfo.managerDe.map(e => e._id).includes(m.equipo._id) //Si es el manager del equipo al que pertenece el usuario de la misión a la que intenta acceder
            const cnd3 = flattenDeep(userInfo.managerDe.map(e => e.hijos)).includes(m.equipo._id) //Si es manager de un equipo superior al que pertenece el usuario de la misión
            const cnd4 = userInfo.role.name === "Administrador" //Si es admin
            // console.log({
            //     cnd1, cnd2, cnd3, cnd4
            // })
            if(!cnd1 && !cnd2 && !cnd3 && !cnd4){
                history.push("/agente/" + userInfo.slug)
            }


            // console.log(m.retoinstancias.map(ri => ({ ...ri, peso: ri.reto.peso })))
            const sumaIndicadores = m.valores && (Object.entries(m.valores).reduce((a, b) => a+parseFloat(b[1]), 0))
            const sumaRetos = Array.isArray(m.retoinstancias) && m.retoinstancias.reduce((a, b) => a + parseFloat(b.valor), 0)
            const sumaRank = sumaIndicadores + sumaRetos + 50;
            const mediaIndicadores = m.valores && Object.entries(m.valores).reduce((a, b) => a+(parseFloat(b[1]), 0))
            const mediaRetos = Array.isArray(m.retoinstancias) && m.retoinstancias.reduce((a, b) => a + parseFloat(b.valor), 0)
            const mediaRank = mediaIndicadores*(100-config.pesoRetosIndicadores)*0.01 + mediaRetos*config.pesoRetosIndicadores*0.01 + 50;
            m.ranking = config.mediasTrueSumaFalse ? Math.round(mediaRank) : Math.round(sumaRank)

            setMision(m)
            setLoading(false)
        }
        if(dataUserMedias){
            const tipo = config.mediasTrueSumasFalse ? "mediaTotal" : "sumaTotal"
            const users = get(dataUserMedias, "users", []).filter(u => get(u, "medias", []).length > 0).map(u => {
                return ({
                    id: u._id,
                    user: {
                        nombre: u.nombre,
                        apellidos: u.apellidos,
                        slug: u.slug
                    },
                    ranking: Math.round((u.medias.reduce((acc, it) => acc + it[tipo], 0))/u.medias.length) + 50
                })
            })
            setUserRank(users)
        }
    }, [dataMision, dataUserMedias, config])

    return config.literales ? (
        <div className="container sans pb-4">
            { mision.user &&
                <div className="row mt-3">
                    <div className="col-12">
                        <Link to={"/agente/" + mision.user.slug}>&lt; Ir a {mision.user.nombre + " " + mision.user.apellidos}</Link>
                    </div>
                </div>
            }
            <div className="row mt-3">
                <div className="col">
                    <h2>{ mision.mision && mision.mision.nombre }</h2>
                    <div className="jumbo sombra alto320m p-4">
                        <h4 className="mb-3">Descripción del {config.literales.mision.sing}</h4>
                        <div style={{ fontSize: "1.2em" }}>{ renderHTML(mision.mision ? mision.mision.descripcion : " - ") }</div>
                    </div>
                </div>
                <div className="col">
                    <h2 className="text-left font-weight-bold col-12 mb-3">&nbsp;</h2>
                    <CuadroRanking 
                        datos={{
                            total: mision.ranking,
                            indicadores: mision.valores || {},
                            ri: mision.retoinstancias || []
                        }}
                        config={config}
                        mejorPeor={false}
                    />
                </div>
            </div>
            
            <div className="row mt-5">
                { Array.isArray(mision.retoinstancias) && mision.retoinstancias.length > 0 && (
                    <div className="col-12">
                        <h2>Retos del {config.literales.mision.sing}</h2> 
                    </div>
                )}
                { Array.isArray(mision.retoinstancias) && mision.retoinstancias.map((ri, i) => ( 
                    <div key={"ri-" + i} className="col mb-4" style={{ maxWidth: "50%" }}>
                        <h4 style={{ minHeight: 56, textAlign: "center"}} className={`pl-0 font-weight-bold col-12 mb-3`}> <span>{ ri.reto.nombre }</span> <Tooltip theme="light" title={ ri.reto && ri.reto.tooltip }><Icon type="question-circle" style={{     
                            fontSize: 14,
                            verticalAlign: "middle",
                            color: "grey"
                        }}/></Tooltip></h4>
                        <div className={`jumbo sombra mx-0 d-flex ${mision.retoinstancias.length > 2 ? "flex-column justify-content-center" : "justify-content-around"}`}>
                            <div>
                                <div className={`d-flex flex-column justify-content-center align-items-center pt-3`}>
                                    <h5 className="mb-0 mt-2" style={{fontWeight: "bold"}}>Consecución</h5>
                                    <div style={{ transform: "scale(0.8)" }}>
                                        <Speedometer
                                            mostrar={true}
                                            etiq="% de consecución"
                                            val={redondear((ri.resultado * 100) / ri.objetivo, 0)}
                                            color1={70}
                                            color2={100}
                                            obj={0}
                                            min={0}
                                            max={200}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`mb-2 ${mision.retoinstancias.length > 1 ? "d-flex flex-column" : "d-flex"}`} >
                                <div className="p-1 d-flex flex-column justify-content-center align-items-center text-center" style={{ flex: 1 }}>
                                    <h5 className="pb-0">Objetivo</h5>
                                    <HacheDos>{ ri.objetivo || "-" }{ ri.tipomejora === "porcentaje" ? "%" : ( ri.tipomejora === "dinero" ? "€" : "" )}</HacheDos>
                                </div>
                                <div className="p-1 d-flex flex-column justify-content-center align-items-center text-center" style={{ flex: 1 }}>
                                    <h5 className="pb-0">Resultado</h5>
                                    <HacheDos>{ ri.resultado ? ri.resultado : ( ri.resultado && ri.reto ? parseFloat(ri.resultado + ( ri.resultado * mision.reto * 0.1 ), 10) : "-" )}{ ri.tipomejora === "porcentaje" ? "%" : ( ri.tipomejora === "dinero" ? "€" : "" )}</HacheDos>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            
            <div className="mt-5">
                <div className="row mt-2 mx-0" style={{justifyContent:'center'}}>
                    <div className="col carditinerario" style={{ backgroundImage: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtyUvzDZqH65rjfGnXjW9SH5U3Eum40cHhSAh6q8UlrzYBA2DB)"}}>
                        <div className="overlay" style={{ backgroundColor: mision.cajas ? ( mision.cajas.entrenamiento === true ? "rgba(25, 100, 20, 0.85)" : ( mision.cajas.entrenamiento === false ? "rgba(100, 25, 20, 0.85)" : "rgba(100, 100, 100, 0.85)")) : "rgba(100, 100, 100, 0.85)" }}></div>
                        <h3>Entrenamiento</h3>
                        <p>{_.capitalize(config.literales.mision.sing)}: { mision && mision.mision && mision.mision.nombre }</p>
                        <p>Descripción: {mision && mision.comentario}</p>
                        <p>Fecha: { mision && mision.fecha }</p>
                        <p>Lugar: { mision && mision.lugar }</p>
                        { mision && mision.mision && Array.isArray(mision.mision.archivos) && mision.mision.archivos[0] &&
                            <a rel="noreferrer noopener" target="_blank" href={mision.mision.archivos[0].url.includes("cix-lyntia.s3") ? mision.mision.archivos[0].url :process.env.REACT_APP_API_URL + mision.mision.archivos[0].url} style={{ zIndex: 12345 }}>
                                <CIXButton>Documentos del {config.literales.mision.sing}</CIXButton>
                            </a>
                        }
                    </div>
                    <div className="col carditinerario" style={{ backgroundImage: "url(https://www.goalcast.com/wp-content/uploads/2017/04/90-day-business-goals.jpg)"}}>
                        <div className="overlay" style={{ backgroundColor: mision.cajas ? ( mision.cajas.reto === true ? "rgba(25, 100, 20, 0.85)" : ( mision.cajas.reto === false ? "rgba(100, 25, 20, 0.85)" : "rgba(100, 100, 100, 0.85)")) : "rgba(100, 100, 100, 0.85)" }}></div>
                        <h3>Retos</h3>
                        { Array.isArray(mision.retoinstancias) && mision.retoinstancias.map((ri, i) => (
                            <p key={"reto_n" + i}>
                                <strong>{ ri.reto.nombre }</strong><br />
                                Objetivo: {(ri.reto && ri.objetivo) && (ri.objetivo+ ( ri.reto.tipomejora === "dinero" ? "€" : ( ri.reto.tipomejora === "porcentaje" ? "%" : ( ri.reto.tipomejora === "unidades" ? " unidades" : "" ))))}
                                { ri.reto && ri.reto.inverso && <Icon style={{verticalAlign: "middle", paddingLeft: 5}} type="swap" title="En este reto cuanto menor sea el resultado mayor será la consecución del objetivo" /> }
                            </p>
                        ))}
                    </div>
                    <div className="col carditinerario" style={{ backgroundImage: "url(https://www.universitiesabroad.com/sites/all/files/public/field/image/business%20admin2.jpg)"}}>
                        <div className="overlay" style={{ backgroundColor: mision.cajas ? ( mision.cajas.finalizacion === true ? "rgba(25, 100, 20, 0.85)" : ( mision.cajas.finalizacion === false ? "rgba(100, 25, 20, 0.85)" : "rgba(100, 100, 100, 0.85)")) : "rgba(100, 100, 100, 0.85)" }}></div>
                        <h3>Final de {config.literales.mision.sing}</h3>
                        <p>Fecha de finalización: {mision && mision.fechaFinal}</p>
                    </div>
                </div>
            </div>

            { mision.temporada && mision.temporada.lecciones &&
                <div className="row mt-5 justify-content-center">
                    <h3 className="text-left font-weight-bold col-12 mb-3">Lecciones aprendidas</h3>
                    <div className="col">{ renderHTML(mision.temporada.lecciones) }</div>
                </div>
            }
            <div className="row mt-5 justify-content-center">
                <h3 className="text-left font-weight-bold col-12 mb-3">Ranking</h3>
                <RankingMision 
                    instanciasPorMision={mision.mision && Array.isArray(mision.mision.misioninstancias) && mision.mision.misioninstancias} 
                    instanciasPorEquipo={mision.equipo && Array.isArray(mision.equipo.misioninstancias) && mision.equipo.misioninstancias} 
                    instanciasPorTemp={mision.temporada && Array.isArray(mision.temporada.misioninstancias) && mision.temporada.misioninstancias} 
                    rankingLocoDeUsuarios={userRank}
                    misionInstancia={mision}
                    id={match.params.idMision} 
                    config={config}
                />
            </div>                
            {/* { mision.temporada && mision.temporada.proximos &&
                <div className="row mt-5 justify-content-center">
                    <h3 className="text-left font-weight-bold col-12 mb-3">Próximos pasos</h3>
                    <div className="col">{ renderHTML(mision.temporada.proximos) }</div>
                </div>
            } */}
        </div>
    ) : null
}

const HacheDos = styled.h2`
    font-size: 30px; 
    font-weight: 600;
    margin: 0;
`

export default DetalleMisionPage;


