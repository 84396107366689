import React, { useState, useEffect, useContext, useRef } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_MISIONINSTANCIAS, EDIT_MISIONINSTANCIA, GET_CONFIG, EDIT_CONFIG } from '../queries/queryList'
import styled from 'styled-components'
import { Modal, message, Radio } from 'antd'
import Button from '../components/CIXButton'
import { LoadingContext } from '../components/LoadingContext'
import { presiosoJSON } from '../utils/func'

const HoliPage = ({ history }) => {
    const [emoji, setEmoji] = useState("&nbsp;")
    const [MI, setMI] = useState([])
    const [config, setConfig] = useState({})
    const [jerar, setJerar] = useState("")
    const [jerarErr, setJerarErr] = useState("")
    const [indic, setIndic] = useState("")
    const [indicErr, setIndicErr] = useState("")
    const [copyright, setCopyright] = useState("")
    const [pesoRI, setPesoRI] = useState(50)
    const [pesoRIErr, setPesoRIErr] = useState("")
    const [calculo, setCalculo] = useState(false)
    const { setLoading } = useContext(LoadingContext)
    const textboxJerar = useRef()
    const textboxIndic = useRef()
    useEffect(() => {
        const rand = Math.random()
        if(rand > 0.9)     { setEmoji("❤️") }
        else if(rand > 0.8){ setEmoji("🦄") }
        else if(rand > 0.7){ setEmoji("🍺") }
        else if(rand > 0.6){ setEmoji("🥌") }
        else if(rand > 0.5){ setEmoji("💃") }
        else if(rand > 0.4){ setEmoji("🍆") }
        else if(rand > 0.3){ setEmoji("🔌") }
        else if(rand > 0.2){ setEmoji("💣") }
        else if(rand > 0.1){ setEmoji("🍔") }
        else               { setEmoji("💩") }
    }, [])

    
    const { loading: loadingMI, error: errorMI, data: dataMI, refetch: refetchMI } = useQuery(GET_MISIONINSTANCIAS)
    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const [editarMIMt, { data: dataMIEdit, error: errorMIEdit }] = useMutation(EDIT_MISIONINSTANCIA, { refetchQueries: [{ query: GET_MISIONINSTANCIAS }] })
    const [editarConfigMt, { data: dataEditConfig, error: errorEditConfig }] = useMutation(EDIT_CONFIG, { refetchQueries: [{ query: GET_CONFIG }] })
    
    useEffect(() => {
        if(dataMI && Array.isArray(dataMI.misioninstancias)){
            setMI(dataMI.misioninstancias)
            setLoading(false)
        }
    }, [dataMI])

    useEffect(() => {
        if(dataConfig && Array.isArray(dataConfig.configs)){
            setConfig(dataConfig.configs[0])
            setJerar(presiosoJSON(dataConfig.configs[0].estructuraJerarquica))
            setIndic(presiosoJSON(dataConfig.configs[0].indicadores))
            setCopyright(dataConfig.configs[0].copyright)
            setPesoRI(dataConfig.configs[0].pesoRetosIndicadores)
            setCalculo(dataConfig.configs[0].mediasTrueSumasFalse ? "medias" : "sumas")
        }
    }, [dataConfig])

    useEffect(() => {
        if(dataMIEdit){
            message.success("Misiones reactivada correctamente")
        }
    }, [dataMIEdit])
    useEffect(() => {
        if(errorMIEdit){
            message.error("Ocurrió un error en la reactivación de la misión")
            console.log(errorMIEdit)
        }
    }, [errorMIEdit])

    useEffect(() => {
        if(dataEditConfig){
            message.success("La configuración se ha actualizado correctamente", 5)
        }
        else if(errorEditConfig){
            message.error("Se ha producido un error durante la actualización de la nueva configuración", 5)
        }
    }, [dataEditConfig, errorEditConfig])

    useEffect(() => {
        console.log({ calculo })
    }, [calculo])

    const modalForget = () => {
        Modal.info({
            title: "Mira aquí un momento",
            content: <img style={{ marginTop: "1em", width: "100%", objectFit: "contain"}} src="https://media1.giphy.com/media/374pcIBVEGb6g/giphy.gif?cid=790b7611c9841408e5414749c18311aaaf4217f88203a566&rid=giphy.gif" />,
            onOk: () => history.push("/auth/logout"),
            onClose: () => history.push("/auth/logout")
        })
    }

    const reactivarTodasLasInstancias = () => MI.forEach(mi => editarMIMt({ variables: { id: mi._id, estado: "activa" }}))

    const catchTab = (ref, setter, activate) => {
        const listener = evt => {
            if(evt.keyCode === 9){
                evt.preventDefault()
                setter(ref.current.value + "\t")
            }
        }
        if(ref){
            if(activate){
                ref.current.addEventListener("keydown", listener)
            }
            else{
                ref.current.removeEventListener("keydown", listener)
            }
        }
    }

    const guardarConfig = () => {
        let jerarObj = {}
        let jerarError = ""
        let indicObj = {}
        let indicError = ""
        try{
            jerarObj = JSON.parse(jerar)
            jerarError = ""
            setJerarErr(jerarError)

        }
        catch(err){ 
            jerarError = err.message
            setJerarErr(err.message) 
        }
        try{
            indicObj = JSON.parse(indic)
            indicError = ""
            setIndicErr(indicError)

        }
        catch(err){ 
            indicError = err.message
            setIndicErr(err.message) 
        }

        const pesoRIError = (pesoRI < 0 || pesoRI > 100) ? "El valor ha de estar comprendido entre 0 y 100" : ""
        setPesoRIErr(pesoRIError)

        if([indicError, jerarError, pesoRIError].filter(e => e).length > 0){
            message.error("El formulario contiene errores. Revísalo antes de continuar", 5)
        }
        else{
            editarConfigMt({ 
                variables: { 
                    id: config._id, 
                    estructuraJerarquica: jerarObj,
                    indicadores: indicObj,
                    copyright,
                    mediasTrueSumasFalse: calculo === "medias",
                    pesoRetosIndicadores: pesoRI
                } 
            })
        }
    }

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col">
                <h2>Everybody {emoji} HoliPage!!</h2>
                    <p>
                        <small>(Si no sabes cómo has acabado aquí, <MIB onClick={() => modalForget()}>sal sin tocar nada y olvida todo lo que has visto</MIB>, plz)</small>
                    </p>
                </div>
            </div>

            <div className="row my-4">
                <div className="col-12">
                    <h3>Parámetros de configuración maestra de la plataforma</h3>
                </div>
                <div className="col-12 mt-4">
                    <label htmlFor="Estructura jerárquica">Estructura jerárquica</label>
                    <Textarea
                        name="Estructura jerárquica" 
                        ref={textboxJerar}
                        rows="10" 
                        onFocus={() => catchTab(textboxJerar, setJerar, true)}
                        onBlur={() => catchTab(textboxJerar, setJerar, false)}
                        value={jerar}
                        onChange={evt => setJerar(evt.target.value)}
                    />
                    <small style={{ color: "red" }}>{ jerarErr }</small>
                </div>
                <div className="col-12 mt-4">
                    <label htmlFor="Indicadores">Indicadores</label>
                    <Textarea 
                        name="Indicadores"
                        ref={textboxIndic}
                        rows="10" 
                        onFocus={() => catchTab(textboxIndic, setIndic, true)}
                        onBlur={() => catchTab(textboxIndic, setIndic, false)}
                        value={indic}
                        onChange={evt => setIndic(evt.target.value)}
                    />
                    <small style={{ color: "red" }}>{ indicErr }</small>
                </div>
                <div className="col-12 mt-4">
                    <label htmlFor="Método de cálculo" style={{ width: "100%" }}>Método de cálculo</label>
                    <Radio.Group value={calculo} buttonStyle="solid" name="Método de cálculo" onChange={e => setCalculo(e.target.value)}>
                        <Radio.Button style={calculo === "medias" ? { backgroundColor: "#351b51", borderColor: "#351b51" } : {}} value="medias">Medias prorrateadas</Radio.Button>
                        <Radio.Button style={calculo === "sumas" ? { backgroundColor: "#351b51", borderColor: "#351b51" } : {}} value="sumas">Sumas guarras</Radio.Button>
                    </Radio.Group>
                </div>
                <div className="col-12 mt-4">
                    <label htmlFor="Copyright">Copyright</label>
                    <Input 
                        name="Copyright"
                        type="text" 
                        value={copyright}
                        onChange={evt => setCopyright(evt.target.value)}
                    />
                </div>
                <div className="col-12 mt-4">
                    <label htmlFor="Peso Retos Indicadores">Peso Retos/Indicadores</label>
                    <Input 
                        name="Peso Retos Indicadores"
                        type="number" 
                        value={pesoRI}
                        onChange={evt => setPesoRI(evt.target.value)}
                    />
                    <small style={{ color: "red" }}>{ pesoRIErr }</small>
                </div>
                <div className="col-12 mt-4">
                    <div className="btn btn-primary" onClick={() => guardarConfig()}>Guardar configuración</div>
                </div>
            </div>

            <div className="row mt-2 mb-4">
                <div className="col-12 my-2">
                    <h3>Botoncitos de la muerte</h3>
                </div>
                <div className="col">
                    <Button onClick={() => reactivarTodasLasInstancias()}>Reactivar todas las instancias de misión</Button>
                </div>
            </div>
        </div>
    )
}

const MIB = styled.span`
    color: #007bff;
    transition: all 300ms;
    &:hover{
        color: #0056b3;
        text-decoration: underline
    }
`

const Textarea = styled.textarea`
    font-family: monospace !important;
    width: 100%;
    background-color: #351b51;
    color: #fff;
    border: 0;
    border-radius: 3px;
    padding: 10px;
`

const Input = styled.input`
    border: 1px solid gainsboro;
    border-radius: 3px;
    padding: 5px;
    width: 100%;
`

export default HoliPage