import React from 'react'
import styled from 'styled-components'

const Button = ({ children, className, style, onClick }) => {
    // console.log(props)
    return (
        <CustomButton onClick={onClick} className={"btn btn-primary " + className} style={style}>
            { children }
        </CustomButton>
    )
}

const CustomButton = styled.div`
`

export default Button