import gql from 'graphql-tag'

const DELETE_MISION = gql`
    mutation DeleteMision(
        $id: ID!
    ){
        deleteMision(input: {
            where: {
                id: $id,
            }
        }){
            mision{
                _id
                nombre
                descripcion
                slug
                categorias{
                    _id
                    nombre
                }
            }
        }
    }
`

export default DELETE_MISION