import React, { useEffect, useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_EQUIPOS } from '../queries/queryList'
import CabeceraTeams from '../components/CabeceraTeams'
import TarjetaEquipo from '../components/TarjetaEquipo'
import { LoadingContext } from '../components/LoadingContext'
import { intersection } from 'lodash'

const PerfilPage = () => {

    const { loading: loadingEquipos, error: errorEquipos, data: dataEquipos, refetch: refetchEquipos } = useQuery(GET_EQUIPOS)
    const [equipos, setEquipos] = useState([])
    const { setLoading } = useContext(LoadingContext)
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    useEffect(() => {
        if(dataEquipos){
            console.log(dataEquipos.equipos)
            setEquipos(dataEquipos.equipos.filter(eq => userInfo?.role?.type === "administrator" || userInfo.managerDe.map(m => m._id).includes(eq._id)))
            setLoading(false)
        }
    }, [dataEquipos])

    return (
        <div className="container">  
            <CabeceraTeams 
                title="Mis equipos"
                hideSelector={true}
                user={JSON.parse(localStorage.getItem("userInfo"))}
            />
            <div className="row">
                { equipos.map(h => (
                        <div key={h._id} className="col-md-4">
                            <TarjetaEquipo equipo={h} noMsg={true} noBottom={true} style={{ marginBottom: "2em" }} />
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default PerfilPage