import gql from 'graphql-tag'

const DELETE_RETOINSTANCIA = gql`
    mutation DeleteRetoinstancia(
        $id: ID!
    ){
        deleteRetoinstancia(input: {
            where: {
                id: $id,
            }
        }){
            retoinstancia {
                _id
                objetivo
                resultado
                valor
                reto{
                    _id
                    nombre
                }
                misioninstancia{
                    _id
                }
            }
        }
    }
`

export default DELETE_RETOINSTANCIA