import gql from 'graphql-tag'

const GET_MISIONINSTANCIAS = gql`
    query Misioninstancia($where: JSON) {
        misioninstancias(where: $where){
            _id
            user{
                _id
                email
                nombre
                apellidos
                trabajadorDe{
                    _id
                    nombre
                    managers{
                        _id
                        nombre
                        apellidos
                        email
                        trabajadorDe{
                            _id
                        }
                    }
                }
            }
            estado
            comentario
            mision{
                _id
                nombre
            }
            temporada{
                _id
                nombre
            }
            retoinstancias{
                _id
                objetivo
                resultado
                valor
                reto{
                    _id
                    nombre
                }
            }
            lugar
            fecha
            fechaFinal
            cajas
            valores
        }
    }
`

export default GET_MISIONINSTANCIAS