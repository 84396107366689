import React, { useState, useEffect } from 'react'
import { Icon, message } from 'antd';
import Select from 'react-select';
import { uniqBy, partition } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { Alert } from "../CIXLibrary";
import { EDIT_CONFIG, GET_CONFIG } from '../../queries/queryList';

const hoverito = {
    backgroundColor: "gainsboro"
}

const BloqueNotificaciones = ({ data }) => {

    const reset = {
        auto: false,
        plantilla: "",
        incFirma: true,
        asunto: ""
    }

    const [usuarios, setUsuarios] = useState([])
    const [listEquipos, setListEquipos] = useState([])
    const [listRoles, setListRoles] = useState([])
    const [autoTab, setAutoTab] = useState({ open: false, hover: false })
    const [masivoTab, setMasivoTab] = useState({ open: false, hover: false })
    const [firmaTab, setFirmaTab] = useState({ open: false, hover: false })
    const [idConfig, setIdConfig] = useState()
    const [config, setConfig] = useState()
    const [emailSalida, setEmailSalida] = useState()
    const [completoPreasignado, setCompletoPreasignado] = useState(reset)
    const [misionValidada, setMisionValidada] = useState(reset)
    const [nuevoUsuario, setNuevoUsuario] = useState(reset)
    const [cambioPw, setCambioPw] = useState(reset)
    const [masivo, setMasivo] = useState(reset)
    const [firma, setFirma] = useState("")
    const [dest, setDest] = useState("todos")
    const [selectedRol , setSelectedRol] = useState(-1)
    const [selectedEquipo , setSelectedEquipo] = useState(-1)
    const [incManager, setIncManager] = useState(false)
    const [usuariosInput, setUsuariosInput] = useState("")

    useEffect(() => {
        // console.log(data)
        if(data){
            if(data.users){ setUsuarios(data.users)}
            if(data.equipos) { setListEquipos(data.equipos.map(eq => ({ value: eq._id, label: eq.nombre }))) }
            if(data.roles)   { setListRoles(data.roles.filter(r => r.name !== "Public").map(r => r.name === "Agente" ? ({ value: r._id, label: data.configs[0].literales.agente.sing }) : ({ value: r._id, label: r.name }))) }
            if(data.configs && data.configs[0]){
                const cfg = data.configs[0]
                setConfig(cfg)
                setIdConfig(cfg._id)
                setEmailSalida(cfg.correoSaliente)
                setCompletoPreasignado({
                    auto: cfg.enviarEquipoCompletoPreasignado || false,
                    plantilla: cfg.plantillaEquipoCompletoPreasignado || "",
                    incFirma: cfg.firmaEquipoCompletoPreasignado === null ? true : cfg.firmaEquipoCompletoPreasignado,
                    asunto: cfg.asuntoEquipoCompletoPreasignado || ""
                })
                setMisionValidada({
                    auto: cfg.enviarMisionValidada || false,
                    plantilla: cfg.plantillaMisionValidada || "",
                    incFirma: cfg.firmaMisionValidada === null ? true : cfg.firmaMisionValidada,
                    asunto: cfg.asuntoMisionValidada || ""
                })
                setNuevoUsuario({
                    auto: cfg.enviarNuevoUsuario || false,
                    plantilla: cfg.plantillaNuevoUsuario || "",
                    incFirma: cfg.firmaNuevoUsuario === null ? true : cfg.firmaNuevoUsuario,
                    asunto: cfg.asuntoNuevoUsuario || ""
                })
                setCambioPw({
                    auto: cfg.enviarCambioPw || false,
                    plantilla: cfg.plantillaCambioPw || "",
                    incFirma: cfg.firmaCambioPw === null ? true : cfg.firmaCambioPw,
                    asunto: cfg.asuntoCambioPw || ""
                })
                setFirma(cfg.plantillaFirma)
            }
        }        
    }, [data])

    const [cambiarConfigMt, { data: dataConfig, error: errorConfig }] = useMutation(EDIT_CONFIG, { refetchQueries: [{ query: GET_CONFIG }] })
    useEffect(() => {
        if(dataConfig) { message.success("La configuración de notificaciones se ha actualizado correctamente", 5) }
        else if(errorConfig){ 
            console.log(errorConfig)
            message.error("Ha ocurrido un error durante la actualización de la configuración de notificaciones", 5) 
        }
    }, [dataConfig, errorConfig])
    
    const handleChange = (evt, selector) => {
        if(selector === "rol"){ setSelectedRol(evt) }
        else if(selector === "equipo"){ setSelectedEquipo(evt) } 
    }
    
    const enviarCorreo = async (listaUsuarios) => {
        console.log(listaUsuarios)
        const responses = await Promise.all(listaUsuarios.map(email => {
            return fetch(process.env.REACT_APP_API_URL + "/email", {
                method: "POST",
                body: JSON.stringify({
                    to: email,
                    from: emailSalida,
                    subject: masivo.asunto,
                    text: masivo.plantilla + ( masivo.incFirma ? ("<br /><hr/><br />" + firma) : "" ),
                    html: masivo.plantilla + ( masivo.incFirma ? ("<br /><hr/><br />" + firma) : "" )
                }),
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + window.localStorage.getItem("token")
                }
            }).then(info => info.status)
        }))
        
        if( responses.filter(t => t === 200).length === responses.length ){ message.success("Todos los correos se han enviado correctamente", 5) }
        else{ message.error("Ha ocurrido un error durante el envío del correo masivo", 5) }
        
    }
    
    const enviarMasivo = async dest => {
        if(dest === "usuarios"){
            const userList = usuariosInput.split(",").map(e => e.trim())
            const [usuariosEnviar, usuariosError] = partition( userList, u => usuarios.map(u1 => u1.email).includes(u) )
            
            if( usuariosError.length > 0 ){ message.warning(`Los siguientes email no están en la base de datos de usuarios:\n ${usuariosError.reduce((a, b) => b + ", " + a, "").slice(0, -2)}`, 5) }
            else{ enviarCorreo( usuariosEnviar ) }
        }
        else if(dest === "rol"){
            if( selectedRol === -1 ){ message.info("Seleccione un rol", 5)}
            const usuariosEnviar = usuarios.filter(u => u.role.name === selectedRol.label)
            enviarCorreo( usuariosEnviar.map(u => u.email),  )
        }          
        else if(dest === "equipo"){
            if( selectedEquipo === -1 ){ message.info("Seleccione un equipo", 5)}
            const usuariosEnviar = usuarios.filter(u => {
                if( incManager === true ){
                    return (u.managerDe && u.trabajadorDe) && (u.managerDe.map(md => md._id).includes(selectedEquipo.value) || u.trabajadorDe._id === selectedEquipo.value)
                }
                else{
                    return u.trabajadorDe && u.trabajadorDe._id === selectedEquipo.value && u.role.name === "Agente"
                }
            })
            enviarCorreo( usuariosEnviar.map(u => u.email)  )
        }
        else{
            enviarCorreo( usuarios.map(u => u.email)  )
        }
    }
    
    return(
        <div className="container py-4">
            <div className="row mb-4">
                <div className="col-12">
                    <div className="jumbo sombra transi" style={{ overflow: "hidden" }}>
                        <div className="d-flex w-100 transi" 
                            style={autoTab.hover ? hoverito : {}}
                            style={autoTab.hover ? hoverito : {}}
                            onMouseEnter={() => setAutoTab({ ...autoTab, hover: true })} 
                            onMouseLeave={() => setAutoTab({ ...autoTab, hover: false })}
                            onClick={() => setAutoTab({ ...autoTab, open: !autoTab.open })}
                        >
                            <h3 className="p-4 text-center">Correos automáticos</h3><Icon type={`caret-${autoTab.open ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
                        </div>
                        {
                            autoTab.open && (
                                <div>
                                    <hr className="m-0" />
                                    <div className="p-4 pt-0 d-flex flex-column">
                                        <div className="d-flex justify-content-between align-items-center pb-4">
                                            <h5 className="mb-0">Equipo completo tiene {config.literales && config.literales.mision.sing} preasignad{config.literales && config.literales.mision.genero === "m" ? "o" : "a"}</h5>
                                            <div className="pagination">
                                                <div className={`page-item act ${completoPreasignado.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setCompletoPreasignado({ ...completoPreasignado, auto: true })}>Activado</div>
                                                </div>
                                                <div className={`page-item desact ${!completoPreasignado.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setCompletoPreasignado({ ...completoPreasignado, auto: false })}>Desactivado</div>
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="asunto1">Asunto</label>
                                        <input 
                                            className="form-control mb-4" 
                                            type="text" 
                                            id="asunto1" 
                                            name="asunto1" 
                                            value={completoPreasignado.asunto} 
                                            onChange={evt => setCompletoPreasignado({ ...completoPreasignado, asunto: evt.target.value })} 
                                        />
                                        <label htmlFor="ta1">Plantilla</label>
                                        <textarea 
                                            style={{ height: 125 }}
                                            className="form-control mb-4" 
                                            id="ta1" 
                                            name="ta1" 
                                            value={completoPreasignado.plantilla} 
                                            onChange={(evt) => setCompletoPreasignado({ ...completoPreasignado, plantilla: evt.target.value })} 
                                        />
                                        <div className="d-flex justify-content-start align-items-center pb-4">
                                            <label className="mb-0 pr-4">¿Incluir firma?</label>
                                            <div className="pagination">
                                                <div className={`page-item act ${completoPreasignado.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setCompletoPreasignado({ ...completoPreasignado, incFirma: true })}>Sí</div>
                                                </div>
                                                <div className={`page-item desact ${!completoPreasignado.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setCompletoPreasignado({ ...completoPreasignado, incFirma: false })}>No</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="align-self-end mt-4 btn btn-primary" onClick={() => {
                                            cambiarConfigMt({ variables: { 
                                                id: idConfig,
                                                enviarEquipoCompletoPreasignado: completoPreasignado.auto,
                                                plantillaEquipoCompletoPreasignado: completoPreasignado.plantilla,
                                                asuntoEquipoCompletoPreasignado: completoPreasignado.asunto,
                                                firmaEquipoCompletoPreasignado: completoPreasignado.incFirma,
                                            }})
                                        }}>Guardar</div>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="p-4 pt-0 d-flex flex-column">
                                        <div className="d-flex justify-content-between align-items-center pb-4">
                                            <h5 className="mb-0">{ data.configs[0].literales.agente.sing } tiene un{config.literales && config.literales.mision.genero === "f" && "a"} {config.literales.mision.sing} validad{config.literales && config.literales.mision.genero === "m" ? "o" : "a"}</h5>
                                            <div className="pagination">
                                                <div className={`page-item act ${misionValidada.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setMisionValidada({ ...misionValidada, auto: true })}>Activado</div>
                                                </div>
                                                <div className={`page-item desact ${!misionValidada.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setMisionValidada({ ...misionValidada, auto: false })}>Desactivado</div>
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="asunto2">Asunto</label>
                                        <input 
                                            className="form-control mb-4" 
                                            type="text" 
                                            id="asunto1" 
                                            name="asunto2" 
                                            value={misionValidada.asunto} 
                                            onChange={evt => setMisionValidada({ ...misionValidada, asunto: evt.target.value })} 
                                        />
                                        <label htmlFor="ta2">Plantilla</label>
                                        <textarea 
                                            style={{ height: 125 }}
                                            className="form-control mb-4" 
                                            id="ta2" 
                                            name="ta2" 
                                            value={misionValidada.plantilla}
                                            onChange={(evt) => setMisionValidada({ ...misionValidada, plantilla: evt.target.value })} 
                                        />
                                        <div className="d-flex justify-content-start align-items-center pb-4">
                                            <label className="mb-0 pr-4">¿Incluir firma?</label>
                                            <div className="pagination">
                                                <div className={`page-item act ${misionValidada.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setMisionValidada({ ...misionValidada, incFirma: true })}>Sí</div>
                                                </div>
                                                <div className={`page-item desact ${!misionValidada.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setMisionValidada({ ...misionValidada, incFirma: false })}>No</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="align-self-end mt-4 btn btn-primary" onClick={() => {
                                            cambiarConfigMt({ variables: { 
                                                id: idConfig,
                                                enviarMisionValidada: misionValidada.auto,
                                                plantillaMisionValidada: misionValidada.plantilla,
                                                asuntoMisionValidada: misionValidada.asunto,
                                                firmaMisionValidada: misionValidada.incFirma,
                                            }})
                                        }} >Guardar</div>
                                    </div>                                        
                                    <hr className="m-0" />
                                    <div className="p-4 pt-0 d-flex flex-column">
                                        <div className="d-flex justify-content-between align-items-center pb-4">
                                            <h5 className="mb-0">Correo de bienvenida</h5>
                                            <div className="pagination">
                                                <div className={`page-item act ${nuevoUsuario.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setNuevoUsuario({ ...nuevoUsuario, auto: true })}>Activado</div>
                                                </div>
                                                <div className={`page-item desact ${!nuevoUsuario.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setNuevoUsuario({ ...nuevoUsuario, auto: false })}>Desactivado</div>
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="asunto4">Asunto</label>
                                        <input 
                                            className="form-control mb-4" 
                                            type="text" 
                                            id="asunto4" 
                                            name="asunto4" 
                                            value={nuevoUsuario.asunto} 
                                            onChange={evt => setNuevoUsuario({ ...nuevoUsuario, asunto: evt.target.value })} 
                                        />
                                        <label htmlFor="ta4">Plantilla</label>
                                        <textarea 
                                            style={{ height: 125 }}
                                            className="form-control mb-4" 
                                            id="ta4" 
                                            name="ta4" 
                                            value={nuevoUsuario.plantilla}
                                            onChange={(evt) => setNuevoUsuario({ ...nuevoUsuario, plantilla: evt.target.value })} 
                                        />
                                        <div className="d-flex justify-content-start align-items-center pb-4">
                                            <label className="mb-0 pr-4">¿Incluir firma?</label>
                                            <div className="pagination">
                                                <div className={`page-item act ${nuevoUsuario.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setNuevoUsuario({ ...nuevoUsuario, incFirma: true })}>Sí</div>
                                                </div>
                                                <div className={`page-item desact ${!nuevoUsuario.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setNuevoUsuario({ ...nuevoUsuario, incFirma: false })}>No</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="align-self-end mt-4 btn btn-primary" onClick={() => {
                                            cambiarConfigMt({ variables: { 
                                                id: idConfig,
                                                enviarNuevoUsuario: nuevoUsuario.auto,
                                                plantillaNuevoUsuario: nuevoUsuario.plantilla,
                                                asuntoNuevoUsuario: nuevoUsuario.asunto,
                                                firmaNuevoUsuario: nuevoUsuario.incFirma,
                                            }})
                                        }} >Guardar</div>
                                    </div>                                        
                                    <hr className="m-0" />
                                    <div className="p-4 pt-0 d-flex flex-column">
                                        <div className="d-flex justify-content-between align-items-center pb-4">
                                            <h5 className="mb-0">Reseteo de contraseña</h5>
                                            <div className="pagination">
                                                <div className={`page-item act ${cambioPw.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setCambioPw({ ...cambioPw, auto: true })}>Activado</div>
                                                </div>
                                                <div className={`page-item desact ${!cambioPw.auto && "active"}`}>
                                                    <div className="page-link" onClick={() => setCambioPw({ ...cambioPw, auto: false })}>Desactivado</div>
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="asunto5">Asunto</label>
                                        <input 
                                            className="form-control mb-4" 
                                            type="text" 
                                            id="asunto5" 
                                            name="asunto5" 
                                            value={cambioPw.asunto} 
                                            onChange={evt => setCambioPw({ ...cambioPw, asunto: evt.target.value })} 
                                        />
                                        <label htmlFor="ta5">Plantilla</label>
                                        <textarea 
                                            style={{ height: 125 }}
                                            className="form-control mb-4" 
                                            id="ta5" 
                                            name="ta5" 
                                            value={cambioPw.plantilla}
                                            onChange={(evt) => setCambioPw({ ...cambioPw, plantilla: evt.target.value })} 
                                        />
                                        <div className="d-flex justify-content-start align-items-center pb-4">
                                            <label className="mb-0 pr-4">¿Incluir firma?</label>
                                            <div className="pagination">
                                                <div className={`page-item act ${cambioPw.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setCambioPw({ ...cambioPw, incFirma: true })}>Sí</div>
                                                </div>
                                                <div className={`page-item desact ${!cambioPw.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setCambioPw({ ...cambioPw, incFirma: false })}>No</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="align-self-end mt-4 btn btn-primary" onClick={() => {
                                            cambiarConfigMt({ variables: { 
                                                id: idConfig,
                                                enviarCambioPw: cambioPw.auto,
                                                plantillaCambioPw: cambioPw.plantilla,
                                                asuntoCambioPw: cambioPw.asunto,
                                                firmaCambioPw: cambioPw.incFirma,
                                            }})                                            
                                        }} >Guardar</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>                
            <div className="row mb-4">
                <div className="col-12">
                    <div className="jumbo sombra" style={{ overflow: "hidden" }}> 
                        <div className="d-flex w-100 transi"
                            style={masivoTab.hover ? hoverito : {}}
                            onMouseEnter={() => setMasivoTab({ ...masivoTab, hover: true })} 
                            onMouseLeave={() => setMasivoTab({ ...masivoTab, hover: false })}
                            onClick={() => setMasivoTab({ ...masivoTab, open: !masivoTab.open })}
                        >
                            <h3 className="p-4 text-center">Correos masivos</h3>
                            <Icon type={`caret-${masivoTab.open ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
                        </div>
                        {
                            masivoTab.open && (
                                <div>
                                    <hr className="m-0" />
                                    <div className="p-4 pt-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label htmlFor="dest" className="mb-0">Destinatario</label>
                                            <div className="pagination" id="dest" name="dest">
                                                <div className={`page-item ${dest === "todos" && "active"}`}>
                                                    <div className="page-link" onClick={() => setDest("todos")}>Todos</div>
                                                </div>
                                                <div className={`page-item ${dest === "rol" && "active"}`}>
                                                    <div className="page-link" onClick={() => setDest("rol")}>Rol</div>
                                                </div>
                                                <div className={`page-item ${dest === "equipo" && "active"}`}>
                                                    <div className="page-link" onClick={() => setDest("equipo")}>Equipo</div>
                                                </div>
                                                <div className={`page-item ${dest === "usuarios" && "active"}`}>
                                                    <div className="page-link" onClick={() => setDest("usuarios")}>Usuarios</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        dest === "rol" && (
                                            <div className="pb-4">
                                                <label className="sr-only" htmlFor="selectorRol">Rol</label>
                                                <Select
                                                    classNamePrefix="moradito"
                                                    id="selectorRol" name="selectorRol"
                                                    className="selecthor mx-4"
                                                    value={selectedRol}
                                                    onChange={evt => handleChange(evt, "rol")}
                                                    options={listRoles}
                                                    placeholder={"Rol"}
                                                />
                                            </div>
                                        )
                                    }                                        
                                    {
                                        dest === "equipo" && (
                                            <div>
                                                <label className="sr-only" htmlFor="selectorEquipo">Rol</label>
                                                <Select
                                                    classNamePrefix="moradito"
                                                    id="selectorEquipo" name="selectorEquipo"
                                                    className="selecthor mx-4"
                                                    style={{ minWidth: 300 }}
                                                    value={selectedEquipo}
                                                    onChange={(evt) => handleChange(evt, "equipo")}
                                                    options={listEquipos}
                                                    placeholder={"Equipo"}
                                                />
                                                <div className="d-flex px-4 pb-4 mt-4 align-items-center">
                                                    <label className="mb-0 mr-4" htmlFor="manager2">¿Envíar también al manager?</label>
                                                    <div className="pagination" name="manager2" id="manager2">
                                                        <div className={`page-item act ${incManager && "active"}`}>
                                                            <div className="page-link" onClick={() => setIncManager(true)}>Sí</div>
                                                        </div>
                                                        <div className={`page-item desact ${!incManager && "active"}`}>
                                                            <div className="page-link" onClick={() => setIncManager(false)}>No</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }                              
                                    {
                                        dest === "usuarios" && (
                                            <div className="px-4 pb-4 d-flex flex-column">
                                                <label className="pr-4" htmlFor="usuarios">Usuarios</label>
                                                <small style={{ color: "grey" }}>Introduce los correos de los usuarios separados por comas</small>
                                                <input 
                                                    style={{ flex: 1 }} 
                                                    className="form-control" 
                                                    type="text" 
                                                    name="usuarios" 
                                                    id="usuarios" 
                                                    value={usuariosInput} 
                                                    onChange={evt => setUsuariosInput(evt.target.value)} 
                                                />
                                            </div>
                                        )
                                    }
                                    <div className="px-4 pb-4 d-flex flex-column">
                                        <label htmlFor="asunto1">Asunto</label>
                                        <input 
                                            className="form-control mb-4" 
                                            type="text" 
                                            id="asunto3" 
                                            name="asunto3" 
                                            value={masivo.asunto} 
                                            onChange={evt => setMasivo({ ...masivo, asunto: evt.target.value })} 
                                        />
                                        <label htmlFor="ta3">Plantilla</label>
                                        <textarea 
                                            style={{ height: 125 }}
                                            className="form-control mb-4" 
                                            id="ta3" 
                                            name="ta3"
                                            onChange={evt => setMasivo({ ...masivo, plantilla: evt.target.value })}
                                        />
                                        <div className="d-flex justify-content-start align-items-center pb-4">
                                            <label className="mb-0 pr-4">¿Incluir firma?</label>
                                            <div className="pagination">
                                                <div className={`page-item act ${masivo.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setMasivo({ ...masivo, incFirma: true })}>Sí</div>
                                                </div>
                                                <div className={`page-item desact ${!masivo.incFirma && "active"}`}>
                                                    <div className="page-link" onClick={() => setMasivo({ ...masivo, incFirma: false })}>No</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn btn-primary mt-4 align-self-end" onClick={() => enviarMasivo(dest)}>Enviar</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="jumbo sombra" style={{ overflow: "hidden" }}> 
                        <div className="d-flex w-100 transi"
                            style={firmaTab.hover ? hoverito : {}}
                            onMouseEnter={() => setFirmaTab({ ...firmaTab, hover: true })} 
                            onMouseLeave={() => setFirmaTab({ ...firmaTab, hover: false })}
                            onClick={() => setFirmaTab({ ...firmaTab, open: !firmaTab.open })}
                        >
                            <h3 className="p-4 text-center">Firma</h3><Icon type={`caret-${firmaTab.open ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
                        </div>
                        {
                            firmaTab.open && (
                                <div>
                                    <hr className="m-0" />
                                    <div className="p-4 pt-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="pt-0 d-flex flex-column w-100">
                                                <textarea 
                                                    style={{ height: 125 }}
                                                    className="form-control" 
                                                    id="firma" 
                                                    name="firma"
                                                    onChange={evt => setFirma(evt.target.value)}
                                                    value={firma}
                                                />
                                                <div className="btn btn-primary mt-4 align-self-end" onClick={() => {
                                                    cambiarConfigMt({ 
                                                        variables: { 
                                                            id: idConfig, 
                                                            plantillaFirma: firma 
                                                        }
                                                    }) 
                                                }}>Guardar</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )    
}

export default BloqueNotificaciones

