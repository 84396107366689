import gql from 'graphql-tag'

const DELETE_USER = gql`
    mutation DeleteUser(
        $id: ID!
    ){
        deleteUser(input: {
            where: {
                id: $id,
            }
        }){
            user {
                username
                email
                confirmed
                blocked
                role{
                    _id
                    name
                }
                trabajadorDe{
                    _id
                    nombre
                }
                managerDe{
                    _id
                    nombre
                }
                nombre
                apellidos
                idusuario
                slug
            }
        }
    }
`

export default DELETE_USER