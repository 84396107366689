import React, { useState, useEffect } from 'react';
import { Icon, Tag, message } from 'antd';
import { Link } from 'react-router-dom'
import { Alert, TableIconWrapper } from "../CIXLibrary";
import Modal from 'react-modal';
import Select from "react-select";
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import styled from 'styled-components'

import { mayusculizer, slugify } from '../../utils/func';
import {
  ADD_EQUIPO,
  EDIT_EQUIPO,
  DELETE_EQUIPO,
  GET_EQUIPOS
} from '../../queries/queryList'
import { useMutation } from '@apollo/react-hooks';


const BloqueEquipos = ({ data }) => {
  
  const reset = { label: "-", value: null }

  const [errors, setErrors] = useState([])
  const [selEquipos, setSelEquipos] = useState([reset])
  const [selEquiposEdit, setSelEquiposEdit] = useState([reset])
  const [selNiveles, setSelNiveles] = useState([reset])

  const [nombreNuevo, setNombreNuevo] = useState("")
  const [nivelNuevo, setNivelNuevo] = useState(reset)
  const [superiorNuevo, setSuperiorNuevo] = useState(reset)
  const [latNuevo, setLatNuevo] = useState(0)
  const [lngNuevo, setLngNuevo] = useState(0)

  const [nombreEdit, setNombreEdit] = useState("")
  const [nivelEdit, setNivelEdit] = useState(reset)
  const [superiorEdit, setSuperiorEdit] = useState(reset)
  const [latEdit, setLatEdit] = useState(0)
  const [lngEdit, setLngEdit] = useState(0)

  const [modalEdit, setModalEdit] = useState({ open: false, row: {}})
  const [alertBorrarEquipo, setAlertBorrarEquipo] = useState({ open: false, row: {}})
  const [alertEditEquipo, setAlertEditEquipo] = useState({ open: false, row: {}})

  const [nuevoEquipoMt, { data: dataNuevo, error: errorNuevo }] = useMutation(ADD_EQUIPO, { refetchQueries: [{ query: GET_EQUIPOS }] })
  const [editarEquipoMt, { data: dataEdit, error: errorEdit }] = useMutation(EDIT_EQUIPO, { refetchQueries: [{ query: GET_EQUIPOS }] })
  const [borrarEquipoMt, { data: dataBorrado, error: errorBorrado }] = useMutation(DELETE_EQUIPO, { refetchQueries: [{ query: GET_EQUIPOS }] })

  useEffect(() => {
    if(data){
      setSelNiveles(Object.values(data.configs[0].estructuraJerarquica).map((e, i) => ({ label: mayusculizer(e.sing), value: i })))
    }
  }, [data])

  useEffect(() => {
    if(data && nivelNuevo){
      setSelEquipos(data.equipos.filter(e => e.nivel < nivelNuevo.value).map(e => ({ label: e.nombre, value: e._id })))
    }
  }, [nivelNuevo])

  useEffect(() => {
    if(data && nivelEdit){
      setSelEquiposEdit(data.equipos.filter(e => e.nivel < nivelEdit.value).map(e => ({ label: e.nombre, value: e._id })))
    }
  }, [nivelEdit, modalEdit])

  useEffect(() => {
    if(data && modalEdit.row.original){
      const selEquiposEdit = data.equipos.filter(e => e.nivel < modalEdit.row.original.nivel).map(e => ({ label: e.nombre, value: e._id }))
      setSelEquipos(selEquiposEdit)
      if(modalEdit.open){
        const padresOrd = modalEdit.row.original.padres.sort((a, b) => {
          if(a.nivel < b.nivel){ return  1 }
          if(a.nivel > b.nivel){ return -1 }
          else                 { return  0 }
        })

        console.log(padresOrd[0])
        console.log(selEquiposEdit)

        setNombreEdit(modalEdit.row.original.nombre)
        setNivelEdit(selNiveles.find(n => n.value === modalEdit.row.original.nivel))
        setSuperiorEdit(selEquiposEdit.find(e => padresOrd[0] && (e.value === padresOrd[0]._id)))
        setLatEdit(modalEdit.row.original.lat)
        setLngEdit(modalEdit.row.original.lng)
      }
    }
  }, [modalEdit])

  const thisTab = window.localStorage.getItem("lastTab") === "equipos"
  useEffect(() => { if(thisTab && dataNuevo){ message.success("Se ha creado correctamente el equipo") } }, [dataNuevo])
  useEffect(() => { if(thisTab && errorNuevo){ message.error("Ha fallado el proceso de creación del equipo") } }, [errorNuevo])
  useEffect(() => { if(thisTab && dataBorrado){ message.success("Se ha borrado correctamente el equipo") } }, [dataBorrado])
  useEffect(() => { if(thisTab && errorBorrado){ message.error("Ha fallado el proceso de borrado del equipo") } }, [errorBorrado])
  useEffect(() => { if(thisTab && dataEdit){ 
    message.success("Se ha editado correctamente el equipo") 
    setModalEdit({ open: false, row: {}})
  }}, [dataEdit])
  useEffect(() => { if(thisTab && errorEdit){ message.error("Ha fallado el proceso de edición del equipo") }}, [errorEdit])

  const crearEquipo = () => {
    const sup = data.equipos.find(e => e._id === superiorNuevo.value)
    const maxId = data.equipos.length > 0 ? Math.max.apply(null, data.equipos.map(e => parseInt(e.idequipo))) : -1
    const slugArr = Array.isArray(data.equipos) ? data.equipos.map(e => e.slug) : []
    // console.log(superiorNuevo.value)
    nuevoEquipoMt({variables: {
      nombre: nombreNuevo,
      lat: parseFloat(latNuevo),
      lng: parseFloat(lngNuevo),
      nivel: nivelNuevo.value,
      slug: slugify(nombreNuevo, slugArr),
      idequipo: (maxId + 1).toString(),
      padres: [
        ...(sup ? sup.padres.map(e => e._id) : []), //Que no falle si el elemento superior de la jerarquía no tiene padres
        ...(superiorNuevo.value ? [superiorNuevo.value] : []) //Que no falle si no hay seleccionado elemento superior, si creamos un elemento de nivel máximo
      ]
    }})
  }

  const editarEquipo = (bool, args) => {
    if(bool){ editarEquipoMt({ variables: {
      id: args.original._id,
      nombre: nombreEdit,
      lat: parseFloat(latEdit),
      lng: parseFloat(lngEdit),
      nivel: nivelEdit.value,
      padres: [
        ...data.equipos.find(e => e._id === superiorEdit.value).padres.map(e => e._id),
        superiorEdit.value
      ]
    }})}
  }

  const borrarEquipo = (bool, args) => {
    if(bool){ borrarEquipoMt({ variables: { id: args.original._id }}) }
  }

  return (
    <div className="w-100">
      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4 finalizaActivas">
          <div className="d-flex w-100">
            <h3>Nuevo equipo</h3>
          </div>
          <div className="mt-4">
            <div className="form-group">
              <label htmlFor="nombre">Nombre</label>
              <input 
                name="nombre" 
                className={`form-control ${errors.includes("nombre") && "is-invalid"}`} 
                type="text" 
                value={nombreNuevo} 
                onChange={evt => setNombreNuevo(evt.target.value)}
              />
              <div className="invalid-feedback">Se requiere este campo</div>
            </div> 
            <div className="form-group">
              <label htmlFor="nombre">Nivel</label>
              <Select className={`p-0 ${errors.includes("equipo") && "is-invalid"}`}
                classNamePrefix="moradito"
                value={nivelNuevo}
                onChange={nivel => setNivelNuevo(nivel)}
                options={selNiveles}
                placeholder={"Equipo"}
              />
              <div className="invalid-feedback">Se requiere este campo</div>
            </div> 
            <div className="form-group">
              <label htmlFor="nombre">Superior</label>
              <Select className={`p-0 ${errors.includes("equipo") && "is-invalid"}`}
                classNamePrefix="moradito"
                value={superiorNuevo}
                onChange={superior => setSuperiorNuevo(superior)}
                options={selEquipos}
                placeholder={"Equipo"}
              />
              <div className="invalid-feedback">Se requiere este campo</div>
            </div> 
            <div className="form-group d-none">
              <label htmlFor="nombre">Latitud</label>
              <input 
                name="latitud" 
                className="form-control"
                type="number" 
                value={latNuevo} 
                onChange={evt => setLatNuevo(evt.target.value)}
              />
            </div> 
            <div className="form-group d-none">
              <label htmlFor="nombre">Longitud</label>
              <input 
                name="longitud" 
                className="form-control"
                type="number" 
                value={lngNuevo} 
                onChange={evt => setLngNuevo(evt.target.value)}
              />
            </div> 
          </div>
          <div className="btn btn-primary" onClick={() => crearEquipo()}>Crear nuevo equipo</div>
        </div>
      </div>
      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4 finalizaActivas">
          <div className="d-flex w-100">
            <h3>Equipos</h3>
          </div>
          <div className="mt-4">
            <ReactTable
              data={data && data.equipos}
              columns={[
                {
                  Header: "ID",
                  id: "idequipo",
                  accessor: row => row.idequipo
                },
                {
                  Header: "Equipo",
                  id: "nombre",
                  accessor: "nombre"
                },
                {
                  Header: "Jerarquía",
                  id: "nivel",
                  accessor: row => selNiveles.find(n => n.value === row.nivel) && mayusculizer(selNiveles.find(n => n.value === row.nivel).label)
                },
                {
                  Header: "Superior",
                  id: "sup",
                  accessor: row => {
                    const padresOrd = row.padres.sort((a, b) => {
                      if(a.nivel > b.nivel){ return -1 }
                      else if(a.nivel < b.nivel){ return 1 }
                      else{ return 0 }
                    })
                    const padreDirecto = data.equipos.find(e => padresOrd[0] && (e._id === padresOrd[0]._id))
                    return padreDirecto && padreDirecto.nombre
                  }
                },
                {
                  id: "btn",
                  Cell: row => ( 
                    <TableIconWrapper>
                      <Tag color="volcano" onClick={() => setAlertBorrarEquipo({open: true, row })}><Icon type="delete" /></Tag>
                      <Tag><Icon type="edit" onClick={() => setModalEdit({open: true, row })}/></Tag>
                      <Link to={"/equipo/" + row.original.slug}>
                        <Tag color="purple"><Icon type="eye" /></Tag>
                      </Link>
                    </TableIconWrapper>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>

      <Modal 
        isOpen={modalEdit.open}                    
        onRequestClose={() => setModalEdit({open: false, row: {}})}
        contentLabel="Editar equipo"
        overlayClassName="Overlay"
        className="Modal"
        style={{ overflowY: "scroll" }}
      >
        <div className="container p-4" style={{ position: "relative" }}>
          <div style={{ position: "absolute", fontSize: "2em", top: 10, right: 20, cursor: "pointer", textAlign: "center", width: 40, zIndex: 100 }} 
               onClick={() => setModalEdit({ open: false, row: {}})}
          >&times;</div>
          <div className="d-flex w-100">
            <h3>Editar equipo</h3>
          </div>
          <div className="h-100">
            <div className="mt-4">
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input 
                  name="nombre" 
                  className={`form-control ${errors.includes("nombre") && "is-invalid"}`} 
                  type="text" 
                  value={nombreEdit} 
                  onChange={evt => setNombreEdit(evt.target.value)}
                />
                <div className="invalid-feedback">Se requiere este campo</div>
              </div> 
              <div className="form-group">
                <label htmlFor="nombre">Nivel</label>
                <Select className={`p-0 ${errors.includes("equipo") && "is-invalid"}`}
                  classNamePrefix="moradito"
                  value={nivelEdit}
                  onChange={nivel => setNivelEdit(nivel)}
                  options={selNiveles}
                  placeholder={"Equipo"}
                />
                <div className="invalid-feedback">Se requiere este campo</div>
              </div> 
              <div className="form-group">
                <label htmlFor="nombre">Superior</label>
                <Select className={`p-0 ${errors.includes("equipo") && "is-invalid"}`}
                  classNamePrefix="moradito"
                  value={superiorEdit}
                  onChange={superior => setSuperiorEdit(superior)}
                  options={selEquiposEdit}
                  placeholder={"Equipo"}
                />
                <div className="invalid-feedback">Se requiere este campo</div>
              </div> 
              <div className="form-group  d-none">
                <label htmlFor="nombre">Latitud</label>
                <input 
                  name="latitud" 
                  className="form-control"
                  type="number" 
                  value={latEdit} 
                  onChange={evt => setLatEdit(evt.target.value)}
                />
              </div> 
              <div className="form-group  d-none">
                <label htmlFor="nombre">Longitud</label>
                <input 
                  name="longitud" 
                  className="form-control"
                  type="number" 
                  value={lngEdit} 
                  onChange={evt => setLngEdit(evt.target.value)}
                />
              </div> 
            </div>
            <div className="d-flex w-100 justify-content-end">
              <div className="btn btn-primary mr-3" onClick={() => setAlertEditEquipo({ open: true, row: modalEdit.row })}>Editar equipo</div>
              <div className="btn btn-secondary" onClick={() => setModalEdit({open: false, row: {}})}>Cancelar</div>
            </div>
          </div>
        </div>
      </Modal>

      <Alert 
        open={alertEditEquipo.open}
        confirm={true}
        title="Editar equipo"
        text={`Va a editar el equipo ${alertEditEquipo.row.original && alertEditEquipo.row.original.nombre}. ¿Desea continuar?`}
        modalCb={bool => setAlertEditEquipo({open: bool, row: {}})}
        confirmFn={editarEquipo}
        confirmArgs={alertEditEquipo.row}
      /> 

      <Alert 
        open={alertBorrarEquipo.open}
        confirm={true}
        title="Borrar equipo"
        text={`Va a borrar el equipo ${alertBorrarEquipo.row.original && alertBorrarEquipo.row.original.nombre}. ¿Desea continuar?`}
        modalCb={bool => setAlertBorrarEquipo({open: bool, row: {}})}
        confirmFn={borrarEquipo}
        confirmArgs={alertBorrarEquipo.row}
      /> 
    </div>
  )
}

export default BloqueEquipos;