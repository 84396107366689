import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_CONFIG, GET_SINGLE_USER, GET_RETOS } from '../queries/queryList'
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { LineChart } from 'react-chartkick';
import Chart from 'chart.js';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import TarjetaId from '../components/TarjetaId';
import { mayusculizer } from '../utils/func';
import { LoadingContext } from '../components/LoadingContext'
import CuadroResultados from '../components/CuadroResultados'
import ModalEdit from '../components/ModalEdit'
import { redondear, media } from '../utils/func'
import { groupBy, flattenDeep, intersection, uniqBy } from 'lodash'
import { CIXButton } from '../components/CIXLibrary';
import moment from 'moment'

const AgentePage = ({ match, history }) => {
    const [user, setUser] = useState({
        equipo: {},
        imagen: null,
        role: {}
    })

    const [misionesCompletadas, setMisionesCompletadas] = useState([])
    const [misionesCompletadasPorTemp, setMisionesCompletadasPorTemp] = useState([])
    const [misionesActivas, setMisionesActivas] = useState([])
    const [chartOptions, setChartOptions] = useState({})
    const [actual, setActual] = useState()
    const [modalEdit, setModalEdit] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [pw, setPw] = useState("")
    const [pw2, setPw2] = useState("")
    const [config, setConfig] = useState({ indicadores: {} })
    const [filtro, setFiltro] = useState("temporada")
    const { loading, setLoading } = useContext(LoadingContext)

    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const { loading: loadingRetos, error: errorRetos, data: dataRetos, refetch: refetchRetos } = useQuery(GET_RETOS)
    const { loading: loadingUser, error: errorUser, data: dataUser, refetch: refetchUser } = useQuery(GET_SINGLE_USER, { variables: { where: { slug: match.params.idAgente } } })

    useEffect(() => {
        if (match.params.idAgente) {
            setLoading(true)
            refetchUser()
        }
    }, [match.params.idAgente])

    useEffect(() => {
        if (dataConfig) {
            setConfig(dataConfig.configs[0])
        }
    }, [dataConfig])

    useEffect(() => {
        if (dataUser && Array.isArray(dataUser.users) && config.indicadores) {
            seteoInicial(dataUser.users[0])
        }
    }, [dataUser, config])

    useEffect(() => {
        const mCompOrd = [...misionesCompletadas].sort((a, b) => {
            if(moment(a.temporada.createdAt).isBefore(b.temporada.createdAt)){ return 1 }
            else{ return -1 }
        })
        if (filtro === "temporada") {
            const mCompletadasPorTemp = Object.entries(groupBy(mCompOrd, it => it.temporada.nombre))
                                              .map(ent => [ent[0], Math.round(ent[1].reduce((a, b, idx, arr) => a + b.ranking / arr.length, 0))]).reverse()
            setMisionesCompletadasPorTemp(mCompletadasPorTemp)
        }
        else {
            const mFiltReto = mCompOrd.map(mc => ({
                ...mc.retoinstancias.find(ri => ri.reto && ri.reto.nombre === filtro),
                temporada: mc.temporada && mc.temporada.nombre
            })).map(qwe => [qwe.temporada, (qwe.resultado / qwe.objetivo) * 100])
            setMisionesCompletadasPorTemp(mFiltReto)
        }
    }, [filtro, misionesCompletadas])

    const seteoInicial = async user => {

        setLoading(true)
        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
        const cnd1 = userInfo.managerDe.map(e => e._id).includes(user.trabajadorDe &&user.trabajadorDe._id) && userInfo.role.name === "Manager"
        const cnd2 = intersection(flattenDeep(user.trabajadorDe && user.trabajadorDe.padres.map(p => p._id)), userInfo.managerDe.map(m => m._id)).length > 0 && userInfo.role.name === "Manager"
        const cnd3 = user._id === userInfo._id
        const cnd4 = userInfo.role.name === "Administrador"

        // console.log({
        //     cnd1, cnd2, cnd3, cnd4
        // })

        if (!cnd1 && !cnd2 && !cnd3 && !cnd4) {
            if (userInfo.role.name === "Manager") {
                const goTo = userInfo.trabajadorDe ? userInfo.trabajadorDe.slug : userInfo.managerDe[0].slug
                history.push("/equipo/" + goTo)
            }
            else if (userInfo.role.name === "Agente") {
                const goTo = userInfo.trabajadorDe ? userInfo.trabajadorDe.slug : userInfo.managerDe[0].slug
                history.push("/agente/" + userInfo.slug)
            }
        }

        if (Array.isArray(user.medias)) {
            const maxRanking = Math.max.apply(null, user.medias.map(m => redondear(config.mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
            const minRanking = Math.min.apply(null, user.medias.map(m => redondear(config.mediasTrueSumasFalse ? m.mediaTotal : m.sumaTotal, 1) + 50))
            const retos = config.mediasTrueSumasFalse ? "mediaRetos" : "sumaRetos"
            const indicadores = config.mediasTrueSumasFalse ? "mediaIndicadores" : "sumaIndicadores"
            const total = config.mediasTrueSumasFalse ? "mediaTotal" : "sumaTotal"
            const medias = {
                consecucion: user.medias.length > 0 ? media(user.medias, "mediaConsecucion", 1) : " - ",
                retos: user.medias.length > 0 ? media(user.medias, retos, 1) : " - ",
                mediaIndicadores: media(user.medias, indicadores, 1),
                ranking: user.medias.length > 0 ? media(user.medias, total, 1) + 50 : " - ",
                maxRanking: maxRanking !== -Infinity ? maxRanking : " - ",
                minRanking: minRanking !== Infinity ? minRanking : " - ",
                indicadores: Object.keys(config.indicadores).map(ind => ({
                    [ind]: user.medias.length > 0 ? media(user.medias.map(m => m.indicadores), ind, 1) : " - "
                })).reduce((a, b) => ({ ...a, ...b }), {})
            }
            user.mediasGlobales = medias
        }

        if (Array.isArray(user.misioninstancias)) {
            const mActivas = user.misioninstancias.filter(mi => mi.estado === "activa")
            const mCompletadas = user.misioninstancias.filter(mi => mi.estado === "completada").map((m, i) => {
                // console.log(config.indicadores)
                const mediaIndicadores = m.valores && Object.entries(m.valores).reduce((a, b) => {
                    return a + (parseInt(b[1]) * (config.indicadores[b[0]] ? config.indicadores[b[0]].peso : 0) * 0.01)
                }, 0)
                const mediaRetos = Array.isArray(m.retoinstancias) && m.retoinstancias.reduce((a, b) => a + (parseInt(b.valor) * b.reto.peso * 0.01), 0)
                const mediaRank = mediaIndicadores * (100 - config.pesoRetosIndicadores) * 0.01 + mediaRetos * config.pesoRetosIndicadores * 0.01 + 50;
                const sumaIndicadores = m.valores && Object.entries(m.valores).reduce((a, b) => a + parseInt(b[1]), 0)
                const sumaRetos = Array.isArray(m.retoinstancias) && m.retoinstancias.reduce((a, b) => a + parseInt(b.valor), 0)
                const sumaRank = sumaIndicadores + sumaRetos + 50;
                const r = config.mediasTrueSumasFalse ? mediaRank : sumaRank
                const ranking = Number.isNaN(r) ? "" : Math.round(r)
                return { ...m, ranking }
            }).sort((a, b) => {
                if(moment(a.temporada.createdAt).isAfter(b.temporada.createdAt)){ return 1 }
                else{ return -1 }
            })

            // console.log(mCompletadas)
            setMisionesActivas(mActivas)
            setMisionesCompletadas(mCompletadas)

            const chartOptions = {
                scales: {
                    scaleOverride: true,
                    xAxes: {
                        scaleLabel: mCompletadas.map(m => {
                            return {
                                display: true,
                                labelString: m.temporada && m.temporada.nombre
                            }
                        })
                    },
                }
            }

            setChartOptions(chartOptions)
        }

        setUser(user)
        setLoading(false)
    }


    const openModal = datos => {
        setModalIsOpen(true)
        setUser(datos)
    }

    const modalEditCallback = user => {
        setUser(user)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col-md-6">
                    <TarjetaId user={user} loading={loading} handleModalEdit={setModalEdit} />
                </div>
                <div className="col-md-6">
                    <CuadroResultados datos={user.mediasGlobales} config={config} />
                </div>
            </div>

            <div className="mt-5">
                {misionesActivas.length > 0 &&
                    <h3 className="text-left font-weight-bold mb-3">Itinerario {misionesActivas.length > 1 ? `${config.literales && mayusculizer(config.literales.mision.plur)} Activ${(config.literales && config.literales.mision.genero === "m") ? "o" : "a"}s` : `${config.literales && mayusculizer(config.literales.mision.sing)} Activ${(config.literales && config.literales.mision.genero === "m") ? "o" : "a"}`}</h3>
                }
                {misionesActivas.map((misionActiva, j) => (
                    <div className="row mt-2 mx-0" style={{ justifyContent: 'center' }} key={"ma-" + j}>
                        <div className="col carditinerario" style={{ backgroundImage: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtyUvzDZqH65rjfGnXjW9SH5U3Eum40cHhSAh6q8UlrzYBA2DB)" }}>
                            <div className="overlay" style={{ backgroundColor: misionActiva.cajas ? (misionActiva.cajas.entrenamiento === true ? "rgba(25, 100, 20, 0.85)" : (misionActiva.cajas.entrenamiento === false ? "rgba(100, 25, 20, 0.85)" : "rgba(100, 100, 100, 0.85)")) : "rgba(100, 100, 100, 0.85)" }}></div>
                            <h3>Entrenamiento</h3>
                            <p>{mayusculizer(config.literales && config.literales.mision.sing)}: {misionActiva && misionActiva.mision && misionActiva.mision.nombre}</p>
                            <p>Descripción: {misionActiva && misionActiva.comentario}</p>
                            <p>Fecha: {misionActiva && misionActiva.fecha}</p>
                            <p>Lugar: {misionActiva && misionActiva.lugar}</p>
                            {misionActiva && misionActiva.mision && Array.isArray(misionActiva.mision.archivos) && misionActiva.mision.archivos[0] &&
                                <a rel="noreferrer noopener" target="_blank" href={misionActiva.mision.archivos[0].url.includes("cix-lyntia.s3") ? misionActiva.mision.archivos[0].url : process.env.REACT_APP_API_URL + misionActiva.mision.archivos[0].url} style={{ zIndex: 12345 }}>
                                    <CIXButton>Documentos de{config.literales && (config.literales.mision.genero === "m" ? ("l " + config.literales.mision.sing) : (" la " + config.literales.mision.sing))}</CIXButton>
                                </a>
                            }
                        </div>
                        <div className="col carditinerario" style={{ backgroundImage: "url(https://www.goalcast.com/wp-content/uploads/2017/04/90-day-business-goals.jpg)" }}>
                            <div className="overlay" style={{ backgroundColor: misionActiva.cajas ? (misionActiva.cajas.reto === true ? "rgba(25, 100, 20, 0.85)" : (misionActiva.cajas.reto === false ? "rgba(100, 25, 20, 0.85)" : "rgba(100, 100, 100, 0.85)")) : "rgba(100, 100, 100, 0.85)" }}></div>
                            <h3>Retos</h3>
                            {misionActiva.retoinstancias.sort((a,b)=>a.reto.orden - b.reto.orden).map((ri, i) => (
                                <p key={"reto_n" + i}>
                                    <strong>{ri.reto.nombre}</strong><br />
                                    Objetivo: {(ri.reto && ri.objetivo) && (ri.objetivo + (ri.reto.tipomejora === "dinero" ? "€" : (ri.reto.tipomejora === "porcentaje" ? "%" : (ri.reto.tipomejora === "unidades" ? " unidades" : ""))))}
                                    {ri.reto && ri.reto.inverso && <Icon style={{ verticalAlign: "middle", paddingLeft: 5 }} type="swap" title="En este reto cuanto menor sea el resultado mayor será la consecución del objetivo" />}
                                </p>
                            ))}
                        </div>
                        <div className="col carditinerario" style={{ backgroundImage: "url(https://www.universitiesabroad.com/sites/all/files/public/field/image/business%20admin2.jpg)" }}>
                            <div className="overlay" style={{ backgroundColor: misionActiva.cajas ? (misionActiva.cajas.finalizacion === true ? "rgba(25, 100, 20, 0.85)" : (misionActiva.cajas.finalizacion === false ? "rgba(100, 25, 20, 0.85)" : "rgba(100, 100, 100, 0.85)")) : "rgba(100, 100, 100, 0.85)" }}></div>
                            <h3>Final de {config.literales && config.literales.mision.sing}</h3>
                            <p>Fecha de finalización: {misionActiva && misionActiva.fechaFinal}</p>
                        </div>
                    </div>
                ))}
            </div>

            {misionesCompletadas.length > 0 && (
                <div className="row mt-5">
                    <h3 className="text-left font-weight-bold col-12 my-4">Histórico de {mayusculizer(config.literales && config.literales.mision.plur)}</h3>
                    {misionesCompletadas.map((m, i) => {
                        return (
                            <div className="col-md-3" key={i}>
                                <Link to={`/detallemision/${m._id}`}>
                                    <div className="cajamision my-2" style={{
                                        backgroundImage: 'url("https://www.jagranjosh.com/imported/images/E/Articles/CBSE-12th-Business-Studies-Sample-Paper-2018.jpg")',
                                        border: `7px solid ${m.ranking >= 50 ? "#6BB332" : (m.ranking >= 45 ? (config.colores && config.colores.amarillo) || "#F89E3C" : (config.colores && config.colores.naranja) || "#C62438")}`
                                    }}>
                                        <h4>{m.temporada && m.temporada.nombre}</h4>
                                        <h2 className="m-0">{m.ranking}</h2>
                                        <div className="overlaybg"></div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    )}
                </div>
            )}

            {misionesCompletadas.length > 0 && (
                <div className="row mt-5">
                    <h3 className="text-left font-weight-bold col-12 my-4">Evolución de los puntos de ranking</h3>
                    <div className="row w-100 mb-4 justify-content-center">
                        <div className="bottonera w-100">
                            <div onClick={() => setFiltro("temporada")} className={filtro === "temporada" ? "boton activo" : "boton"}>Total</div>
                            {
                                dataRetos && uniqBy(dataRetos.retos, "nombre").map((reto, i) =>
                                    <div key={`reto${i}`} onClick={() => setFiltro(reto.nombre)} className={filtro === reto.nombre ? "boton activo" : "boton"}>{reto.nombre}</div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-md-12 pad20 d-flex justify-content-center jumbo sombra" style={{ filter: "hue-rotate(-110deg)" }}>
                        <LineChart discrete={true} data={misionesCompletadasPorTemp} width="100%" options={chartOptions} suffix={filtro !== "temporada" ? "%" : ""} />
                    </div>
                </div>
            )}

            <ModalEdit open={modalEdit} user={user} setModalEdit={setModalEdit} modalEditCallback={user => modalEditCallback(user)} />
        </div>
    )
}

export default AgentePage;
