import gql from 'graphql-tag'

const GET_EQUIPOS = gql`
    {
        equipos{
            _id
            nombre
            idequipo
            slug
            lat
            lng
            imagen{
                url
            }
            nivel
            medias{
                _id
                mediaRetos
                sumaRetos
                mediaIndicadores
                sumaIndicadores
                mediaTotal
                sumaTotal
                mediaConsecucion
                indicadores
                temporada{
                    _id
                    nombre
                }
            }
            hijos{
                _id
                nombre
                nivel
                slug
                idequipo
                medias{
                    _id
                    mediaRetos
                    sumaRetos
                    mediaIndicadores
                    sumaIndicadores
                    mediaTotal
                    sumaTotal
                    mediaConsecucion
                    indicadores
                    temporada{
                        _id
                        nombre
                    }
                }
                managers{
                    _id
                    nombre
                    apellidos
                    email
                    imagen{
                        url
                    }
                    trabajadorDe{
                        _id
                        slug
                    }
                    managerDe{
                        _id
                        slug
                    }
                }
                padres{
                    _id
                    nombre
                    nivel
                }
                trabajadores{
                    _id
                    nombre
                    apellidos
                    email
                    slug
                    imagen{
                        url
                    }
                    misioninstancias{
                        _id
                        valores
                        estado
                        comentario
                        mision{
                            _id
                            nombre
                        }
                        temporada{
                            _id
                            nombre
                        }
                        lugar
                        fecha
                        fechaFinal
                        cajas
                    }
                    medias{
                        _id
                        mediaTotal
                        sumaTotal
                        temporada{
                            _id
                        }
                    }
                }
            }
            padres{
                _id
                nombre
                nivel
                slug
                idequipo
                managers{
                    _id
                }
            }
            trabajadores{
                _id
                nombre
                apellidos
                email
                slug
                imagen{
                    url
                }
                misioninstancias{
                    _id
                    valores
                    estado
                    comentario
                    mision{
                        _id
                        nombre
                    }
                    temporada{
                        _id
                        nombre
                    }
                    lugar
                    fecha
                    fechaFinal
                    cajas
                }
                role{ 
                    _id
                    name
                }
                medias{
                        _id
                        mediaRetos
                        sumaRetos
                        mediaIndicadores
                        sumaIndicadores
                        mediaTotal
                        sumaTotal
                        mediaConsecucion
                        indicadores
                        temporada{
                            _id
                            nombre
                        }
                }
            }
            managers{
                _id
                nombre
                apellidos
                email
                imagen{
                    url
                }
                trabajadorDe{
                    _id
                }
            }
        }
    }
`

export default GET_EQUIPOS