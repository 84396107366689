import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import { Form, Input, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { CIXButton } from '../components/CIXLibrary'
import { Button } from 'antd'
import { LoadingContext } from '../components/LoadingContext'
import { goToBaseURL } from '../utils/func'

const AuthPage = ({ match, history, location }) => {
    
    const body = useRef({ identifier: "", password: "", email: "" })
    if(!["login", "forgot", "reset", "logout"].includes(match.params.show)){ 
        history.push("/auth/login") 
    }
    else if(match.params.show === "login"){
        goToBaseURL(history, true)
    }
    else if(match.params.show === "logout"){
        localStorage.clear()
        window.location = "/auth/login"
    }
    const [form, setForm] = useState({ help: "", status: ""})
    const [formForgot, setFormForgot] = useState({ help: "", status: ""})
    const [formReset, setFormReset] = useState({ help: "", status: ""})
    const { setLoading } = useContext(LoadingContext)

    const detectaEnter = (evt, b) => { 
        if(evt.keyCode === 13){
            if(match.params.show === "login"){ login(b) }
            else if(match.params.show === "forgot"){ forgot(b) }
            else if(match.params.show === "reset"){ reset(b) }
        }
    } 

    useEffect(() => {
        setLoading(false)
        document.addEventListener("keydown", evt => detectaEnter(evt, body.current))
        return document.removeEventListener("keydown", evt => detectaEnter(evt, body.current))
    }, [])

    const login = b => {
        fetch(process.env.REACT_APP_API_URL + "/auth/local", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(b)
        }).then(resp => {
            if(resp.status === 400){
                setForm({ help: "Usuario o contraseña inválidos", status: "error" })
            }
            else{
                resp.json().then(data => {
                    localStorage.setItem("userInfo", JSON.stringify(data.user))
                    localStorage.setItem("token", data.jwt)

                    setLoading(true)
                    if(data.user.role.name === "Administrador"){ history.push("/global") }
                    else if(data.user.role.name === "Manager"){
                        if(data.user.managerDe.length === 1){ history.push("/equipo/" + data.user.managerDe[0].slug) }
                        else{ history.push("/perfil") }
                    }
                    else if(data.user.role.name === "Agente"){
                        history.push("/agente/" + data.user.slug)
                    }
                    else{ /* */ }
                })
            }
        }).catch(err => {
            console.log(JSON.stringify(err))
            message.error("Hay un problema con el servidor. Inténtelo más tarde.")
        })

    }

    const forgot = b => {
        fetch(process.env.REACT_APP_API_URL + "/auth/forgot-password", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(b)
        }).then(resp => {
            if(resp.status === 200){ message.success("En breves instantes recibirás un correo electrónico para restaurar tu contraseña de CIX", 5)}
            else if(resp.status >= 400){ 
                console.log(resp)
                message.error("Se ha producido un error en la solicitud de restauración de la contraseña", 5)
            }
        }).catch(err => {
            console.log(err)
            message.error("No se puede conectar con el servidor en estos instantes", 5)
        })
    }

    const reset = b => {
        if(b.password !== b.passwordConfirmation){
            setFormReset({ status: "error", help: "Las contraseñas no son iguales" })
        }
        else if(b.password.length < 6){
            setFormReset({ status: "error", help: "La contraseña ha de tener una longitud de al menos 6 caracteres" })
        }
        else{
            fetch(process.env.REACT_APP_API_URL + "/auth/reset-password", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({...b, code: location.search.slice(6)})
            }).then(resp => {
                console.log(resp)
                if(resp.status === 200){ 
                    history.push("/auth/login")
                    message.success("La contraseña se ha restaurado correctamente, puedes hacer login a continuación", 5)
                }
                else if(resp.status === 400){
                    message.error("El enlace de recuperación de contraseña que has seguido es incorrecto o ha caducado", 5)
                }
                else if(resp.status > 400){
                    message.error("Se ha producido un error durante el proceso de recuperación de contraseña", 5)
                }
            }).catch(err => {
                console.log(err)
                message.error("El servidor no responde. Por favor, inténtalo más tarde", 5)
            })
        }
    }

    return (
        <div>
            { match.params.show !== "logout" ?
                <FormContainer>
                    <Img src={process.env.REACT_APP_API_URL + "/usersilh.png"} />
                    <Title>Identifícate</Title>
                    <p>Accede a tu área privada de benchmarks y planes de acción personalizados</p>
                    { match.params.show === "login" && (
                        <Form onSubmit={e => {
                            // e.preventDefault()
                            console.log(e)}
                        }>
                            <Form.Item validateStatus={form.status}>
                                <Input 
                                    placeholder="Email" 
                                    onChange={e => {
                                        body.current = { ...body.current, identifier: e.target.value }
                                        if(form.status === "error"){ 
                                            setForm({ help: "", status: "" })
                                        }
                                    }} 
                                />
                            </Form.Item>
                            <Form.Item validateStatus={form.status} help={form.help}>
                                <Input.Password 
                                    placeholder="Contraseña" 
                                    onChange={e => {
                                        body.current = { ...body.current, password: e.target.value }
                                        if(form.status === "error"){ 
                                            setForm({ help: "", status: "" })
                                        }
                                    }} 
                                />
                            </Form.Item>
                            <Link to="/auth/forgot">
                                <p>¿Olvidaste la contraseña?</p>
                            </Link>
                            <CIXButton onClick={() => login(body.current)}>Acceder</CIXButton>
                        </Form>
                    )}
                    { match.params.show === "forgot" && (
                        <div>
                            <Form.Item label="Introduce tu email" colon={false} validateStatus={formForgot.status}>
                                <Input 
                                    placeholder="Email" 
                                    onChange={e => {
                                        body.current = { ...body.current, email: e.target.value } 
                                    }}
                                />
                            </Form.Item>
                            <Link to="/auth/login">
                                <p>Volver al login</p>
                            </Link>
                            <CIXButton onClick={() => forgot(body.current)}>Recuperar contraseña</CIXButton>
                        </div>
                    )}
                    { match.params.show === "reset" && (
                        <div>
                            <Form.Item label="Introduce tu nueva contraseña" colon={false} validateStatus={formReset.status}> 
                                <Input.Password
                                    placeholder="Nueva contraseña" 
                                    onChange={e => {
                                        body.current = { ...body.current, password: e.target.value } 
                                    }}
                                />
                            </Form.Item>
                            <Form.Item colon={false} validateStatus={formReset.status} help={formReset.help}>
                                <Input.Password
                                    placeholder="Confirmar contraseña" 
                                    onChange={e => {
                                        body.current = { ...body.current, passwordConfirmation: e.target.value } 
                                    }}
                                />
                            </Form.Item>
                            <Link to="/auth/login">
                                <p>Volver al login</p>
                            </Link>
                            <CIXButton onClick={() => reset(body.current)}>Cambiar contraseña</CIXButton>
                        </div>
                    )}
                </FormContainer>
            : <h1 style={{ width: "100%", textAlign: "center", color: "grey", marginTop: "1em" }}>Cerrando sesión...</h1>}
        </div>
    )
}

const FormContainer = styled.div`
    display: flex;
    min-height: 200px;
    text-align: center;
    width: 416px;
    margin: 3em auto;
    margin-bottom: 0;
    padding: 33px 15px 15px 15px;
    border-radius: 2px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 2px 20px rgba(0,0,0,0.2);
    flex-direction: column;
    .ant-form-item{
        margin-bottom: 1em;
    }
`

const Img = styled.img`
    width: 100%;
    height: 100px;
    object-fit: contain;
`

const Title = styled.h2`
    margin-top: 1em;
`

export default AuthPage
