import gql from 'graphql-tag'

const ADD_MISION = gql`
    mutation CreateMision(
        $nombre: String
        $descripcion: String
        $slug: String
        $categorias: [ID]
    ){
        createMision(input: {
            data: {
                nombre: $nombre,
                descripcion: $descripcion,
                slug: $slug,
                categorias: $categorias
            }
        }){
            mision{
                _id
                nombre
                descripcion
                slug
                categorias{
                    _id
                    nombre
                }
            }
        }
    }
`

export default ADD_MISION