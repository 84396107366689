import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom'
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import "react-table/react-table.css";
import { GET_CONFIG } from '../../queries/queryList';
import { useQuery } from '@apollo/react-hooks';

const BloqueAnalitica = ({ data }) => {
  
  const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
  const [equipos, setEquipos] = useState([])
  const [literales, setLiterales] = useState(null);


  
  useEffect(() => {
    if(data && dataConfig){
      // console.log(data.equipos)
      setEquipos(data.equipos)
      setLiterales(dataConfig.configs[0].literales)
    }
  }, [data])

  return literales && (
    <div className="w-100">
      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4 finalizaActivas">
          <div className="d-flex w-100 flex-column">
            <h3 className="mb-4">Analítica de equipos</h3>
            <ReactTable
              data={equipos}
              columns={[
                {
                  Header: "Equipo",
                  id: "equipo",
                  className: "text-left justify-content-start px-2",
                  accessor: d => d.nombre,
                  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["equipo"] }),
                  filterAll: true
                },                  
                {
                  Header: "Nº " + literales.agente.plur,
                  id: "numAgentes",
                  filterable: false,
                  accessor: d => d.trabajadores.filter( c => c && c.role && c.role.name === "Agente" ).length,
                },                  
                {
                  Header: "Validadas",
                  id: "conValidada",
                  filterable: false,
                  accessor: d => d.trabajadores.filter( c => c && c.role && c.role.name === "Agente" && c.misioninstancias.map(m => m.estado).includes("activa")).length
                },                  
                {
                  Header: "Preasignados",
                  id: "conPreasignada",
                  filterable: false,
                  accessor: d => d.trabajadores.filter( c => c && c.role && c.role.name === "Agente" && c.misioninstancias.map(m => m.estado).includes("inactiva")).length
                },                  
                {
                  Header: "Sin "+literales.mision.sing,
                  id: "sinMision",
                  filterable: false,
                  accessor: d => d.trabajadores.filter( c => c && c.role && c.role.name === "Agente" && !c.misioninstancias.map(m => m.estado).includes("activa") && !c.misioninstancias.map(m => m.estado).includes("inactiva")).length
                },
                {
                  Header: "Gestionar",
                  id: 'buttons',
                  filterable: false,
                  Cell: row => {
                    return (
                      <div className="text-center">
                        <Link to={`/gestionmisiones/${row.original.slug}`}><Icon type="eye-o" style={{ color: "grey" }}/></Link>
                      </div>
                    );
                  }
                }
              ]}
              filterable
              defaultPageSize={10}
              previousText='Anterior'
              nextText='Siguiente'
              loadingText='Cargando...'
              // noDataText={loading ? "Cargando..." : "Sin datos"}
              pageText='Página'
              ofText='de'
              rowsText='filas'
              className="-striped -highlight w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BloqueAnalitica;