import gql from 'graphql-tag'

const GET_SINGLE_MISION = gql`
    query Mision($id: ID!) {
        misioninstancia(id: $id){
            _id
            valores
            estado
            comentario
            lugar
            fecha
            fechaFinal
            cajas
            user{
                _id
                slug
                nombre
                apellidos
            }
            mision{
                _id
                nombre
                descripcion
                archivos{
                    _id
                    name
                    url
                }
                misioninstancias{
                    _id
                    valores
                    estado
                    equipo{ 
                        _id
                        nombre 
                    }
                    temporada { 
                        _id
                        nombre 
                    }
                    retoinstancias{
                        valor
                        reto{
                            _id
                            peso
                        }
                    }
                    user{
                        _id
                        nombre
                        apellidos
                    }
                }
            }
            temporada{
                _id
                nombre
                lecciones
                proximos
                misioninstancias{
                    _id
                    valores
                    estado
                    equipo{ 
                        _id
                        nombre 
                    }
                    mision { 
                        _id
                        nombre 
                    }
                    retoinstancias{
                        valor
                        reto{
                            _id
                            peso
                        }
                    }
                    user{
                        _id
                        nombre
                        apellidos
                    }
                }
            }
            retoinstancias{
                objetivo
                valor
                resultado
                reto{
                    _id
                    nombre
                    peso
                    tipomejora
                    inverso
                    tooltip
                }
            }
            equipo{
                _id
                nombre
                misioninstancias{
                    _id
                    valores
                    estado
                    mision{ 
                        _id
                        nombre 
                    }
                    temporada { 
                        _id
                        nombre 
                    }
                    retoinstancias{
                        valor
                        reto{
                            _id
                            peso
                        }
                    }
                    user{
                        _id
                        nombre
                        apellidos
                    }
                }
            }
        }
    }
`

export default GET_SINGLE_MISION