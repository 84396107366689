import gql from 'graphql-tag'

const GET_TEMPORADAS = gql`
    {
        temporadas{
            _id
            nombre
            finalizada
            misioninstancias{
                _id
            }
            lecciones
            proximos
        }
    }
`

export default GET_TEMPORADAS