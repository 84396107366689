import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import GET_CONFIG from '../queries/GET_CONFIG'

const Footer = () => {
    const { loading, error, data } = useQuery(GET_CONFIG)
    const [config, setConfig] = useState({})
    const [role, setRole] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(false)

    if(data && Object.keys(config).length === 0){ setConfig(data.configs[0]) }

    useEffect(() => {
        try{
            const userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
            if(userInfo && userInfo.role && userInfo.role.name){
                setRole(userInfo.role.name)
            }
        } catch(err){ console.log(err) }
    }, [])

    useEffect(() => {
        if(config && typeof config.version === "string"){
            const envVersion = process.env.REACT_APP_VERSION.replace(" Dev", "").split(".")
            const backVersion = config.version.split(".")
            if(
                backVersion[0] > envVersion[0] || 
                (backVersion[0] === envVersion[0] && backVersion[1] > envVersion[1]) ||
                (backVersion[0] === envVersion[0] && backVersion[1] === envVersion[1] && backVersion[2] > envVersion[2])
            ){
                setNeedUpdate(true)
            }
        }
    }, [config])
    
    return (
        <FooterWrapper>
            <span>{ config.copyright }</span>
            { role === "Administrador" && config.ayudaAdmin &&
                <HelpLink colores={config ? config.colores : {}} href={config.ayudaAdmin.url.includes("cix-lyntia.s3") ? config.ayudaAdmin.url : process.env.REACT_APP_API_URL + config.ayudaAdmin.url} target="_blank" rel="noopener noreferrer">
                    <Icon type="question-circle" />
                    <span>Ayuda</span>
                </HelpLink>
            }
            { role === "Manager" && config.ayudaManager &&
                <HelpLink colores={config ? config.colores : {}} href={config.ayudaManager.url.includes("cix-lyntia.s3") ? config.ayudaManager.url : process.env.REACT_APP_API_URL + config.ayudaManager.url} target="_blank" rel="noopener noreferrer">
                    <Icon type="question-circle" />
                    <span>Ayuda</span>
                </HelpLink>
            }
            { role === "Agente" && config.ayudaUsuario &&
                <HelpLink colores={config ? config.colores : {}} class="btn btn-secondary" href={config.ayudaUsuario.url.includes("cix-lyntia.s3") ? config.ayudaUsuario.url : process.env.REACT_APP_API_URL + config.ayudaUsuario.url} target="_blank" rel="noopener noreferrer">
                    <Icon type="question-circle" />
                    <span>Ayuda</span>
                </HelpLink>
            }
            <span style={{ margin: 0 }}>v{process.env.REACT_APP_VERSION}{needUpdate && <Link to="/actualizar"><HelpSpan colores={config ? config.colores : {}}>(Versión {config.version} disponible)</HelpSpan></Link>}</span>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    padding: 0 2em;
`

const HelpLink = styled.a`
    display: flex;
    align-items: center;
    color: ${({ colores }) => colores.base || "grey"};
    &:hover{
        color: ${({ colores }) => colores.claro2 || "grey"};
    }
    i{
        margin-right: 5px;

    }
    span{
        margin-bottom: -3px;
    }
`

const HelpSpan = styled.span`
    color: ${({ colores }) => colores.base || "grey"};
    &:hover{
        color: ${({ colores }) => colores.claro2 || "grey"};
    }
    font-weight: bold
    margin-left: 5px;
`

export default Footer

