import gql from 'graphql-tag'

const ADD_MISIONINSTANCIA = gql`
    mutation CreateMisionInstancia(
        $user: ID!
        $equipo: ID
        $valores: JSON
        $estado: ENUM_MISIONINSTANCIA_ESTADO
        $comentario: String
        $mision: ID
        $temporada: ID
        $lugar: String
        $fecha: String
        $fechaFinal: String
        $cajas: JSON
    ){
        createMisioninstancia(input: {
            data: {
                user: $user
                equipo: $equipo
                valores: $valores
                estado: $estado
                comentario: $comentario
                mision: $mision
                temporada: $temporada
                lugar: $lugar
                fecha: $fecha
                fechaFinal: $fechaFinal
                cajas: $cajas
            }
        }){
            misioninstancia{
                _id
                user{
                    _id
                    email
                    nombre
                    apellidos
                }
                estado
                comentario
                mision{
                    _id
                    nombre
                }
            }
        }
    }
`

export default ADD_MISIONINSTANCIA