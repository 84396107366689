import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { mayusculizer } from '../utils/func'
import { GET_CONFIG } from '../queries/queryList'

const CuadroResultados = ({ datos = { indicadores: {}}, config }) => {

    // const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const [colores, setColores] = useState({})
  
    useEffect(() => {
      if(config && config.colores){
        setColores(config.colores)
      }
    }, [config])

    return (
        <div>
            {/* <h3 className="text-left font-weight-bold col-12 mb-4">Media Ranking Red</h3> */}
            <div className="jumbo sombra">
                <UpperWrapper className="row">
                    <BigTotal>
                        <h2>{ datos.ranking }</h2>
                        <p>Puntos de Ranking</p>
                    </BigTotal>
                    <MPWrapper>
                        <MejorPeor>Min <strong>{ datos.minRanking }</strong></MejorPeor>
                        <MejorPeor>Max <strong>{ datos.maxRanking }</strong></MejorPeor>
                    </MPWrapper>
                </UpperWrapper>
                <LowerWrapper className="row">
                    {
                        config && Object.entries(config.indicadores).map((ind, i) => {
                            return (
                                <Indicador key={"ind-" + i}>
                                    <Titulo>{mayusculizer(ind[1].nombre)}</Titulo>
                                    <Rank style={{ color: datos.indicadores[ind[0]] > 0 ? "green" : ( datos.indicadores[ind[0]] < 0 ? "red" : "black" )}}>
                                        {
                                            (Number.isNaN(datos.indicadores[ind[0]]) || datos.indicadores[ind[0]] === 0) ? 0 : (datos.indicadores[ind[0]] > 0 ? ("+" + datos.indicadores[ind[0]]) : datos.indicadores[ind[0]])
                                        }
                                    </Rank>
                                </Indicador>
                            )
                        })
                    }
                    <Indicador key={"ind-reto"}>
                        <Titulo>Reto</Titulo>
                        <Rank style={{ color: datos.retos > 0 ? "green" : ( datos.retos < 0 ? "red" : "black" )}}>
                            {
                                (Number.isNaN(datos.retos) || datos.retos === 0) ? 0 : (datos.retos > 0 ? ("+" + datos.retos) : datos.retos)
                            }
                        </Rank>
                    </Indicador>
                </LowerWrapper>
            </div>
        </div>
    )
}


const UpperWrapper = styled.div`
    height: 190px;
    margin: 0px !important;
    border-bottom: 1px solid gainsboro;
    `
    
const LowerWrapper = styled.div`
    height: 120px;
    margin: 0px !important;
    display: flex;
`

const BigTotal = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 0;
    }
    p{
        color: gainsboro;
    }
`

const MPWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    border-left: 1px solid gainsboro;
`
const MejorPeor = styled.h4`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 16px;
    &:not(:last-child){
        border-bottom: 1px solid gainsboro;
    }
    strong{
        margin-left: 4px;
    }
`
    
const Indicador = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:not(:last-child){
        border-right: 1px solid gainsboro;
    }
`

const Titulo = styled.div`
    width: 100%;
    border-bottom: 1px solid gainsboro;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Rank = styled.div`
    width: 100%;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    font-weight: 600;
`

export default CuadroResultados