import gql from 'graphql-tag'

const GET_EQUIPO_COMPLETO = gql`
    query Equipo($where: JSON) {
        equipos(where: $where){
            _id
            nombre
            idequipo
            slug
            lat
            lng
            imagen{
                url
            }
            nivel
            hijos{
                _id
                nombre
                nivel
                slug
                idequipo
                managers{
                    _id
                    nombre
                    apellidos
                    email
                    trabajadorDe{
                        _id
                    }
                }
                padres{
                    _id
                    nombre
                    nivel
                }
            }
            padres{
                _id
                nombre
                nivel
                slug
                idequipo
            }
            trabajadores{
                _id
                nombre
                apellidos
                email
                slug
                role{
                    _id
                    name
                }
                misioninstancias{
                    _id
                    valores
                    estado
                    comentario
                    mision{
                        _id
                        nombre
                    }
                    temporada{
                        _id
                        nombre
                    }
                    retoinstancias{
                        _id
                        objetivo
                        resultado
                        valor
                        reto{
                            _id
                            nombre
                            tipomejora
                            inverso
                        }
                    }
                    lugar
                    fecha
                    fechaFinal
                    cajas
                }
                imagen{
                    _id
                    url
                }
            }
            managers{
                _id
                nombre
                apellidos
                email
            }
        }
    }
`

export default GET_EQUIPO_COMPLETO