import gql from 'graphql-tag'

const DELETE_RETO = gql`
    mutation DeleteReto(
        $id: ID!
    ){
        deleteReto(input: {
            where: {
                id: $id,
            }
        }){
            reto{
                _id
                mision{
                    _id
                    nombre
                }
                nombre
            }
        }
    }
`

export default DELETE_RETO