import gql from 'graphql-tag'

const DELETE_MISIONINSTANCIA = gql`
    mutation DeleteMisionInstancia(
        $id: ID!
    ){
        deleteMisioninstancia(input: {
            where: {
                id: $id,
            }
        }){
            misioninstancia{
                _id
                user{
                    _id
                    email
                    nombre
                    apellidos
                }
                estado
                comentario
                mision{
                    _id
                    nombre
                }
            }
        }
    }
`

export default DELETE_MISIONINSTANCIA