import React from 'react';
import { Link } from 'react-router-dom'
import { Skeleton, Avatar } from 'antd';
// import Avatar from '@material-ui/core/Avatar';

const RankingEquipos = ({ equipos, history, loading, minMax = true }) => {
    //const flag = equipos[0] !== undefined && equipos[0].comerciales[0] !== undefined && equipos[0].comerciales[0].medias !== undefined

    const flag = !loading
    
    const eqOrdenados = (loading ? Array(5).fill("dummy") : equipos).sort((a, b) => {
        const ar = a.mediasInter.ranking !== " - " ? a.mediasInter.ranking : 0
        const br = b.mediasInter.ranking !== " - " ? b.mediasInter.ranking : 0
        if( ar < br ){ return 1 }
        else if( ar > br ){ return -1 }
        else{ return 0 }
    })
    
    // console.log( eqOrdenados )
    
    return(
        <div className="row m-1 jumbo sombra alto320 bodytable">
            <div className="innercontainer">
            {
                eqOrdenados.map((e, i) => {
                    //console.log(`${e.nombre} - ${e.ranking}`)
                    return(
                        <Link key={i} to={(e.email ? "/agente/" : "/equipo/") + e.slug}>
                            <div className="megarow">
                                <div className="comp" style={{flex:3}}>
                                    <Avatar alt={`Foto de ${e.nombre + (e.apellidos ? (" " + e.apellidos) : "")}`} src={e.imagen ? e.imagen.url.includes("cix-lyntia.s3") ? e.imagen.url : `${process.env.REACT_APP_API_URL}${e.imagen.url}` : process.env.REACT_APP_API_URL + "/uploads/team.jpg"}  className="avatar" />
                                </div>
                                <div className="comp left" style={{flex:5}}>
                                    <h3>{ loading ? <Skeleton width={"135px"}/> : e.nombre + (e.apellidos ? (" " + e.apellidos) : "")}</h3>
                                </div>
                                { loading ? <Skeleton width={"135px"}/> : (
                                    <div className="comp" style={{flex:7, alignItems: "center", textAlign:'right', justifyContent:"space-evenly"}}>
                                        <div className={minMax ? "d-flex flex-column" : "d-none"}>
                                            <small className="p-1">Min <span style={{ fontWeight: "bold", lineHeight: "inherit", fontSize: "inherit" }}>{ flag ? e.mediasInter && e.mediasInter.minRanking : "" }</span></small>
                                            <small className="p-1">Max <span style={{ fontWeight: "bold", lineHeight: "inherit", fontSize: "inherit" }}>{ flag ? e.mediasInter && e.mediasInter.maxRanking : "" }</span></small>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <h3 style={{ fontSize: 20 }}>Media</h3>
                                            <h3 style={{ fontSize: 20 }}><strong>{ flag ? e.mediasInter && e.mediasInter.ranking : "-" }</strong></h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Link>
                    )
                })
            }

            </div>
        </div>
    )
}

export default RankingEquipos;