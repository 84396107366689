import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert, CIXButton } from '../CIXLibrary';
import Table from "react-table";
import Modal from 'react-modal';
import { Icon, Tag, message, Button } from "antd";
import Select from "react-select";
import Quill from 'react-quill';
import Loader from 'react-loaders'
import matchSorter from 'match-sorter'
import _ from 'lodash';
import "react-table/react-table.css";
import 'react-quill/dist/quill.snow.css';
import 'loaders.css';
import Jumbo from '../Jumbo';
import { slugify, mayusculizer } from '../../utils/func';
import { uniqBy, difference } from 'lodash'
import { 
  ADD_CATEGORIAS, GET_CATEGORIAS, EDIT_CATEGORIAS, DELETE_CATEGORIAS, 
  ADD_MISION, GET_MISION, EDIT_MISION, DELETE_MISION, 
  ADD_RETO, EDIT_RETO, DELETE_RETO, 
  ADD_RETOINSTANCIA, EDIT_RETOINSTANCIA, DELETE_RETOINSTANCIA,
  GET_TEMPORADAS, ADD_TEMPORADA, EDIT_TEMPORADA, DELETE_TEMPORADA,
  GET_MISIONINSTANCIAS, ADD_MISIONINSTANCIA, EDIT_MISIONINSTANCIA, DELETE_MISIONINSTANCIA,
  GET_CONFIG, EDIT_CONFIG
} from '../../queries/queryList'
import { useQuery, useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

const reset = { 
  plazos: "",
  calidad: "",
  reto: "",
  proactividad: "",
  resultado: "",
  comentarios: "",
  kpi: { label: "-", value: "" },
  estado: { label: "-", value: "" },
  c1: null,
  c2: null,
  c3: null
}
const resetEdit = { 
  plazosEdit: "",
  calidadEdit: "",
  retoEdit: "",
  proactividadEdit: "",
  objetivoEdit: "",
  resultadoEdit: "",
  kpiEdit: { label: "-", value: "" },
  estadoEdit: { label: "-", value: "" }
}

const retoVacio = {
  nombre: "",
  peso: 0,
  tipomejora: {
    value: "unidades",
    label: "Unidades"
  },
  inverso: false,
  tooltip: ""
}

const selectorEstado = [
  {
    label: "Completada",
    value: "completada"
  },
  {
    label: "Inactiva",
    value: "inactiva"
  },
  {
    label: "Activa",
    value: "activa"
  }
]

const selectorKPI = [
  {
    label: "Unidades",
    value: "unidades"
  },
  {
    label: "Moneda",
    value: "dinero"
  },
  {
    label: "Incremento",
    value: "porcentaje"
  }
]

const BloqueMisiones = ({ data, refetchMI }) => {

  const [nombreTemporada, setNombreTemporada] = useState("")
  const [loading, setLoading] = useState()
  const [spinner, setSpinner] = useState()
  const [activas, setActivas] = useState([])
  const [errors, setErrors] = useState([])
  const [indicadores, setIndicadores] = useState({})
  const [modalEditar, setModalEditar] = useState()
  const [modalFinalizar, setModalFinalizar] = useState()
  const [selTemporada, setSelTemporada] = useState()
  const [misiones, setMisiones] = useState([])
  const [misionesTemp, setMisionesTemp] = useState([])
  const [misionesTemp2, setMisionesTemp2] = useState([])
  const [completadas, setCompletadas] = useState([])
  const [retos, setRetos] = useState([retoVacio])
  const [nuevaTemporada, setNuevaTemporada] = useState("")
  const [temporadas, setTemporadas] = useState([])
  const [temporadaActual, setTemporadaActual] = useState({ value: undefined, label: "-" })
  const [temporada, setTemporada] = useState({})
  const [temporadaLeccProx, setTemporadaLeccProx] = useState({})
  const [temporadaEdit, setTemporadaEdit] = useState({})
  const [temporadaBorr, setTemporadaBorr] = useState({})
  const [showLeccProx, setShowLeccProx] = useState(false)
  const [lecciones, setLecciones] = useState("")
  const [proximos, setProximos] = useState("")
  const [misionSeleccionada, setMisionSeleccionada] = useState()
  const [misionEditSeleccionada, setMisionEditSeleccionada] = useState({})
  const [misionesFinalizadas, setMisionesFinalizadas] = useState([])

  const [showNuevaMision, setShowNuevaMision] = useState(false)
  const [showListaMisiones, setShowListaMisiones] = useState(false)
  const [showCategorias, setShowCategorias] = useState(false)
  const [showTemporadas, setShowTemporadas] = useState(false)
  const [showEditFinalizadas, setShowEditFinalizadas] = useState(false)

  const [alertAsignaNuevaTemporada, setAlertAsignaNuevaTemporada] = useState(false)
  const [alertSobreescribeTemp, setAlertSobreescribeTemp] = useState(false)
  const [alertDemasiadoGrande, setAlertDemasiadoGrande] = useState(false)
  const [alertProximosOk, setAlertProximosOk] = useState(false)
  const [alertProximosMal, setAlertProximosMal] = useState(false)
  const [alertBorraMisionOk, setAlertBorraMisionOk] = useState(false)
  const [alertBorraMisionMal, setAlertBorraMisionMal] = useState(false)
  const [alertEdicionOk, setAlertEdicionOk] = useState(false)
  const [alertEdicionMal, setAlertEdicionMal] = useState(false)
  const [alertEntrenamientoMal, setAlertEntrenamientoMal] = useState(false)

  const [modalEditCategoria, setModalEditCategoria] = useState({ open: false, row: {}})
  const [alertBorrarCategoria, setAlertBorrarCategoria] = useState({ open: false, row: {}})
  const [modalEditMision, setModalEditMision] = useState({ open: false, row: {}})
  const [alertBorrarMision, setAlertBorrarMision] = useState({ open: false, row: {}})
  const [alertBorrarMI, setAlertBorrarMI] = useState(false)
  
  const [nuevaCategoria, setNuevaCategoria] = useState("")
  const [editCategoria, setEditCategoria] = useState("")
  const [categoriaSel, setCategoriaSel] = useState({})
  const [misionSel, setMisionSel] = useState({})

  const [nuevaMision, setNuevaMision] = useState({
    nombre: "",
    descripcion: "",
    slug: "",
    categorias: []
  })
  const [editMision, setEditMision] = useState({
    nombre: "",
    descripcion: "",
    slug: "",
    categorias: [],
    retos: [retoVacio]
  })
  const [misionFinalizar, setMisionFinalizar] = useState({
    plazos: "",
    calidad: "",
    reto: "",
    proactividad: "",
    resultado: "",
    comentarios: ""
  })
  const [misionEdit, setMisionEdit] = useState({
    plazos: "",
    calidad: "",
    reto: "",
    proactividad: "",
    objetivo: "",
    resultado: "",
    kpi: { label: "-", value: "" },
    estado: { label: "-", value: "" },
    temporada: { label: "-", value: "" },
    c1: null,
    c2: null,
    c3: null
  })

  const [archivoMision, setArchivoMision] = useState()

  const { loading: loadingCategorias, error: errorCategorias, data: dataCategorias, refetch: refetchCategorias } = useQuery(GET_CATEGORIAS)
  const { loading: loadingMisiones, error: errorMisiones, data: dataMisiones, refetch: refetchMisiones } = useQuery(GET_MISION)
  const { loading: loadingTemporadas, error: errorTemporadas, data: dataTemporadas, refetch: refetchTemporadas } = useQuery(GET_TEMPORADAS)

  const [editarConfigMt, { data: dataEditConfig, error: errorEditConfig }] = useMutation(EDIT_CONFIG, { refetchQueries: [{ query: GET_CONFIG }] })
  const [nuevaCategoriaMt, { data: dataNuevaCategoria, error: errorNuevaCategoria }] = useMutation(ADD_CATEGORIAS, { refetchQueries: [{ query: GET_MISION }, { query: GET_CATEGORIAS }] })
  const [editarCategoriaMt, { data: dataEditCategoria, error: errorEditCategoria }] = useMutation(EDIT_CATEGORIAS, { refetchQueries: [{ query: GET_MISION }, { query: GET_CATEGORIAS }] })
  const [borrarCategoriaMt, { data: dataBorradoCategoria, error: errorBorradoCategoria }] = useMutation(DELETE_CATEGORIAS, { refetchQueries: [{ query: GET_MISION }, { query: GET_CATEGORIAS }] })
  const [nuevaMisionMt, { data: dataNuevaMision, error: errorNuevaMision }] = useMutation(ADD_MISION, { refetchQueries: [{ query: GET_MISION }, { query: GET_CATEGORIAS }] })
  const [editarMisionMt, { data: dataEditMision, error: errorEditMision }] = useMutation(EDIT_MISION, { refetchQueries: [{ query: GET_MISION }, { query: GET_CATEGORIAS }] })
  const [borrarMisionMt, { data: dataBorradoMision, error: errorBorradoMision }] = useMutation(DELETE_MISION, { refetchQueries: [{ query: GET_MISION }, { query: GET_CATEGORIAS }] })
  const [nuevaTemporadaMt, { data: dataNuevaTemporada, error: errorNuevaTemporada }] = useMutation(ADD_TEMPORADA, { refetchQueries: [{ query: GET_TEMPORADAS }] })
  const [editarTemporadaMt, { data: dataEditTemporada, error: errorEditTemporada }] = useMutation(EDIT_TEMPORADA, { refetchQueries: [{ query: GET_TEMPORADAS }] })
  const [borrarTemporadaMt, { data: dataBorradoTemporada, error: errorBorradoTemporada }] = useMutation(DELETE_TEMPORADA, { refetchQueries: [{ query: GET_TEMPORADAS }] })
  const [nuevoRetoMt, { data: dataNuevoReto, error: errorNuevoReto }] = useMutation(ADD_RETO, { refetchQueries: [{ query: GET_MISION }] })
  const [editarRetoMt, { data: dataEditReto, error: errorEditReto }] = useMutation(EDIT_RETO, { refetchQueries: [{ query: GET_MISION }] })
  const [borrarRetoMt, { data: dataBorradoReto, error: errorBorradoReto }] = useMutation(DELETE_RETO, { refetchQueries: [{ query: GET_MISION }] })
  const [nuevoRIMt, { data: dataNuevoRI, error: errorNuevoRI }] = useMutation(ADD_RETOINSTANCIA)
  const [editarRIMt, { data: dataEditRI, error: errorEditRI }] = useMutation(EDIT_RETOINSTANCIA)
  const [borrarRIMt, { data: dataBorradoRI, error: errorBorradoRI }] = useMutation(DELETE_RETOINSTANCIA)
  const [nuevaMIMt, { data: dataNuevoMI, error: errorNuevoMI }] = useMutation(ADD_MISIONINSTANCIA)
  const [editarMIMt, { data: dataEditMI, error: errorEditMI }] = useMutation(EDIT_MISIONINSTANCIA)
  const [borrarMIMt, { data: dataBorradoMI, error: errorBorradoMI }] = useMutation(DELETE_MISIONINSTANCIA)

  const {acceptedFiles, rejectedFiles, getRootProps, getInputProps} = useDropzone({
    multiple: false,
    maxSize: 	104857600
  });

  useEffect(() => {
    if(data && Array.isArray(data.configs)){
      const tActual = data.configs[0].temporadaActual
      const ind = data.configs[0].indicadores
      setTemporadaActual(tActual ? { value: tActual._id, label: tActual.nombre } : { value: undefined, label: "-"})
      setIndicadores(ind)
    }
    if(data && Array.isArray(data.MI)){
      setMisionesFinalizadas(data.MI)
    }
  }, [data])

  useEffect(() => {
    if(dataTemporadas && Array.isArray(dataTemporadas.temporadas)){
      setSelTemporada( dataTemporadas.temporadas.map(t => ({ value: t._id, label: t.nombre })))
      setTemporadas(dataTemporadas.temporadas)
    }
  }, [dataTemporadas])
  
  useEffect(() => {
    Modal.setAppElement('body');
  }, [])

  useEffect(() => {
      const misionesTemp = misiones.filter(m => m.temporada === temporada.value)
      setMisionesTemp(misionesTemp)
      setLecciones(misionesTemp[0] && misionesTemp[0].lecciones)
  }, [temporada])

  useEffect(() => {
    if(modalEditar){
      setMisionEdit({
        plazos: misionEditSeleccionada.plazos || 0,
        proactividad: misionEditSeleccionada.proactividad || 0,
        reto: misionEditSeleccionada.reto || 0,
        calidad: misionEditSeleccionada.calidad || 0,
        temporada: misionEditSeleccionada.temporada || "",
        objetivo: misionEditSeleccionada.kpiantes || "",
        resultado: misionEditSeleccionada.kpidespues || 0,
        kpi: selectorKPI.find(item => item.value === misionEditSeleccionada.tipomejora),
        estado: selectorEstado.find(item => item.value === misionEditSeleccionada.estado),
        c1: misionEditSeleccionada.cajas.entrenamiento,
        c2: misionEditSeleccionada.cajas.reto,
        c3: misionEditSeleccionada.cajas.finalizacion
      })
    }
  }, [modalEditar])

  useEffect(() => {
    if(nuevaMision.nombre !== ""){
      setNuevaMision({ ...nuevaMision, slug: slugify(nuevaMision.nombre, Array.isArray(dataMisiones.misions) ? dataMisiones.misions.map(m => m.slug) : []) })
    }
    else{
      setNuevaMision({ ...nuevaMision, slug: "" })
    }
  }, [nuevaMision.nombre])

  useEffect(() => {
    if(editMision.nombre !== ""){
      setEditMision({ ...editMision, slug: slugify(editMision.nombre, Array.isArray(dataMisiones.misions) ? dataMisiones.misions.filter(m => m.slug !== misionSel.original.slug).map(m => m.slug) : []) })
    }
    else{
      setEditMision({ ...editMision, slug: "" })
    }
  }, [editMision.nombre])

  useEffect(() => {
    if(temporadaLeccProx.value && temporadas.length > 0){
      const tSelec = temporadas.find(t => t._id === temporadaLeccProx.value)
      setShowLeccProx(true)
      setLecciones(tSelec && tSelec.lecciones)
      setProximos(tSelec && tSelec.proximos)
    }
  }, [temporadaLeccProx, temporadas])

  useEffect(() => { if(dataNuevaCategoria){ message.success("Se ha creado correctamente la categoría") } }, [dataNuevaCategoria])
  useEffect(() => { if(errorNuevaCategoria){ message.error("Ha fallado el proceso de creación de la categoría") } }, [errorNuevaCategoria])
  useEffect(() => { if(dataBorradoCategoria){ message.success("Se ha borrado correctamente la categoría") } }, [dataBorradoCategoria])
  useEffect(() => { if(errorBorradoCategoria){ message.error("Ha fallado el proceso de borrado de la categoría") } }, [errorBorradoCategoria])
  useEffect(() => { if(dataEditCategoria){ 
    message.success("Se ha editado correctamente la categoría") 
    setModalEditCategoria({ open: false, row: {}})
  }}, [dataEditCategoria])
  useEffect(() => { if(errorEditCategoria){ message.error("Ha fallado el proceso de edición de la categoría") } }, [errorEditCategoria])

  useEffect(() => { 
    if(dataNuevaMision){ 
      message.success("Se ha creado correctamente " + ((data.configs[0].literales && data.configs[0].literales.mision.genero === "m") ? `el ${data.configs[0].literales.mision.sing}` : `la ${data.configs[0].literales.mision.sing}`))
      retos.forEach((rt, i) => {
        nuevoRetoMt({ 
          variables: { 
            nombre: rt.nombre,
            peso: parseFloat(rt.peso),
            tipomejora: rt.tipomejora.value,
            inverso: rt.inverso,
            mision: dataNuevaMision.createMision && dataNuevaMision.createMision.mision._id,
            tooltip: rt.tooltip
          }
        })
      })
    } 
  }, [dataNuevaMision])


  useEffect(() => { if(errorNuevaMision){ message.error("Ha fallado el proceso de creación de" + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l" : " la") + (data.configs[0].literales && data.configs[0].literales.mision.sing)) } }, [errorNuevaMision])
  useEffect(() => { if(dataBorradoMision){ message.success("Se ha borrado correctamente " + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "el" : "la") + (data.configs[0].literales && data.configs[0].literales.mision.sing)) } }, [dataBorradoMision])
  useEffect(() => { if(errorBorradoMision){ message.error("Ha fallado el proceso de borrado de" + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l" : " la") + (data.configs[0].literales && data.configs[0].literales.mision.sing)) } }, [errorBorradoMision])
  useEffect(() => { if(dataEditMision){ 
    message.success("Se ha editado correctamente " + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "el" : "la") + (data.configs[0].literales && data.configs[0].literales.mision.sing)) 
    const retosEdit = editMision.retos.map(rt => rt._id)
    const retosAnteriores = dataEditMision.updateMision.mision.retos
    const retosDesvincular = retosAnteriores.filter(rt => {
      return difference(retosAnteriores.map(rt => rt._id), retosEdit).includes(rt._id)
    })

    editMision.retos.forEach((rt, i) => {
      console.log(rt)
      if(rt._id){
        editarRetoMt({ 
          variables: { 
            id: rt._id,
            nombre: rt.nombre,
            peso: parseFloat(rt.peso),
            inverso: rt.inverso,
            tipomejora: rt.tipomejora.value,
            tooltip: rt.tooltip
          }
        })
      }
      else{
        nuevoRetoMt({ 
          variables: { 
            nombre: rt.nombre,
            peso: parseFloat(rt.peso),
            tipomejora: rt.tipomejora.value,
            inverso: rt.inverso,
            mision: dataEditMision.updateMision && dataEditMision.updateMision.mision._id
          }
        })
      }
    })

    console.log(retosDesvincular)
    retosDesvincular.forEach((rt, i) => {
      editarRetoMt({ 
        variables: { 
          id: rt._id,
          mision: null
        }
      })
    })
    setModalEditMision({ open: false, row: {}})
  } }, [dataEditMision])
  useEffect(() => { if(errorEditMision){ message.error("Ha fallado el proceso de editado del " + data.configs[0].literales.mision.sing) } }, [errorEditMision])

  useEffect(() => { if(dataNuevaTemporada){ message.success("Se ha creado correctamente " + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "el " : "la ") + data.configs[0].literales.temporada.sing) } }, [dataNuevaTemporada])
  useEffect(() => { if(errorNuevaTemporada){ message.error("Ha fallado el proceso de creación de" + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l " : " la ") + data.configs[0].literales.temporada.sing) } }, [errorNuevaTemporada])
  useEffect(() => { if(dataEditTemporada){ message.success("Se han editado correctamente los textos de" + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l " : " la ") + data.configs[0].literales.temporada.sing) } }, [dataEditTemporada])
  useEffect(() => { if(errorEditTemporada){ message.error("Ha fallado el proceso de edición de los textos de" + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l " : " la ") + data.configs[0].literales.temporada.sing) } }, [errorEditTemporada])
  useEffect(() => { if(dataBorradoTemporada){ message.success("Se ha borrado correctamente " + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "el " : "la ") + data.configs[0].literales.temporada.sing) } }, [dataBorradoTemporada])
  useEffect(() => { if(errorBorradoTemporada){ message.error("Ha fallado el proceso de borrado de" + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l " : " la ") + data.configs[0].literales.temporada.sing) } }, [errorBorradoTemporada])
  useEffect(() => { if(dataEditConfig){ message.success(`Se ha asignado correctamente ${temporadaActual.label} como ${data.configs[0].literales.temporada.sing} actual`) } }, [dataEditConfig])
  useEffect(() => { if(errorEditConfig){ message.error("Ha fallado el proceso de asignación de " + data.configs[0].literales.temporada.sing + " actual") } }, [errorEditConfig])
  useEffect(() => { if(dataBorradoMI){ 
    refetchMI()
    message.success("Se ha borrado correctamente la instancia de" + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l " : " la ") + data.configs[0].literales.mision.sing + "seleccionad" + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")) } 
    setModalEditar(false)
    setMisionEditSeleccionada({})
  }, [dataBorradoMI])
  useEffect(() => { if(errorBorradoMI){ message.error("Ha fallado el proceso de borrado de la instancia del " + data.configs[0].literales.mision.sing) } }, [errorBorradoMI])
  useEffect(() => { if(dataEditMI){ 
    refetchMI()
    message.success("Se ha editado correctamente la instancia de" + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l " : " la ") + data.configs[0].literales.mision.sing + " seleccionad" + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a"))
    setModalEditar(false)
    setMisionEditSeleccionada({})
    console.log(dataEditMI)
    fetch(process.env.REACT_APP_API_URL + "/calcula-medias", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token")
      },
      body: JSON.stringify({
        misionesEditadas: [dataEditMI.updateMisioninstancia.misioninstancia._id],
        edit: true
      })
    })
  }}, [dataEditMI])
  useEffect(() => { if(errorEditMI){ message.error("Ha fallado el proceso de edición de la instancia de" + (data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l " : " la ") + data.configs[0].literales.mision.sing) } }, [errorEditMI])

  useEffect(() => {
    if(Array.isArray(acceptedFiles) && acceptedFiles[0]){
      console.log("2")
      console.log({ acceptedFiles })
      let formData = new FormData();
      console.log(editMision._id)
      formData.append("refId", editMision._id);
      formData.append("ref", "mision" );
      formData.append("field", "archivos");
      if (acceptedFiles[0] instanceof File) {
        formData.append('files', acceptedFiles[0]);
        console.log(formData)
      }
      fetch(process.env.REACT_APP_API_URL + "/upload", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: formData
      }).then(data => data.json()).then((response) => {
        setEditMision({...editMision, archivos: [...response]})
        refetchMisiones()
      }).catch(err => {
        console.log(err)
        message.error("Se ha producido un error durante la subida del archivo")
      })
    }
  }, [acceptedFiles])

  useEffect(() => {
    if(Array.isArray(rejectedFiles) && rejectedFiles.length > 0){
      setAlertDemasiadoGrande(true)
    }
  }, [rejectedFiles])

  // useEffect(() => {
  //   if(dataMisiones && Array.isArray(dataMisiones.misions) && acceptedFiles[0]){
  //     console.log("1")
  //     const archivos = [...editMision.archivos]
  //     archivos.unshift(acceptedFiles[0])
  //     setEditMision({ ...editMision, archivos })
  //   }
  // }, [dataMisiones, acceptedFiles])

  const crearNuevaCategoria = () => {
    nuevaCategoriaMt({ variables: { nombre: nuevaCategoria }})
  }

  const editarCategoria = () => {
    editarCategoriaMt({ variables: { id: categoriaSel.original._id, nombre: editCategoria }})
  }

  const borrarCategoria = (bool, args) => {
    if(bool){ 
      borrarCategoriaMt({ variables: { id: args.original._id }}) 
    }
  }

  const crearNuevaMision = () => {
    nuevaMisionMt({ variables: {
      nombre: nuevaMision.nombre,
      descripcion: nuevaMision.descripcion,
      slug: nuevaMision.slug,
      categorias: nuevaMision.categorias.map(c => c.value)
    }})
  }

  const crearNuevaTemporada = () => {
    nuevaTemporadaMt({ variables: { nombre: nuevaTemporada }})
  }

  const asignarTemporadaActual = () => {
    editarConfigMt({ variables: { id: data.configs[0]._id, temporadaActual: temporadaActual.value } })
  }
  
  const borrarMision = (bool, args) => {
    if(bool){
      borrarMisionMt({ variables: { id: args.original._id }})
    }
  }

  const borrarMI = bool => {
    if(bool){
      borrarMIMt({ variables: { id: misionEditSeleccionada._id }})
    }
  }
  
  const editarMision = row => {
    editarMisionMt({ variables: {
      id: misionSel.original._id,
      nombre: editMision.nombre,
      descripcion: editMision.descripcion,
      slug: editMision.slug,
      categorias: editMision.categorias.map(c => c._id)
    }})
  }

  const editarMI = () => {
    misionEditSeleccionada.retoinstancias.forEach(ri => {
      editarRIMt({
        variables: {
          id: ri._id,
          objetivo: parseFloat(ri.objetivo),
          resultado: parseFloat(ri.resultado),
          valor: parseFloat(ri.valor)
        }
      })
    })
    editarMIMt({
      variables: {
        id: misionEditSeleccionada._id,
        valores: misionEditSeleccionada.valores,
        temporada: misionEditSeleccionada.temporada._id,
        estado: misionEditSeleccionada.estado.toLowerCase(),
        cajas: misionEditSeleccionada.cajas
      }
    })
  }
  
  const validarFormulario = tipo => {
    // let errors = [];
    // if(tipo === "nuevaTemporada"){
    //   if( nombreTemporada === "" ){ errors.push("nombreTemporada") }
    // }
    // else if(tipo === "finalizarMision"){
    //   if( plazos === "" ){ errors.push("plazos") }
    //   if( calidad === "" ){ errors.push("calidad") }
    //   if( reto === "" ){ errors.push("reto") }
    //   if( proactividad === "" ){ errors.push("proactividad") }
    //   //if( resultado === "" ){ errors.push("resultado") }
    // }
    // else if(tipo === "editarMision"){
    //   if( estadoEdit.value === "completada" ){
    //     if( plazosEdit === "" ){ errors.push("plazosEdit") }
    //     if( calidadEdit === "" ){ errors.push("calidadEdit") }
    //     if( retoEdit === "" ){ errors.push("retoEdit") }
    //     if( proactividadEdit === "" ){ errors.push("proactividadEdit") }
    //   }
    //   if( temporadaEdit === "" ){ errors.push("temporadaEdit") }
    //   if( objetivoEdit === "" ){ errors.push("objetivoEdit") }
    // }
    // this.setState({ errors })
    // return errors.length === 0
  }
  
  const openModal = dato => {
    // this.setState({ modalFinalizar: true, misionSeleccionada: dato })
  }  
  
  const openEditModal = dato => {
    setModalEditar(true)
    setMisionEditSeleccionada(dato)
  }
  
  // useEffect(() => {
  //   console.log(misionEditSeleccionada)
  // }, [misionEditSeleccionada])

  const finalizarMision = () => {
    // const { plazos, calidad, reto, proactividad, resultado, comentario, activas } = this.state
    // if( this.validarFormulario("finalizarMision")){
    //   request(process.env.REACT_APP_API_URL + "/misioninstancias/" + misionSeleccionada._id, {
    //     method: "PUT",
    //     body: { plazos, calidad, reto, proactividad, kpiantes: resultado, comentario, estado: "completada" }
    //   }).then(m => {
    //     this.setState({ activas: activas.filter(m => m._id !== misionSeleccionada._id ), modalFinalizar: false})
    //   }).catch(err => console.log( err ))
    // }
  }
  
  const asignarLeccProx = async () => {
    editarTemporadaMt({
      variables: {
        id: temporadaLeccProx.value,
        lecciones,
        proximos
      }
    })
  }

  const borrarTemporada = () => {
    borrarTemporadaMt({
      variables: {
        id: temporadaBorr.value
      }
    })
    setTemporadaBorr({ label: " - ", value: undefined })
  }
  
  const cambiaEntrenamiento = (entrenamiento, instancia) => {

    // const activas = [...activas].map(a => {
    //   if(a._id === instancia.id){ a.cajas = {...a.cajas, entrenamiento} }
    //   return a
    // })
    // this.setState({ activas })
    
    // request(process.env.REACT_APP_API_URL + "/misioninstancias/" + instancia.id, {
    //   method: "PUT",
    //   body: {
    //     cajas: {...instancia.cajas, entrenamiento}
    //   }
    // }).catch(err => { console.log(err); this.setState({ alertEntrenamientoMal: true }) })
  }

  return (!data || !data.configs[0] || !data.configs[0].literales) ? <div key="spinner" style={{
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: spinner ? "rgba(0, 0, 0, 0.3)" : "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: spinner ? 100 : -1
  }}>
      { spinner ? <Loader type="line-scale" color="#232323" active /> : null }
  </div>: (
    
    <div key="body" className="d-flex w-100 justify-content-center align-items-center flex-column">
      <h1 className="pt-4">{_.capitalize(data.configs[0].literales.mision.plur)}</h1>
      
      <div className="row w-100 p-4">
        <Jumbo className="col p-4">
          <div className="d-flex w-100 transi" onClick={() => setShowNuevaMision(!showNuevaMision)}>
            <h3 className="mr-2">Nuev{data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a"} {data.configs[0].literales.mision.sing}</h3>
            <Icon type={`caret-${showNuevaMision ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
          </div>
          { showNuevaMision &&
            <div>
              <div className="form-group mt-2">
                <label htmlFor="nombreTemporada">Nombre de{data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l" : " la"} {data.configs[0].literales.mision.sing}</label>
                <input 
                  name="nombre" 
                  className={`form-control ${errors.includes("nombre") && "is-invalid"}`} 
                  type="text" 
                  value={nuevaMision.nombre} 
                  onChange={evt => setNuevaMision({ ...nuevaMision, nombre: evt.target.value })}
                />
              </div>              
              <div className="form-group mt-2">
                <label htmlFor="nombreTemporada">Descripción</label>
                <Quill 
                  value={nuevaMision.descripcion || ""}
                  onChange={evt => setNuevaMision({ ...nuevaMision, descripcion: evt })}
                />
              </div>              
              <div className="form-group mt-2">
                <label htmlFor="nombreTemporada">Slug</label>
                <input 
                  disabled
                  name="nombre" 
                  className={`form-control ${errors.includes("slug") && "is-invalid"}`} 
                  type="text" 
                  value={nuevaMision.slug} 
                  onChange={evt => setNuevaMision({ ...nuevaMision, slug: evt.target.value })}
                />
              </div>              
              <div className="form-group mt-2">
                <h5>Retos</h5>
                { retos.map((r, i) => (
                  <div key={`reto-${i}`} className="form-control form-group mb-4" style={{ height: "initial", fontSize: 14 }}>
                    <div className="row">
                      <div className="col-md col-12">
                        <label htmlFor="nombreReto">Nombre</label>
                        <input 
                          name="nombreReto" 
                          className={`form-control mb-2 ${errors.includes("nombreReto") && "is-invalid"}`} 
                          type="text" 
                          value={retos[i].nombre} 
                          onChange={evt => setRetos(retos.map((rt, j) => {
                            if(i === j){ return {
                              ...rt,
                              nombre: evt.target.value
                            }}
                            else{ return rt }
                          }))}
                        />
                      </div>
                      <div className="col-md col-12">
                        <label htmlFor="pesoReto">Peso</label>
                        <div className="input-group mb-2">
                          <input 
                            name="pesoReto" 
                            className={`form-control ${errors.includes("pesoReto") && "is-invalid"}`} 
                            type="text" 
                            value={retos[i].peso} 
                            onChange={evt => setRetos(retos.map((rt, j) => {
                              if(i === j){ return {
                                ...rt,
                                peso: evt.target.value
                              }}
                              else{ return rt }
                            }))}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md col-12">
                        <label htmlFor="tipomejoraReto">Tipo de KPI</label>
                        <Select className={`mb-2 p-0 ${errors.includes("tipomejoraReto") && "is-invalid"}`}
                          classNamePrefix="moradito"
                          name="tipomejoraReto"
                          value={retos[i].tipomejora}
                          onChange={evt => setRetos(retos.map((rt, j) => {
                            if(i === j){ return {
                              ...rt,
                              tipomejora: evt
                            }}
                            else{ return rt }
                          }))}
                          options={ selectorKPI }
                          placeholder={"Tipo de KPI"}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="tooltipReto">Tooltip</label>
                        <input 
                          name="tooltipReto" 
                          className={`form-control mb-2 ${errors.includes("tooltipReto") && "is-invalid"}`} 
                          type="text" 
                          value={retos[i].tooltip} 
                          onChange={evt => setRetos(retos.map((rt, j) => {
                            if(i === j){ return {
                              ...rt,
                              tooltip: evt.target.value
                            }}
                            else{ return rt }
                          }))}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="sentido">Sentido</label>
                        <div className="pagination mb-2" name="sentido">
                          <div className={"page-item" + (retos[i].inverso ? "" : " active")} onClick={() => {
                            setRetos(retos.map((rt, j) => {
                              if(i === j){ return {
                                ...rt,
                                inverso: false
                              }}
                              else{ return rt }
                            }))
                          }}>
                            <div className="page-link">Más es mejor</div>
                          </div>
                          <div className={"page-item" + (retos[i].inverso ? " active" : "")} onClick={() => {
                            setRetos(retos.map((rt, j) => {
                              if(i === j){ return {
                                ...rt,
                                inverso: true
                              }}
                              else{ return rt }
                            }))
                          }}> 
                            <div className="page-link">Menos es mejor</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    { retos.length > 1 &&
                      <Button className="my-2" onClick={() => setRetos(retos.filter((rt, j) => i !== j))}>Borrar reto</Button>
                    }
                  </div>
                ))}
                <CIXButton onClick={() => setRetos([...retos, retoVacio])}>Añadir reto</CIXButton>
              </div>              
              <div className="form-group mt-2">
                <label htmlFor="nombreTemporada">Categorías</label>
                <Select className="p-0"
                  classNamePrefix="moradito"
                  onChange={categoria => setNuevaMision({ ...nuevaMision, categorias: uniqBy([...nuevaMision.categorias, categoria], "value")})}
                  options={Array.isArray(dataCategorias && dataCategorias.categorias) ? dataCategorias.categorias.map(c => ({ label: c.nombre, value: c._id })) : []}
                  placeholder={"Categorías"}
                />
                <TagWrapper>
                  { nuevaMision.categorias.map((c, i) => {
                    return <Tag key={"tag-" + i} onClick={() => setNuevaMision({ ...nuevaMision, categorias: nuevaMision.categorias.filter(cs => c.value !== cs.value)})}>{ c.label } &times;</Tag>
                  })}
                </TagWrapper>
              </div>              
              <div className="btn btn-primary" onClick={() => crearNuevaMision()}>Guardar</div>
            </div>
          }  
        </Jumbo>
      </div>

      <div className="row w-100 p-4">
        <Jumbo className="col p-4">
          <div className="d-flex w-100 transi" onClick={() => setShowListaMisiones(!showListaMisiones)}>
            <h3 className="mr-2">Lista de { data.configs[0].literales.mision.plur }</h3>
            <Icon type={`caret-${showListaMisiones ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
          </div>
          { showListaMisiones &&
            <Table
                data={(dataMisiones && dataMisiones.misions) || []}
                defaultPageSize={10}
                className="mt-4"
                columns={[
                  {
                    Header: "Nombre",
                    id: "nombre",
                    accessor: d => d.nombre
                  },
                  {
                    Header: "Categorías",
                    id: "categorias",
                    accessor: d => <div>
                      {
                        d.categorias.map((c, i) => {
                          return <Tag key={"tagEdit-" + i}>{c.nombre}</Tag>
                        })
                      }
                    </div>
                  },
                  {
                    id: "btn",
                    Cell: row => <BtnWrap>
                      <Tag color="purple" onClick={() => {
                        setMisionSel(row)
                        setEditMision(row.original)
                        setModalEditMision({ open: true, row })
                      }}><Icon type="edit" /></Tag>
                      <Tag color="volcano" className="d-none" onClick={() => {
                        setMisionSel(row)
                        setAlertBorrarMision({ open: true, row })
                      }}><Icon type="delete" /></Tag>
                      <Link className="d-none" to={"/mision/" + row.original.slug}>
                        <Tag><Icon type="eye" /></Tag>
                      </Link>
                    </BtnWrap>
                  }
                ]}
            />
          }
        </Jumbo>
      </div>

      <div className="row w-100 p-4">
        <Jumbo className="col p-4">
          <div className="d-flex w-100 transi" onClick={() => setShowCategorias(!showCategorias)}>
            <h3 className="mr-2">Categorias</h3>
            <Icon type={`caret-${showCategorias ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
          </div>
          { showCategorias &&
            <div className="mt-4">
              <Table
                  data={dataCategorias && dataCategorias.categorias || []}
                  defaultPageSize={10}
                  columns={[
                    {
                      Header: "Nombre",
                      id: "nombre",
                      accessor: d => d.nombre
                    },
                    {
                      // Header: "Misiones asociadas",
                      Header: `${_.capitalize(data.configs[0].literales.mision.plur)} asociad${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")}s`,
                      id: "nmisiones",
                      accessor: d => Array.isArray(d.misions) ? d.misions.length : 0
                    },
                    {
                      id: "btn",
                      Cell: row => <BtnWrap>
                        <Tag color="purple" onClick={() => {
                          setCategoriaSel(row)
                          setModalEditCategoria({ open: true, row })
                          setEditCategoria(row.original.nombre)
                        }}><Icon type="edit" /></Tag>
                        <Tag color="volcano" onClick={() => {
                          setCategoriaSel(row)
                          setAlertBorrarCategoria({ open: true, row })
                        }}><Icon type="delete" /></Tag>
                      </BtnWrap>
                    }
                  ]}
              />
              <div className="form-group mt-4">
                <label htmlFor="nombreTemporada">Nombre de nueva categoría</label>
                <input 
                  name="nombre" 
                  className={`form-control ${errors.includes("categoria") && "is-invalid"}`} 
                  type="text" 
                  value={nuevaCategoria} 
                  onChange={evt => setNuevaCategoria(evt.target.value)}
                  />
              </div>              
              <div className="btn btn-primary" onClick={() => crearNuevaCategoria()}>Crear</div>
            </div>
          }
        </Jumbo>
      </div>   

      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4">
          <div className="d-flex w-100 transi" onClick={() => setShowTemporadas(!showTemporadas)}>
            <h3 className="mr-2"> {_.capitalize(data.configs[0].literales.temporada.plur)}</h3>
            <Icon type={`caret-${showTemporadas ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
          </div>
          { showTemporadas &&
            <div>
              <div className="row">
                <div className="col form-group mt-2">
                  <label htmlFor="nuevaTemporada">Crear nuev{(data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "o" : "a")} {data.configs[0].literales.temporada.sing}</label>
                  <input 
                    name="nuevaTemporada" 
                    className={`form-control ${errors.includes("nuevaTemporada") && "is-invalid"}`} 
                    type="text" 
                    value={nuevaTemporada} 
                    placeholder={"Nombre de" + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l " : " la ") + data.configs[0].literales.temporada.sing + " nuev" + (data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "o" : "a")}
                    onChange={evt => setNuevaTemporada(evt.target.value)}
                  />
                  <div className="btn btn-primary my-3" onClick={() => crearNuevaTemporada()}>Crear</div>
                </div>    
                <div className="col form-group mt-2">
                  <label htmlFor="nombreTemporada">Seleccionar {data.configs[0].literales && data.configs[0].literales.temporada.sing} actual</label>
                  <Select className="p-0"
                    classNamePrefix="moradito"
                    value={temporadaActual}
                    onChange={temporada => setTemporadaActual(temporada) }
                    options={selTemporada}
                    placeholder={_.capitalize(data.configs[0].literales.temporada.sing)}
                  />
                  <div className="btn btn-primary my-3" onClick={() => asignarTemporadaActual()}>Asignar</div>
                </div> 
                <div className="col form-group mt-2">
                  <label htmlFor="nombreTemporada">Borrar  {_.capitalize(data.configs[0].literales.temporada.sing)}</label>
                  <Select className="p-0"
                    classNamePrefix="moradito"
                    value={temporadaBorr}
                    onChange={temporada => setTemporadaBorr(temporada) }
                    options={selTemporada}
                    placeholder={_.capitalize(data.configs[0].literales.temporada.sing)}
                  />
                  <div className="btn btn-primary my-3" onClick={() => borrarTemporada()}>Borrar</div>
                </div> 
              </div>         
              <div className="row">
                <div className="col">
                  <h4 className="mb-2">Textos de{(data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l" : " la")} {data.configs[0].literales.temporada.sing}</h4>
                  <div className="form-group mt-2">
                    <label htmlFor="nombreTemporada"> {_.capitalize(data.configs[0].literales.temporada.sing)}</label>
                    <Select className="p-0"
                      classNamePrefix="moradito"
                      value={temporadaLeccProx}
                      onChange={temporada => setTemporadaLeccProx(temporada) }
                      options={selTemporada}
                      placeholder={_.capitalize(data.configs[0].literales.temporada.sing)}
                    />
                  </div>    
                  { showLeccProx && [
                    <div key="tx1" className="form-group mt-2">
                      <label htmlFor="leccionesQuill">Lecciones aprendidas</label>
                      <Quill 
                        name="leccionesQuill"
                        value={lecciones || ""}
                        onChange={evt => setLecciones(evt) }
                      />
                    </div>,
                    <div key="tx2" className="form-group mt-2">
                      <label htmlFor="temporadaQuill">Próximos pasos</label>
                      <Quill 
                        name="temporadaQuill"
                        value={proximos || ""}
                        onChange={evt => setProximos(evt) }
                      />
                    </div>,
                    <div key="tx3" className="btn btn-primary" onClick={() => asignarLeccProx()}>Guardar</div>
                  ]}
                </div>
              </div> 
            </div> 
          }
          </div>
      </div>              
        
      {( !loading && activas.length > 0 ) && (
        <div className="row w-100 p-4">
          <div className="col jumbo sombra p-4 finalizaActivas">
          <h3 className="mb-4">Finalizar {data.configs[0].literales.mision.plur} activ{(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")}s</h3>
            <Table
              data={activas}
              defaultPageSize={10}
              columns={[
                {
                  Header: "Nombre",
                  id: "nombre",
                  accessor: d => d.user.name
                },                      
                {
                  Header: "Apellidos",
                  id: "apellidos",
                  accessor: d => d.user.lastname
                },                  
                {
                  Header: _.capitalize(data.configs[0].literales.mision.plur),
                  id: "mision",
                  accessor: d => d.mision.nombre
                },                  
                {
                  Header: _.capitalize(data.configs[0].literales.temporada.sing),
                  id: "temporada",
                  accessor: d => d.temporada
                },                  
                {
                  Header: "Entrenamiento",
                  className: "d-flex justify-content-center",
                  Cell: row => (
                    <div className="pagination">
                      <div className={`page-item act ${row.original.cajas && row.original.cajas.entrenamiento === true && "active" }`}>
                        <div className="page-link" onClick={() => cambiaEntrenamiento(true, row.original)}>Sí</div>
                      </div>
                      <div className={`page-item grey ${(row.original.cajas === undefined || row.original.cajas.entrenamiento === undefined) && "active" }`}>
                        <div className="page-link" onClick={() => cambiaEntrenamiento(undefined, row.original)}>Pend</div>
                      </div>
                      <div className={`page-item desact ${row.original.cajas && row.original.cajas.entrenamiento === false && "active" }`}>
                        <div className="page-link" onClick={() => cambiaEntrenamiento(false, row.original)}>No</div>
                      </div>
                    </div>
                )},                                  
                {
                  Header: "",
                  maxWidth: 60,
                  className: "text-center",
                  Cell: row => <div className="btn btn-secondary" onClick={evt => openModal(row.original)}><Icon type="form" style={{ display: "flex", height: 25, alignItems: "center" }}/></div>
                }
              ]}
              defaultPageSize={10}
              previousText='Anterior'
              nextText='Siguiente'
              loadingText='Cargando...'
              noDataText={loading ? "Cargando..." : "Sin datos"}
              pageText='Página'
              ofText='de'
              rowsText='filas'
              className="-striped -highlight w-100"
            />
          </div>
        </div>
      )}
      
      <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4 finalizaActivas">
          <div className="d-flex w-100 transi" onClick={() => setShowEditFinalizadas(!showEditFinalizadas)}>
          <h3 className="mr-2">Editar {data.configs[0].literales.mision.plur} finalizad{(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")}s</h3>
            <Icon type={`caret-${showEditFinalizadas ? "up" : "down"}`} style={{ marginTop: -4}} className="d-flex align-items-center" theme="outlined" />
          </div>
          { showEditFinalizadas &&
            <Table
              data={misionesFinalizadas}
              defaultPageSize={10}
              columns={[
                {
                  Header: "Nombre",
                  id: "nombre",
                  accessor: d => d.user.nombre + " " + d.user.apellidos,
                  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nombre", "apellidos"] }),
                  filterAll: true
                },                                     
                {
                  Header: _.capitalize(data.configs[0].literales.mision.sing),
                  id: "mision",
                  accessor: d => d.mision && d.mision.nombre,
                  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["mision"] }),
                  filterAll: true
                },                  
                {
                  Header: _.capitalize(data.configs[0].literales.temporada.plur),
                  id: "temporada",
                  accessor: d => d.temporada && d.temporada.nombre,
                  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["temporada"] }),
                  filterAll: true
                },                  
                {
                  Header: "",
                  maxWidth: 60,
                  className: "text-center",
                  filterable: false,
                  Cell: row => <div className="btn btn-primary" onClick={evt => openEditModal(row.original)}><Icon type="form" style={{ display: "flex", height: 25, alignItems: "center" }}/></div>
                }
              ]}
              filterable
              defaultPageSize={10}
              previousText='Anterior'
              nextText='Siguiente'
              loadingText='Cargando...'
              noDataText={loading ? "Cargando..." : "Sin datos"}
              pageText='Página'
              ofText='de'
              rowsText='filas'
              className="-striped -highlight w-100 mt-4"
            />
          }
        </div>
      </div>
    
      {/* <div className="row w-100 p-4">
        <div className="col jumbo sombra p-4 finalizaActivas">
          <h3 className="mb-4">Filtrado de misiones seleccionables</h3>
          <FiltroMisiones />
        </div>
      </div> */}
      
      <Modal 
        isOpen={modalFinalizar}                    
        onRequestClose={() => {
          setModalFinalizar(false) 
          setErrors([])
          setMisionFinalizar(reset) 
        }}
        contentLabel={"Modal de finalización de "+data.configs[0].literales.mision.plur}
        overlayClassName="Overlay"
        className="Modal"
        style={{ overflowY: "scroll" }}
      >
        <div className="container p-4" style={{ position: "relative" }}>
          <div style={{ position: "absolute", fontSize: "2em", top: 0, right: 20, cursor: "pointer", textAlign: "center", width: 40, zIndex: 100 }} 
               onClick={() => {
                 setModalFinalizar(false)
                 setMisionFinalizar(reset) 
               }}
          >&times;</div>
          <div className="row mt-2">
            <div className="col">
              <h4><span style={{ fontWeight: 600 }}>{ _.capitalize(data.configs[0].literales.agente.sing) }:</span><span> { misionSeleccionada && misionSeleccionada.user.name } { misionSeleccionada && misionSeleccionada.user.lastname }</span></h4>
              <h4><span style={{ fontWeight: 600 }}>{ _.capitalize(data.configs[0].literales.mision.sing) }:</span><span> { misionSeleccionada && misionSeleccionada.mision.nombre }</span></h4>
              <h4><span style={{ fontWeight: 600 }}>{ _.capitalize(data.configs[0].literales.temporada.sing) }:</span><span> { misionSeleccionada && misionSeleccionada.temporada }</span></h4>
            </div>
          </div>            
          <div className="row mt-2">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="plazos">Plazos</label>
                <input 
                  className={`form-control ${errors.includes("plazos") && "is-invalid"}`} 
                  type="number" 
                  name="plazos" 
                  value={ misionFinalizar.plazos } 
                  onChange={evt => setMisionFinalizar({ plazos: evt.target.value }) }
                />
                <div className="invalid-feedback">Campo requerido</div>
              </div> 
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="calidad">Calidad</label>
                <input 
                  className={`form-control ${errors.includes("calidad") && "is-invalid"}`} 
                  type="number" 
                  name="calidad" 
                  value={ misionFinalizar.calidad } 
                  onChange={evt => setMisionFinalizar({ ...misionFinalizar, calidad: evt.target.value }) }
                />
                <div className="invalid-feedback">Campo requerido</div>
              </div>  
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="reto">Reto</label>
                <input 
                  className={`form-control ${errors.includes("reto") && "is-invalid"}`} 
                  type="number" 
                  name="reto" 
                  value={ misionFinalizar.reto } 
                  onChange={evt => setMisionFinalizar({ ...misionFinalizar, reto: evt.target.value }) }
                />
                <div className="invalid-feedback">Campo requerido</div>
              </div> 
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="proactividad">Proactividad</label>
                <input 
                  className={`form-control ${errors.includes("proactividad") && "is-invalid"}`} 
                  type="number" 
                  name="proactividad" 
                  value={ misionFinalizar.proactividad } 
                  onChange={evt => setMisionFinalizar({ ...misionFinalizar, proactividad: evt.target.value }) }
                />
                <div className="invalid-feedback">Campo requerido</div>
              </div> 
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="resultado">Resultado</label>
                <input className={`form-control ${errors.includes("resultado") && "is-invalid"}`}  
                  type="text" 
                  name="resultado" 
                  value={ misionFinalizar.resultado } 
                  onChange={evt => setMisionFinalizar({ ...misionFinalizar, resultado: evt.target.value }) }
                />
                <div className="invalid-feedback">Campo requerido</div>
              </div>  
            </div>
            {/*<div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="comentario">Comentario</label>
                <input className="form-control" type="text" name="comentario" value={ comentario } onClick={evt => this.setState({ comentario: evt.target.value }) }/>
              </div>
            </div>*/}
          </div>
          <div className="row">
            <div className="col">
              <div className="btn btn-success mr-2" onClick={() => finalizarMision()}>Finalizar {data.configs[0].literales.mision.sing}</div>
              <div className="btn btn-secondary" onClick={() => {
                setModalFinalizar(false)
                setErrors([])
                setMisionFinalizar(reset)
              }}>Cancelar</div>
            </div>
          </div>
        </div>
      </Modal>
      
      <Modal 
        isOpen={modalEditar}                    
        onRequestClose={() => { 
          setModalEditar(false)
          setErrors([])
          setMisionEditSeleccionada({}) 
          // setMisionEdit(reset)
        }}
        contentLabel={`Modal de edición de ${data.configs[0].literales.mision.plur} finalizad${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")}s`}
        overlayClassName="Overlay"
        className="Modal"
        style={{ overflowY: "scroll" }}
      >
        <div className="container p-4" style={{ position: "relative" }}>
          <div 
            style={{ position: "absolute", fontSize: "2em", top: 0, right: 20, cursor: "pointer", textAlign: "center", width: 40, zIndex: 100 }} 
            onClick={() => {
              setModalEditar(false)
              setMisionEdit(reset) 
            }}>&times;</div>
          <div className="row mt-2">
            <div className="col">
              <h4><span style={{ fontWeight: 600 }}>{ data.configs[0].literales.agente.sing }:</span><span> { misionEditSeleccionada.user && misionEditSeleccionada.user.nombre } { misionEditSeleccionada.user && misionEditSeleccionada.user.apellidos }</span></h4>
              <h4><span style={{ fontWeight: 600 }}>{ _.capitalize(data.configs[0].literales.mision.sing) }:</span><span> { misionEditSeleccionada && misionEditSeleccionada.mision && misionEditSeleccionada.mision.nombre }</span></h4>
              <h4><span style={{ fontWeight: 600 }}>{ _.capitalize(data.configs[0].literales.temporada.sing) }:</span><span> { misionEditSeleccionada && misionEditSeleccionada.temporada && misionEditSeleccionada.temporada.nombre }</span></h4>
            </div>
          </div>     
          { indicadores &&
            <div>
              {Object.keys(indicadores).length > 0 && <h5>Indicadores</h5>}
              <div className="row mt-2">
                {
                  Object.entries(indicadores).map((ind, i) => (
                    <div key={"ind-" + i} className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="plazos">{ mayusculizer(ind[1].nombre) }</label>
                        <input 
                          disabled={(misionEditSeleccionada && !misionEditSeleccionada.estado) || (misionEditSeleccionada && misionEditSeleccionada.estado.toLowerCase() !== "completada")} 
                          className={`form-control ${errors.includes("ind" + i) && "is-invalid"}`} 
                          type="number" 
                          name={"ind" + i}
                          value={ (misionEditSeleccionada && misionEditSeleccionada.valores && misionEditSeleccionada.valores[ind[0]]) || "" } 
                          onChange={evt => {
                            setMisionEditSeleccionada({ ...misionEditSeleccionada, valores: { ...misionEditSeleccionada.valores, [i]: evt.target.value }}) 
                          }}
                        />
                        <div className="invalid-feedback">Campo requerido</div>
                      </div> 
                    </div>
                  ))
                }
              </div>
            </div>
          }

          { misionEditSeleccionada && Array.isArray(misionEditSeleccionada.retoinstancias) && misionEditSeleccionada.retoinstancias.length > 0 && <h5>Retos</h5>}
          {
            misionEditSeleccionada && Array.isArray(misionEditSeleccionada.retoinstancias) && misionEditSeleccionada.retoinstancias.map((ri, i) => (
              <div key={"reto_" + i} className="row my-2">
                <strong className="col-12 mt-2 mb-1" key={`ri${i}_nombre`}>{ ri.reto.nombre }</strong>,
                <div className="col" key={`ri${i}_objetivo`}>
                  <label htmlFor={"objetivo" + i}>Objetivo</label>
                  <input 
                    className={`form-control ${errors.includes("obj" + i) && "is-invalid"}`} 
                    type="number" 
                    name={"ind" + i}
                    value={ misionEditSeleccionada.retoinstancias[i] && misionEditSeleccionada.retoinstancias[i].objetivo }
                    onChange={evt => {
                      let riMod = [...misionEditSeleccionada.retoinstancias]
                      riMod[i].objetivo = evt.target.value
                      setMisionEditSeleccionada({ ...misionEditSeleccionada, retoinstancias: riMod }) 
                    }}
                  />
                </div>
                <div className="col" key={`ri${i}_resultado`}>
                  <label htmlFor={"resultado" + i}>Resultado</label>
                  <input 
                    className={`form-control ${errors.includes("res" + i) && "is-invalid"}`} 
                    type="number" 
                    name={"ind" + i}
                    value={ misionEditSeleccionada.retoinstancias[i] && misionEditSeleccionada.retoinstancias[i].resultado }
                    onChange={evt => {
                      let riMod = [...misionEditSeleccionada.retoinstancias]
                      riMod[i].resultado = evt.target.value
                      setMisionEditSeleccionada({ ...misionEditSeleccionada, retoinstancias: riMod }) 
                    }}
                  />
                </div>
                <div className="col" key={`ri${i}_valor`}>
                  <label htmlFor={"valor" + i}>Valor</label>
                  <input 
                    className={`form-control ${errors.includes("val" + i) && "is-invalid"}`} 
                    type="number" 
                    name={"ind" + i}
                    value={ misionEditSeleccionada.retoinstancias[i] && misionEditSeleccionada.retoinstancias[i].valor }
                    onChange={evt => {
                      let riMod = [...misionEditSeleccionada.retoinstancias]
                      riMod[i].valor = evt.target.value
                      setMisionEditSeleccionada({ ...misionEditSeleccionada, retoinstancias: riMod }) 
                    }}
                  />
                </div>
              </div>
            ))
          }

          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="temporada">{_.capitalize(data.configs[0].literales.mision.sing)}</label>
                <Select className={`p-0 ${errors.includes("temporada") && "is-invalid"}`}
                  classNamePrefix="moradito"
                  name="estado"
                  value={misionEditSeleccionada.temporada ? { label: misionEditSeleccionada.temporada.nombre, value: misionEditSeleccionada.temporada._id} : { label: " - ", value: "" }}
                  onChange={temporada => setMisionEditSeleccionada({ ...misionEditSeleccionada, temporada: { _id: temporada.value, nombre: temporada.label } }) }
                  options={ selTemporada }
                  placeholder={_.capitalize(data.configs[0].literales.mision.sign)}
                />
                <div className="invalid-feedback">Campo requerido</div>
              </div>  
            </div>                
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="estado">Estado de{(data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l" : " la")} {data.configs[0].literales.mision.sing}</label>
                <Select className={`p-0 ${errors.includes("estado") && "is-invalid"}`}
                  classNamePrefix="moradito"
                  name="estado"
                  value={{ label: misionEditSeleccionada.estado ? mayusculizer(misionEditSeleccionada.estado) : " - ", value: misionEditSeleccionada.estado || "" }}
                  onChange={estado => setMisionEditSeleccionada({ ...misionEditSeleccionada, estado: mayusculizer(estado.value) }) }
                  options={ selectorEstado }
                  placeholder={"Estado"}
                />
                <div className="invalid-feedback">Campo requerido</div>
              </div>  
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="c1">Caja Entrenamiento</label>
                <div className="pagination" name="c1" id="c1">
                  <div className={`page-item act ${misionEditSeleccionada.cajas && misionEditSeleccionada.cajas.entrenamiento && "active"}`}>
                    <div className="page-link" onClick={() => setMisionEditSeleccionada({
                      ...misionEditSeleccionada, 
                      cajas: {
                        ...misionEditSeleccionada.cajas, 
                        entrenamiento: true 
                      }
                    })}>Sí</div>
                  </div>
                  <div className={`page-item desact ${misionEditSeleccionada.cajas && !misionEditSeleccionada.cajas.entrenamiento && "active"}`}>
                    <div className="page-link" onClick={() => setMisionEditSeleccionada({
                      ...misionEditSeleccionada, 
                      cajas: {
                        ...misionEditSeleccionada.cajas, 
                        entrenamiento: false
                      }
                    })}>No</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="c2">Caja Reto</label>
                <div className="pagination" name="c2" id="c2">
                  <div className={`page-item act ${misionEditSeleccionada.cajas && misionEditSeleccionada.cajas.reto && "active"}`}>
                    <div className="page-link" onClick={() => setMisionEditSeleccionada({
                      ...misionEditSeleccionada, 
                      cajas: {
                        ...misionEditSeleccionada.cajas, 
                        reto: true 
                      }
                    })}>Sí</div>
                  </div>
                  <div className={`page-item desact ${misionEditSeleccionada.cajas && !misionEditSeleccionada.cajas.reto && "active"}`}>
                    <div className="page-link" onClick={() => setMisionEditSeleccionada({
                      ...misionEditSeleccionada, 
                      cajas: {
                        ...misionEditSeleccionada.cajas, 
                        reto: false
                      }
                    })}>No</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="c3">Caja finalización</label>
                <div className="pagination" name="c3" id="c3">
                  <div className={`page-item act ${misionEditSeleccionada.cajas && misionEditSeleccionada.cajas.finalizacion && "active"}`}>
                    <div className="page-link" onClick={() => setMisionEditSeleccionada({
                      ...misionEditSeleccionada, 
                      cajas: {
                        ...misionEditSeleccionada.cajas, 
                        finalizacion: true 
                      }
                    })}>Sí</div>
                  </div>
                  <div className={`page-item desact ${misionEditSeleccionada.cajas && !misionEditSeleccionada.cajas.finalizacion && "active"}`}>
                    <div className="page-link" onClick={() => setMisionEditSeleccionada({
                      ...misionEditSeleccionada, 
                      cajas: {
                        ...misionEditSeleccionada.cajas, 
                        finalizacion: false
                      }
                    })}>No</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="btn btn-danger mr-2" onClick={() => setAlertBorrarMI(true)}>Borrar {data.configs[0].literales.mision.sing}</div>
              <div className="btn btn-primary mr-2" onClick={() => editarMI()}>Editar {data.configs[0].literales.mision.sing}</div>
              <div className="btn btn-secondary" onClick={() => {
                setModalEditar(false)
                setErrors([])
                setMisionEditSeleccionada({})
                // setMisionEdit(reset)
              }}>Cancelar</div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal 
        isOpen={modalEditCategoria.open}                    
        onRequestClose={() => { 
          setModalEditCategoria({ open: false, row: {}})
        }}
        contentLabel="Editar categoría"
        overlayClassName="Overlay"
        className="Modal"
        style={{ overflowY: "scroll" }}
      >
        <div className="container p-4" style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          <div className="row">
            <div 
              style={{ position: "absolute", fontSize: "2em", top: 0, right: 20, cursor: "pointer", textAlign: "center", width: 40, zIndex: 100 }} 
              onClick={() => {
                setModalEditar(false)
                setMisionEdit(reset) 
              }}>&times;</div>
            <div className="col-10 offset-1 pl-0">
              <h2>Editar categoría</h2>
              <div className="row mt-2">
                <label htmlFor="nombreTemporada">Nombre de la categoría</label>
                <input 
                  name="categoriaEdit" 
                  className={`form-control ${errors.includes("nombre") && "is-invalid"}`} 
                  type="text" 
                  value={editCategoria} 
                  onChange={evt => setEditCategoria(evt.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1" style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <div className="btn btn-success mr-2" onClick={() => editarCategoria()}>Editar categoría</div>
              <div className="btn btn-secondary" onClick={() => { setModalEditCategoria({ open: false, row: {}}) }}>Cancelar</div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal 
        isOpen={modalEditMision.open}                    
        onRequestClose={() => { 
          setModalEditMision({ open: false, row: {}})
        }}
        contentLabel={"Editar" + data.configs[0].literales.mision.sing}
        overlayClassName="Overlay"
        className="Modal"
        style={{ overflowY: "scroll", borderRadiusTopRight: 0, borderRadiusBottomRight: 0 }}
      >
        <div className="container p-4" style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          <div className="row">
            <div 
              style={{ position: "absolute", fontSize: "2em", top: 10, right: 20, cursor: "pointer", textAlign: "center", width: 40, zIndex: 100 }} 
              onClick={() => {
                setModalEditMision({ open: false, row: {}})
              }}>&times;</div>
            <div className="col-10 offset-1 pl-0">
              <div className="row">
                <h2>Editar {data.configs[0].literales.mision.sing}</h2>
              </div>
              <div className="row mt-2">
                <label htmlFor="nombreMisionEdit">Nombre de{(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l" : " la")} {data.configs[0].literales.mision.sing}</label>
                <input 
                  name="nombreMisionEdit" 
                  className={`form-control ${errors.includes("nombreMisionEdit") && "is-invalid"}`} 
                  type="text" 
                  value={editMision.nombre} 
                  onChange={evt => setEditMision({...editMision, nombre: evt.target.value})}
                />
              </div>
              <div className="row flex-column mt-2">
                <label htmlFor="descripcionMisionEdit">Descripción</label>
                <Quill 
                  className="d-flex flex-column"
                  value={editMision.descripcion || ""}
                  onChange={evt => setEditMision({ ...editMision, descripcion: evt })}
                />
              </div>
              <div className="row mt-2">
                <label htmlFor="slugMisionEdit">Slug</label>
                <input 
                  disabled
                  name="slugMisionEdit" 
                  className={`form-control ${errors.includes("slugMisionEdit") && "is-invalid"}`} 
                  type="text" 
                  value={editMision.slug} 
                  onChange={evt => setEditMision({...editMision, slug: evt.target.value})}
                />
              </div>
              <div className="row mt-2">
                <div className="form-group mt-2 w-100">
                  <h5 className="d-none">Retos</h5>
                  { Array.isArray(editMision.retos) && editMision.retos.map((r, i) => (
                    <div key={`reto-${i}`} className="form-control form-group mb-4 d-none" style={{ height: "initial", fontSize: 14 }}>
                      <div className="row">
                        <div className="col-12 col-md">
                          <label htmlFor="nombreRetoEdit">Nombre</label>
                          <input 
                            name="nombreRetoEdit" 
                            className={`form-control mb-2 ${errors.includes("nombreRetoEdit") && "is-invalid"}`} 
                            type="text" 
                            value={editMision.retos[i].nombre} 
                            onChange={evt => setEditMision({
                              ...editMision,
                              retos: editMision.retos.map((rt, j) => {
                                if(i === j){ return {
                                  ...rt,
                                  nombre: evt.target.value
                                }}
                                else{ return rt }
                              })
                            })}
                          />
                        </div>
                        <div className="col-12 col-md">
                          <label htmlFor="pesoRetoEdit">Peso</label>
                          <div className="input-group mb-2">
                            <input 
                              name="pesoRetoEdit" 
                              className={`form-control ${errors.includes("pesoRetoEdit") && "is-invalid"}`} 
                              type="text" 
                              value={editMision.retos[i].peso} 
                              onChange={evt => setEditMision({
                                ...editMision,
                                retos: editMision.retos.map((rt, j) => {
                                  if(i === j){ return {
                                    ...rt,
                                    peso: evt.target.value
                                  }}
                                  else{ return rt }
                                })
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">%</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md">
                          <label htmlFor="tipomejoraRetoEdit">Tipo de KPI</label>
                          <Select className={`mb-2 p-0 ${errors.includes("tipomejoraRetoEdit") && "is-invalid"}`}
                            classNamePrefix="moradito"
                            name="tipomejoraRetoEdit"
                            value={editMision.retos[i].tipomejora && selectorKPI.find(k => k.value === editMision.retos[i].tipomejora)}
                            onChange={evt => setEditMision({
                              ...editMision,
                              retos: editMision.retos.map((rt, j) => {
                                if(i === j){ return {
                                  ...rt,
                                  tipomejora: evt
                                }}
                                else{ return rt }
                              })
                            })}
                            options={ selectorKPI }
                            placeholder={"Tipo de KPI"}
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="tooltipRetoEdit">Tooltip</label>
                          <input 
                            name="tooltipRetoEdit" 
                            className={`form-control mb-2 ${errors.includes("tooltipEdit") && "is-invalid"}`} 
                            type="text" 
                            value={editMision.retos[i].tooltip} 
                            onChange={evt => setEditMision({
                              ...editMision,
                              retos: editMision.retos.map((rt, j) => {
                                if(i === j){ return {
                                  ...rt,
                                  tooltip: evt.target.value
                                }}
                                else{ return rt }
                              })
                            })}
                          />
                        </div>
                      </div>
                      <label htmlFor="sentido">Sentido</label>
                      <div className="pagination mb-2" name="sentido">
                        <div className={"page-item" + (editMision.retos[i].inverso ? "" : " active")} onClick={() => {
                          setEditMision({
                            ...editMision,
                            retos: editMision.retos.map((rt, j) => {
                              if(i === j){ return {
                                ...rt,
                                inverso: false
                              }}
                              else{ return rt }
                            })
                          })
                        }}>
                          <div className="page-link">Más es mejor</div>
                        </div>
                        <div className={"page-item" + (editMision.retos[i].inverso ? " active" : "")} onClick={() => {
                          setEditMision({
                            ...editMision,
                            retos: editMision.retos.map((rt, j) => {
                              if(i === j){ return {
                                ...rt,
                                inverso: true
                              }}
                              else{ return rt }
                            })
                          })
                        }}> 
                          <div className="page-link">Menos es mejor</div>
                        </div>
                      </div>
                      { editMision.retos.length > 1 &&
                        <Button className="my-2" onClick={() => setEditMision({
                          ...editMision,
                          retos: editMision.retos.filter((rt, j) => i !== j)
                        })}>Borrar reto</Button>
                      } 
                    </div>
                  ))}

                  <div className="row mb-4 d-none">
                    <div className="col">
                      <CIXButton onClick={() => setEditMision({
                        ...editMision,
                        retos: [...editMision.retos, retoVacio]
                      })}>Añadir reto</CIXButton>
                    </div>
                  </div>

                    <label>Archivo de información de{(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l" : " la")} {data.configs[0].literales.mision.sing }</label>
                  <div className="form-control mb-4 d-flex p-4" style={{ height: "initial" }}>
                    <div className="d-flex flex-column flex-fill px-2">
                      <label>Documento actual</label>
                      {(editMision.archivos && editMision.archivos[0]) ? (
                        <a href={ editMision.archivos[0].url.includes("cix-lyntia.s3") ? editMision.archivos[0].url : process.env.REACT_APP_API_URL + editMision.archivos[0].url } target="_blank" rel="noopener noreferrer"><div className="btn btn-outline-secondary w-100">{ editMision.archivos[0].name }</div></a>
                      ) : <div className="btn btn-secondary disabled">No hay ningún documento disponible</div>}
                    </div>
                    <div className="d-flex flex-column flex-fill px-2">
                      <label htmlFor="documento">Subir nuevo documento</label>
                      <div className="btn btn-primary dropzone" {...getRootProps()} style={{
                        width: "100%",
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}>
                        <input {...getInputProps({})}/>
                        <p className="m-0">Selecciona un archivo o arrástralo aquí</p>
                      </div>
                    </div>
                  </div>  

                </div>
              </div>
              <div className="row mt-2 d-flex flex-column">
                <label htmlFor="categoriaList">Categorías</label>
                <Select className="p-0"
                  classNamePrefix="moradito"
                  name="categoriaList"
                  onChange={categoria => setEditMision({ ...editMision, categorias: uniqBy([...editMision.categorias, {_id: categoria.value, nombre: categoria.label}], "nombre")})}
                  options={Array.isArray(dataCategorias && dataCategorias.categorias) ? dataCategorias.categorias.map(c => ({ label: c.nombre, value: c._id })) : []}
                  placeholder={"Categorías"}
                />
                <TagWrapper>
                  { editMision && editMision.categorias && editMision.categorias.map((c, i) => {
                    return <Tag key={"tag-" + i} onClick={() => setEditMision({ ...editMision, categorias: editMision.categorias.filter(cs => c._id !== cs._id)})}>{ c.nombre } &times;</Tag>
                  })}
                </TagWrapper>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-10 offset-1 mb-4 pr-0" style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <div className="btn btn-primary mr-2" onClick={() => editarMision()}>Editar {data.configs[0].literales.mision.sing}</div>
              <div className="btn btn-secondary" onClick={() => { setModalEditMision({ open: false, row: {}}) }}>Cancelar</div>
            </div>
          </div>
        </div>
      </Modal>
      
      <Alert 
        open={alertAsignaNuevaTemporada}
        text={`Se han asignado correctamente tod${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")}s l${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")}s ${data.configs[0].literales.mision.plur} activ${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "o" : "a")}s al ${data.configs[0].literales.temporada.sign} "${nombreTemporada}"`}
        modalCb={bool => setAlertAsignaNuevaTemporada(bool)}
      />                 
      {/* <Alert 
        open={alertSobreescribeTemp}
        confirm={true}
        text={`Se sobreescribirá el ${data.configs[0].literales.temporada.sign} de algunos ${data.configs[0].literales.mision.plur} activos que ya la tienen asignada. ¿Desea continuar?`}
        modalCb={bool => setAlertSobreescribeTemp(bool)}
        confirmFn={asignarNuevaTemporada}
      />                     */}
      <Alert 
        open={alertProximosOk}
        text={`Se han guardado correctamente los textos de${(data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l" : " la")} ${data.configs[0].literales.temporada.sign} ${temporadaLeccProx && temporadaLeccProx.value}`}
        modalCb={bool => setAlertProximosOk(bool)}
      />    
      <Alert 
        open={alertProximosMal}
        text={`Ocurrió un error durante el guardado de los textos de${(data.configs[0].literales && data.configs[0].literales.temporada.genero === "m" ? "l" : " la")} ${data.configs[0].literales.temporada.sign} ${temporadaLeccProx && temporadaLeccProx.value}`}
        modalCb={bool => setAlertProximosMal(bool)}
      />           
      <Alert 
        open={alertBorraMisionOk}
        text={`Se ha borrado correctamente ${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "el" : "la")} ${data.configs[0].literales.mision.sing}`}
        modalCb={bool => setAlertBorraMisionOk(bool)}
      />    
      <Alert 
        open={alertBorraMisionMal}
        text={`Ocurrió un error durante el borrado de${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l" : " la")} ${data.configs[0].literales.mision.sing}`}
        modalCb={bool => setAlertBorraMisionMal(bool)}
      />            
      <Alert 
        open={alertEdicionOk}
        text={`Se ha editado correctamente ${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "el" : "la")} ${data.configs[0].literales.mision.sing}`}
        modalCb={bool => setAlertEdicionOk(bool)}
      />    
      <Alert 
        open={alertEdicionMal}
        text={`Ocurrió un error durante la edición de${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "l" : " la")} ${data.configs[0].literales.mision.sing}`}
        modalCb={bool => setAlertEdicionMal(bool)}
      />    
      <Alert 
        open={alertEntrenamientoMal}
        text={`Ocurrió un error al guardar el valor de Entrenamiento`}
        modalCb={bool => setAlertEntrenamientoMal(bool)}
      />    
      <Alert 
        open={alertBorrarCategoria.open}
        confirm={true}
        text={`Se va a borrar la categoria ${categoriaSel && categoriaSel.original && categoriaSel.original.nombre}. ¿Desea continuar?`}
        modalCb={bool => setAlertBorrarCategoria(bool)}
        confirmFn={borrarCategoria}
        confirmArgs={alertBorrarCategoria.row}
      />    
      <Alert 
        open={alertBorrarMision.open}
        confirm={true}
        text={`Se va a borrar ${(data.configs[0].literales && data.configs[0].literales.mision.genero === "m" ? "el" : "la")} ${data.configs[0].literales.mision.sing} ${misionSel && misionSel.original && misionSel.original.nombre}. ¿Desea continuar?`}
        modalCb={bool => setAlertBorrarMision(bool)}
        confirmFn={borrarMision}
        confirmArgs={alertBorrarMision.row}
      />    
      <Alert 
        open={alertBorrarMI}
        confirm={true}
        text={`Se va a borrar la instancia del ${data.configs[0].literales.mision.sing} seleccionado. ¿Desea continuar?`}
        modalCb={bool => setAlertBorrarMI(bool)}
        confirmFn={borrarMI}
      />    
      <Alert 
        open={alertDemasiadoGrande}
        text={`El tamaño máximo de archivo aceptado por ${data && data.configs && data.configs[0] && data.configs[0].nombrePlat} es de 100MiB`}
        modalCb={bool => setAlertDemasiadoGrande(bool)}
      />    
      <Alert 
        open={alertBorrarCategoria.open}
        confirm={true}
        text={`Se va a borrar la categoria ${categoriaSel && categoriaSel.original && categoriaSel.original.nombre}. ¿Desea continuar?`}
        modalCb={bool => setAlertBorrarCategoria(bool)}
        confirmFn={borrarCategoria}
        confirmArgs={alertBorrarCategoria.row}
      />   
    </div>
  )
}

const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .ant-tag{
    display: flex;
    align-items: center;
    height: 24px;
    transition: all 300ms;
  }
  .ant-tag:hover{
    filter: brightness(0.85)
  }
`

const TagWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
`

export default BloqueMisiones;