import React from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';
// import auth from "../../utils/auth"

const TarjetaId = ({ user, loading, handleModalEdit }) => {
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo")) 
    return (
        <div className="col-12 jumbo sombra alto320 p-0 persocarduser" style={{ overflow: "hidden" }}>
            <div id="imgworker" className="imgtarget" style={{height: "100%", width:'45%', position: "relative"}}>
                <img alt="Imagen del usuario" src={(user && user.imagen ) ? user.imagen.url.includes("cix-lyntia.s3") ? user.imagen.url : process.env.REACT_APP_API_URL + "/uploads" + user.imagen.url.split("/uploads").slice(-1)[0] : process.env.REACT_APP_API_URL + "/uploads/person.jpg"} className="img-responsive ancho" style={{objectFit: "cover", objectPosition: "center top", height: "100%"}}/>
            </div>
            <div className="d-flex flex-column align-items-center h-100 flex-fill justify-content-around px-3">
                <div className="cientosesenta">
                    <h4 className="text-left leftpad px-2" id="nombre">{ loading ? <Skeleton /> : user.nombre + " " + user.apellidos}</h4>
                    <div className="row leftpad abajo px-2">
                        <div className="col-md-12 mt-2">
                        {( userInfo.role.name !== "Agente" ) ? (
                            <Link to={{
                                pathname: `/equipo/${user && user.trabajadorDe && user.trabajadorDe.slug}`,
                                idEquipo: user && user.trabajadorDe && user.trabajadorDe.slug
                            }}>
                                <h5 className="text-left font-weight-bold" id="tipoequipo">{ loading ? <Skeleton /> : (user && user.trabajadorDe && user.trabajadorDe.nombre )}</h5>
                            </Link>
                        ) : (
                            <h5 className="text-left font-weight-bold" id="tipoequipo">{ loading ? <Skeleton height="40px" /> : ((user && user.trabajadorDe) ? user.trabajadorDe.nombre : user.role.name )}</h5>
                        )}
                            
                            {/* <p className="text-left distribu">{ loading ? <Skeleton /> : ((user && user.equipo && user.equipo.subdivision) === "auto" ? "Auto" : ( (user && user.equipo && user.equipo.subdivision) === "distribucion" ? "Distribución" : ""))}</p> */}
                        </div> 
                    </div>
                </div>
                <div onClick={() => handleModalEdit(true)}>
                    <div className="btn btn-primary">Editar perfil</div>
                </div> 
            </div>
        </div>
    )
}

export default TarjetaId