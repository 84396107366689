import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import { Tooltip } from 'react-tippy';
import { GET_CONFIG } from '../queries/queryList';
import { useQuery } from '@apollo/react-hooks';
import { mayusculizer } from '../utils/func';

const CuadroRanking = ({ datos, config, mejorPeor = false }) => {

    // const mejorPeor = !(peorComercialRanking === undefined && mejorComercialRanking === undefined)
    const [indicadores, setIndicadores] = useState({})
    const [reto, setReto] = useState()
    const { loading: loadingConfig, error: errorConfig, data: dataConfig, refetch: refetchConfig } = useQuery(GET_CONFIG)
    const rojo = dataConfig ? dataConfig.configs[0].colores.naranja : "red"

    useEffect(() => {
        if(config.indicadores){
            setIndicadores(config.indicadores)
        }
    }, [config])

    useEffect(() => {
        if(Array.isArray(datos.ri)){
            const retoMedia = datos.ri.reduce((a, b) => {
                return a + (b.valor * b.reto.peso * 0.01)
            }, 0)
            const retoSuma = datos.ri.reduce((a, b) => a + b.valor, 0)
            setReto(config.mediasTrueSumasFalse ? Math.round(retoMedia) : Math.round(retoSuma))
        }
    }, [datos])

    return (                   
        <div className="row m-1 jumbo sombra alto320">
            <div className={`${mejorPeor ? "col-8" : "col-12" } bigtotalrank`} >
                <h2 style={{fontSize:60, fontWeight:600, marginBottom: 0}}>{ datos.total !== 0 ? datos.total : "-" }</h2>
                <p style={{color:'gainsboro'}}> Puntos de Ranking</p>
            </div>
            { mejorPeor && (
                <div className="col-4 p-0">
                    <div className="righranking" style={{borderBottom:'gainsboro 1px solid'}}>
                        <h4>Min <strong>{ datos.minRanking }</strong></h4>
                    </div>
                    <div className="righranking" style={{borderBottom:'gainsboro 1px solid'}}>
                        <h4>Max <strong>{ datos.maxRanking }</strong></h4>
                    </div>
                </div>
            )}
            <div className="col-md-12">
                <div className="row">
                    { Object.entries(indicadores).map((ind, i) => (
                            <div key={"ind-" + i} className="col p-0">
                                <small className="titlerank centerall">{ mayusculizer(ind[1].nombre) }
                                    { ind[1].tooltip &&
                                        <Tooltip style={{ marginBottom: 3 }} theme="light" title={ind[1].tooltip}>
                                            <Icon style={{ color: "gray", paddingLeft: 10 }} type="question-circle" />
                                        </Tooltip>
                                    }
                                </small>
                                <span 
                                    className="numberrank centerall" 
                                    style={{ color: datos.indicadores[ind[0]] > 0 ? "green" : ( datos.indicadores[ind[0]] === "0" ? "black" : rojo )}}
                                > 
                                    { datos.indicadores[ind[0]] > 0 ? "+" + datos.indicadores[ind[0]] : ( !isNaN(datos.indicadores[ind[0]]) ? datos.indicadores[ind[0]] : 0 ) } 
                                </span>
                            </div>
                        )
                    )}
                    <div key={"ind-reto"} className="col p-0">
                        <small className="titlerank centerall">Reto
                                <Tooltip style={{ marginBottom: 3 }} theme="light" title={"Tooltip del reto"}>
                                    <Icon style={{ color: "gray", paddingLeft: 10 }} type="question-circle" />
                                </Tooltip>
                        </small>
                        <span 
                            className="numberrank centerall" 
                            style={{ color: reto > 0 ? "green" : ( reto === 0 ? "black" : rojo )}}
                        > 
                            { reto }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CuadroRanking